import { track } from 'services/gtm';

export function trackShopChosen(shopId, communityLeaderId) {
  track('shop_chosen', {
    ecommerce: {
      view: 'shop_chosen',
      shop_id: shopId,
      community_leader_id: communityLeaderId,
    }
  });
}