import { TextBold, SubtitleBold } from './styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import { FormattedMessage } from 'react-intl';

function SwitchFilter({ handleFilters, checked, setChecked }) {
    useEffect(() => {
        const sliderThumbs = document.querySelectorAll('.MuiSlider-thumb');
        const switchElement = document.querySelector('.css-j204z7-MuiFormControlLabel-root');
        const switchMargin = document.querySelector('.css-6gd29l-MuiSwitch-root');

        if (sliderThumbs) {
          sliderThumbs.forEach((thumb) => {
            thumb.style.width = '24px';
            thumb.style.height = '24px';
          });
        }
        if (switchElement) {
          switchElement.style.marginRight = '0px';
        }
        if(switchMargin){
          switchMargin.style.margin = '0px';
          }
      }, []);

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 52,
        height: 32,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          marginRight: 0,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(23px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#00A656' : '#00A656',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#00A656',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
        },
        '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
          backgroundColor: 'white',
          width: 23,
          height: 23,
          transform: 'translateY(2px)',
        },
      
        '& .MuiSwitch-switchBase:not(.Mui-checked) .MuiSwitch-thumb': {
          backgroundColor: '#99A09D',
          marginLeft: '5px', 
          width: 18,
          height: 18,
          transform: 'translateY(4px)',
        },
        '& .MuiSwitch-track': {
          borderRadius: '100px',
          backgroundColor: theme.palette.mode === 'light' ? 'white' : 'white',
          borderColor: '#99A09D',
          border: '2px solid #99A09D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

    return (
            <>
                <TextBold>
                    <FormattedMessage
                      id='filterById'
                      defaultMessage='Filter by'
                    />
                </TextBold>
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'16px', marginBottom:'16px', height: '48px'}}>
                    <SubtitleBold active={checked}>
                    <FormattedMessage
                      id='filterWithDiscount'
                      defaultMessage='With discounts'
                    />
                    </SubtitleBold>
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }}  />}
                      checked={checked}
                      onChange={(e)=> {
                        handleFilters('discount_filter', e.target.checked)
                        setChecked(!checked)
                      }}
                    />
                </div>
          </>
    );
  }
  
  export default SwitchFilter;