import Image from "next/image"
import { Container, DetailBlock, Text, Title, ButtonContainer } from "./styled"
import { ST } from "next/dist/shared/lib/utils"
import { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useRouter } from "next/router";
import { countryCode, localNames } from "services/helpers";
import { Context } from "context/Context";
import { removeItem, resetCart } from "services/localstorage";

const ModalProductsInCart = ({ setModalWarningCart, country, setOpenDrawer }) => {
    const router = useRouter()
    const { setCarrito, setCantidad, setTotal } = useContext(Context)

    const handleCloseModal = (event) => {
        if (event.target === event.currentTarget) {
            setModalWarningCart(false);
        }
    };

    const handleChangeLocation = () => {
        router.push(`/${countryCode[country]}/selector`)
        resetCart()
        setCarrito([])
        setCantidad(0)
        setTotal(0)
        setOpenDrawer(false)
    }

    const handleContinue = () => {
        setOpenDrawer(false)
    }

    return(
        <Container onClick={handleCloseModal}>
            <DetailBlock>
                <Title>¿Querés salir?</Title>
                <Text>Si cambiás ubicación vas a perder los productos que agregaste al carrito.</Text>
                <ButtonContainer border onClick={handleChangeLocation} >Sí, salir</ButtonContainer>
                <ButtonContainer onClick={handleContinue}>No, continuar con el carrito</ButtonContainer>
            </DetailBlock>
        </Container>
    )
}

export default ModalProductsInCart;
