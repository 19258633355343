import { Container, ContainerLoader } from './styled';
import CardCategoryId from 'componentes/CardCategoryId';
import SearchEmpty from 'componentes/SearchEmpty';
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "styled-components";

function ModalSearch({ modal, productsMatch, sucursal, value, search, loading, deliveryPointSelector }) {
    const theme = useTheme();
    return (
        modal && value.length > 0 && (
        <Container deliveryPointSelector={deliveryPointSelector}>
            {loading && (
                <ContainerLoader>
                    <CircularProgress
                    style={{
                        color: theme.secondary,
                        width: "50px",
                        height: "50px",
                    }}
                    />
                </ContainerLoader>
            )}
            {productsMatch?.data?.length === 0 && (
                <SearchEmpty value={value} />
            )}
            {productsMatch !== null && productsMatch?.data?.length > 0 && (   
                <CardCategoryId 
                products={productsMatch?.data} 
                sucursal={sucursal} 
                search={search} 
                algolia={productsMatch}
                />
            )}
        </Container>
        )
  );
}

export default ModalSearch;