import { useContext } from "react";
import { Context } from "context/Context";
import { HeaderGreen, ContainerUser } from "./styled";
import Image from "next/image";
import { capitalizeFirstLetter } from "services/helpers";
import { NameOfShop, ContainerName } from "componentes/DrawerNavigation/styled";
import {
  getLogoByCountryId,
  getBrandNameByCountryId,
} from "services/getLogosByCountryId";

function HeaderDrawerNotVerified() {
  const { setOpenDrawer, dataShop, pais } = useContext(Context);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <HeaderGreen>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ height: "40px", display: "flex" }}>
            {getLogoByCountryId(pais)}
            <ContainerUser>
              <ContainerName>
                {getBrandNameByCountryId(pais)}
                <NameOfShop>{`${capitalizeFirstLetter(
                  dataShop.name
                )}`}</NameOfShop>
              </ContainerName>
            </ContainerUser>
          </div>
          <div style={{ height: "40px", display: "flex", cursor: "pointer" }}>
            <Image
              onClick={handleCloseDrawer}
              src="/imgs/menu.svg"
              alt="logo"
              width={24}
              height={24}
            />
          </div>
        </div>
      </HeaderGreen>
    </>
  );
}

export default HeaderDrawerNotVerified;
