import styled from "styled-components";

export const Container = styled.div`
border-radius: 0px 0px 12px 12px;
z-index: 1;
width: ${props => props.detail ? '61px' : '35.8px'};
padding: ${props => props.detail? '12px 8px' : '4px'};
position: absolute;
background: #FFE713;
height: ${props => props.detail ? '64px' : '35px'};
display: flex;
align-items: center;
justify-content: center;
top: 0px;
right ${props => props.right ? props.right : (props.detail ? '16px': '4px')};
`

export const Percentage = styled.p`
color: #011D0E;
text-align: center;
font-family: Roboto;
font-size: ${props => props.detail ? '20px' : '12px'};
font-style: normal;
font-weight: 800;
line-height: 16px;
`;