export const termsConditionsByCountry = {
1: '',
2: `
Términos y Condiciones para Clientes Finales de Nilus México, S. de R.L. de C.V. (“Nilus”).

Introducción
    
Estos términos y condiciones aplican a los productos vendidos por Nilus a través de las líderes comunitarias. Aunque no hay una relación directa entre Nilus y los clientes finales, estos términos buscan clarificar la naturaleza de los productos y las responsabilidades de todas las partes involucradas.
    
Calidad y Seguridad de los Productos

Los productos ofrecidos son principalmente productos alimentarios y básicos con posibles defectos estéticos que no afectan su calidad general.

Nilus se compromete a cumplir con todas las normativas de seguridad y calidad aplicables.

Responsabilidad por Productos Dañados o Defectuosos
    
Nilus no asume responsabilidad por daños causados por el uso inadecuado de los productos una vez entregados a la líder comunitaria.

En caso de defectos inherentes al producto, la responsabilidad se limita a la sustitución del producto o la devolución del dinero pagado por el cliente final a la líder comunitaria.

Procesos de Reclamación y Devolución

Los procesos para reclamaciones o devoluciones se gestionarán a través de la líder comunitaria, quien actuará como intermediario entre el cliente final y Nilus.

Precisión de la Información y Descripción de Productos

Nilus se esfuerza por asegurar que la información sobre los productos sea precisa y clara. No obstante, Nilus no se hace responsable de la interpretación o la comunicación incorrecta de dicha información por parte de la líder comunitaria.

Manejo de Conflictos y Quejas

Los conflictos o quejas deben ser inicialmente manejados por la líder comunitaria. En casos excepcionales, Nilus puede intervenir para resolver disputas.

Limitación de Responsabilidad

La responsabilidad de Nilus se limita a lo expresado en estos términos y condiciones y los términos y condiciones entre Nilus y las líderes comunitarias. Nilus no será responsable de daños indirectos, incidentales, o consecuenciales que surjan del uso de los productos.

Cambios en los Términos y Condiciones

Estos términos y condiciones pueden ser modificados en cualquier momento Nilus.

Legislación Aplicable y Jurisdicción

Estos términos y condiciones se rigen de conformidad con las leyes y códigos de la Ciudad de México y leyes o códigos federales cuando sea aplicable. La jurisdicción para resolver cualquier controversia bajo estos términos y condiciones será la Ciudad de México.
`,
46: '',
180: '',
59: '',
95: '',
};