import styled from "styled-components";

export const ContainerSliders = styled.ul`
  overflow-x: scroll;
  overflow-y: hidden;
  transform-origin: top left;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
  width: 100%;
  max-width: 490px;
  position: fixed;
  bottom: 25px;
  scroll-snap-type: x mandatory;
  padding: 16px 16px 24px;
  display: flex;
  gap: 8px;
`;

export const SliderCard = styled.div`
border-radius: 8px;
background: white;
box-shadow: 0px 1px 14px 0px rgba(52, 74, 62, 0.30);
display: flex;
justify-content: space-between;
padding: 16px;
flex-direction: column;
align-items: flex-start;
min-width: 244px;
`;

export const ContentWrapper = styled.div`
width: 80%;
max-width: 80%;
flex-shrink: 0;
`;

export const SubtitleDirection = styled.p`
color: ${props => props.theme.textDefault};
font-size: 12px;
font-family: sans-serif;
font-style: normal;
font-weight: 400;
line-height: 14px;
padding: 0px 16px 0px 0px;
box-sizing: border-box;
word-break: break-all; 
`;

export const TitleShop = styled.p`
font-size: 16px;
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-style: normal;
font-weight: 600;
line-height: 20px;
`;

export const DeliveryDate = styled.p`
font-size: 12px;
font-family: sans-serif;
font-style: normal;
font-weight: 400;
line-height: 16px;
color: #3560D5;
margin-top: 3px;
`;

export const ContainerTitle = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
align-items: center;
`

export const Distance = styled.p`
color: ${props => props.theme.textTertiary};
font-family: sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
`

export const ContainerDeliveryDate = styled.p`
display: flex;
padding: 2px 8px;
align-items: center;
border-radius: 16px;
background: #E7EEFB;
margin-top: 8px;
`;

export const TextButton = styled.p`
text-align: center;
font-size: 16px;
font-family: sans-serif;
font-style: normal;
font-weight: 800;
line-height: 16px;
color: white;
`;

export const Button = styled.div`
border-radius: 24px;
background: ${({selected}) => selected ? props => props.theme.secondaryDark : props => props.theme.primary};
display: flex;
width: 100%;
padding: 12px 24px;
justify-content: center;
align-items: center;
gap: 10px;
margin-top: 16px;
&:active {
  opacity: 0.7;
}
`;

export const ContainerAv = styled.div`
margin-top: 8px;
display: flex;
align-items: center;
`;

export const TextAv = styled.p`
font-size: 14px;
font-family: sans-serif;
font-style: normal;
font-weight: 600;
line-height: 14px;
color: ${props => props.theme.textDefault};
margin-left: 4px;
`;