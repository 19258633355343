import styled from 'styled-components';

export const HeaderGreenNotUser = styled.div`
height: 180px;
background-color: ${props => props.theme.secondary};
padding: 16px;
display: flex;
flex-direction: column;
justify-content: space-between;
`;

export const ContainerButton = styled.div`
display: flex;
justify-content: flex-start;
height: auto;
width: 100%
`

export const Button = styled.div`
height: 36px;
padding: 16px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 8px;
border: 2px solid #fff;
&:active{
  opacity: 0.6;
}
`

export const TextButton = styled.p`
color: #FFF;
text-align: center;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 800;
line-height: 14px;
letter-spacing: -0.14px;
`