import { Container, DetailBlock, Close, Subtitle, Title, ContainerButtons, Button } from "../styled"
import Image from 'next/image';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

export default function ModalTyC({ setShowModalTyC, showModalTyC }) {
    const [startY, setStartY] = useState(0);
    
    const handleTouchStart = (event) => {
        setStartY(event.touches[0].clientY);
      };
    
    const handleTouchMove = (event) => {
        const currentY = event.touches[0].clientY;
        const deltaY = currentY - startY;
        const threshold = 100;
    
        if (deltaY > threshold) {
            setShowModalTyC(false);
        }
    };

    const handleCloseModal = (event) => {
        if (event.target === event.currentTarget) {
            setShowModalTyC(false);
        }
    };

    const textsTYC = [
        {  
            id: 2, 
            label:
            <FormattedMessage
            id="modalTyC2"
            defaultMessage="Minimum purchase amount"
            />, 
            title: true,
        },
        {  
            id: 3, 
            label:
            <FormattedMessage
            id="modalTyC3"
            defaultMessage="In order to purchase the unmissable promotions, you must reach a minimum purchase amount indicated in the progress bar."
            />, 
        },
        {  
            id: 4, 
            label:
            <FormattedMessage
            id="modalTyC4"
            defaultMessage="Complete the minimum required with other products from the catalog and get these unmissable promotions!"
            />, 
        },
        {  
            id: 5, 
            label:
           <FormattedMessage
            id="modalTyC5"
            defaultMessage="Please note that the minimum required for the order may be modified by the quantity and type of products you add to the cart."
            />, 
        },
        {  
            id: 6, 
            label:
           <FormattedMessage
            id="modalTyC6"
            defaultMessage="Quantity limit"
            />, 
            title: true,
        },
        {  
            id: 7, 
            label:
           <FormattedMessage
            id="modalTyC7"
            defaultMessage="All products have a quantity limit indicated in the product description."
            />, 
        },
        {  
            id: 8, 
            label:
           <FormattedMessage
            id="modalTyC8"
            defaultMessage="Promotions are valid until the date indicated in the campaign or while supplies last."
            />, 
        },
        {  
            id: 9, 
            label:
           <FormattedMessage
            id="modalTyC9"
            defaultMessage="Mercadito Guarantee"
            />, 
            title: true,
        },
        {  
            id: 10, 
            label:
           <FormattedMessage
            id="modalTyC10"
            defaultMessage="All products sold and distributed by Mercadito are protected by the Mercadito Guarantees (freshness, fair weight and expiration)"
            />, 
        },
    ]

    return (
    showModalTyC &&
        <Container onClick={handleCloseModal} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
            <DetailBlock>
                <Close>
                    <Image src='/imgs/closemodal.svg' width={24} height={24} alt='close' onClick={() => setShowModalTyC(false)} />
                </Close>
                <Title>
                    <FormattedMessage
                        id="titleUmissablePromotions"
                        defaultMessage="Unmissable promotions"
                    />
                </Title>
                {textsTYC.map((text)=>{
                    return (
                        <Subtitle key={text.id} title={text.title}>
                            {text.label}
                        </Subtitle>
                    )
                })}
                <ContainerButtons>
                <Button onClick={() => {
                    setShowModalTyC(false);
                }}>
                    <FormattedMessage
                        id="modalTyC11"
                        defaultMessage="Got it"
                    />
                </Button>
            </ContainerButtons>
            </DetailBlock>
        </Container>
    )
}