import style from 'styles/CardCategory.module.scss';
import { Context } from 'context/Context';
import { useContext, useEffect } from 'react';
import Image from 'next/image';
import { trackSelectItem } from 'componentes/CardCategoryId/tracking';
import nameunitmeasure from 'services/nameunitmeasure';
import { finalDiscount, finalPrice, hasDiscount } from 'services/producthelpers';
import formatNumber from 'services/formatNumber';
import { currencies } from 'services/helpers'
import { ContainerProducts, CardsContainer, Card, InfoCard, ContainerInfo,InfoProduct, ProductName, ContainerPriceAndDiscount, PriceDiscount, Price, ContainerPriceSavings, TextSavings, UOMS, ContainerButton, Button, TextButton, TitleNoResults, SubtitleNoResults, MinimumWarning, MinimumText } from 'componentes/CardCategoryId/styled';
import AddToCartControl from 'componentes/AddToCartControl';
import { capitalizeFirstLetter } from 'services/helpers'
import { FormattedMessage } from 'react-intl'
import { getPriceRange } from 'services/filter';
import CountDown from 'componentes/CountDown';
import calculateTimeDifferenceInSeconds from 'services/calculatedtimediscount';
import { dateSelector } from 'services/featureflags';
import DiscountFlag from 'componentes/DiscountFlag';
import { BadgeExpirationDate, ContainerExpirationDate, DateTextExpirationDate } from 'componentes/ProductId/styled';

  function ProductsInCategory({
  products, 
  categoria, 
  search, 
  isDescription, 
  algolia, 
  filteredProducts, 
  setFilterObject, 
  handleClick, 
  handleClickSearch,
  }){
  const { currentInfoCountry, pais, isCatalogSG, dataShop, deliveryPointSelector, isDefaultTier } = useContext(Context);
  const currency = currentInfoCountry?.currency_code;

  
  const dateSelectorFeature = dateSelector(dataShop?.country_id);
  return (
      <ContainerProducts search={search} showDateSelector={dateSelectorFeature} deliveryPointSelector={deliveryPointSelector}>
        {products?.length > 0 ?
          <>
          <CardsContainer grid={filteredProducts?.length > 0} search={search} isDescription={isDescription} isCatalogSG={isCatalogSG}>
            {filteredProducts?.length > 0 ?
              filteredProducts?.map((item) => {
                let percentage = hasDiscount(item) ? finalDiscount(item).toString() : 0;
                const endDate = item && item["product-discount"] ? new Date(item["product-discount"]["end_date"]) : null;
                const timeDifferenceInSeconds = calculateTimeDifferenceInSeconds(endDate, pais);
                const unmissable = item?.max_units_per_delivery > 0;
                const minimum = item?.minimum_order_value > 0;
                const productTierDiscount = item['product-discount']?.tier_id ?? null

                const expiry_date = item['master-product']?.expiry_date
                ? new Intl.DateTimeFormat('es-MX').format(new Date(item['master-product'].expiry_date))
                : false;

                return (
                  <Card key={item.id}>
                    <InfoCard
                      onClick={() => {
                        !search
                          ? handleClick(item.id)
                          : handleClickSearch(item.id, item.category_id);
                        !search &&
                          trackSelectItem(currency, item, categoria?.name);
                        search &&
                          trackSelectItem(
                            currency,
                            item,
                            categoria?.name,
                            algolia
                          );
                      }}
                    >
                      <ContainerInfo>
                        {hasDiscount(item) && (
                          <div>
                            <DiscountFlag discount={`${finalDiscount(item)}`} />
                          </div>
                        )}

                        <InfoProduct>
                          <div style={{ height: "auto", position: "relative" }}>
                            <Image
                              src={`${item["master-product"].image}`}
                              alt={`${item["master-product"].name}`}
                              width={300}
                              height={280}
                            />
                            {minimum && (
                              <MinimumWarning>
                                <MinimumText>
                                  <FormattedMessage
                                    id="withMinimunTitle"
                                    defaultMessage="With minimum"
                                  />
                                </MinimumText>
                              </MinimumWarning>
                            )}
                          </div>
                          <ContainerPriceAndDiscount>
                            {timeDifferenceInSeconds && (
                              <Image
                                src="/imgs/flash.svg"
                                width={16}
                                height={16}
                                alt="flash"
                              />
                            )}
                            {unmissable && (
                              <Image
                                src="/imgs/fire.svg"
                                width={16}
                                height={16}
                                alt="unmissable"
                              />
                            )}
                            <Price>
                              {currencies[pais]}
                              <span style={{ width: "2px" }} />
                              {formatNumber(finalPrice(item), pais)}
                            </Price>

                            {hasDiscount(item) && (
                              <PriceDiscount>
                                {currencies[pais]}
                                <span style={{ width: "2px" }} />
                                {item.local_price > item.price
                                  ? item.local_price
                                  : item.price}
                              </PriceDiscount>
                            )}
                          </ContainerPriceAndDiscount>
                          {hasDiscount(item) &&
                            (isDefaultTier && productTierDiscount ? (
                              <ContainerPriceSavings>
                                <TextSavings isDefaultTier>
                                  PRIMERA COMPRA
                                </TextSavings>
                              </ContainerPriceSavings>
                            ) : (
                              <ContainerPriceSavings>
                                <Image
                                  src="/imgs/badge-percent.svg"
                                  alt="icon"
                                  width={12}
                                  height={12}
                                />
                                <FormattedMessage
                                  id="textSaveId"
                                  defaultMessage="Save"
                                />{" "}
                                {currencies[pais]}
                                {formatNumber(
                                  item.local_price > item.price
                                    ? item.local_price - finalPrice(item)
                                    : item.price - finalPrice(item),
                                  pais
                                )}
                              </ContainerPriceSavings>
                            ))}
                            {
                              expiry_date && (
                                <ContainerExpirationDate
                                justifyContent={"flex-start"}
                                marginBlock={"8px"}
                              >
                                <BadgeExpirationDate
                                  padding={"2px 4px"}
                                  fontSize={"10px"}
                                >
                                  <Image
                                    src={"/imgs/calendar-x.svg"}
                                    alt={"Calendar"}
                                    width={12}
                                    height={12}
                                  />
                                  {`Vence: `}
                                  <DateTextExpirationDate
                                    fontSize={"10px"}
                                  >
                                    {expiry_date}
                                  </DateTextExpirationDate>
                                </BadgeExpirationDate>
                              </ContainerExpirationDate>
                              )
                            }
                          <UOMS>
                            /
                            {nameunitmeasure(item.unit_of_measurement.erp_name)}
                          </UOMS>
                          <ProductName>
                            {capitalizeFirstLetter(item.name)}
                          </ProductName>
                        </InfoProduct>
                        {timeDifferenceInSeconds && (
                          <CountDown
                            initialTimeInSeconds={timeDifferenceInSeconds}
                            productsView
                          />
                        )}
                      </ContainerInfo>
                    </InfoCard>
                    {!isCatalogSG && (
                      <AddToCartControl
                        product={item}
                        category={search ? item.category.name : categoria?.name}
                        maxWidth={"100%"}
                        algolia={algolia}
                        // reduced
                      />
                    )}
                  </Card>
                );
              }) :
              <div>
                <TitleNoResults>
                     <FormattedMessage 
                          id='noResultsFoundText'
                          defaultMessage='No results found'
                        />
                </TitleNoResults>
                <SubtitleNoResults>
                     <FormattedMessage
                      id='removingFilterSubtitle'
                      defaultMessage='Try removing filters to get results.'
                     />
                </SubtitleNoResults>
                <ContainerButton>
                  <Button onClick={()=> setFilterObject({order:'recommended_sort', range: getPriceRange(products), discount: false})}>
                    <TextButton>
                      <FormattedMessage 
                          id='removeFiltersText'
                          defaultMessage='Remove filters'
                        />
                    </TextButton>
                  </Button>
                </ContainerButton>
              </div>
            }
            </CardsContainer>
           </> :
          <div className={style.noProducts}>
            <h1 className={style.noProductsText}>
              <FormattedMessage
               id='noProductsAvailable'
               defaultMessage="There are no products in this category 😔"                    
              />
            </h1>
          </div>
        }
      </ContainerProducts>
  );
}

export default ProductsInCategory;
