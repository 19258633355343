import styled, { keyframes, css } from "styled-components";

export const Container = styled.div`
width: 100%;
max-width: 490px;
display: flex;
justify-content: center;
transition: max-width 0.3s ease
`

export const ContainerButton = styled.div`
width: 100%;
height: 40px;
display: flex;
padding: 10px 12px;
align-items: center;
background-color: ${props => props.theme.secondaryLighter};
border-radius: 24px;
&:active {
    opacity: 0.9;
}
cursor: pointer;
transition: all 0.3s ease;
margin-top: ${props => props.isSearchButtonMoved && props.deliveryPointSelector ? '8px' :'12px'};
${props => props.noMargin && css`
    margin-top: 0px;
    margin-left: 8px;
`}
`
export const TextSearch = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 16px;
color: ${props => props.theme.secondary};
margin-left: 4px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`;

export const ContainerButtonSg = styled.div`
margin-top: 20px;
width: 100%;
display: flex;
justify-content: flex-start;
border: 1px solid rgba(48, 54, 64, 0.4);
border-radius: 8px;
height: 48px;
padding: 0px 13px;
align-items: center;
margin-right: 16px;
margin-left: 16px;
&:active {
  opacity: 0.6
};
cursor: pointer;
`;

export const TextButtonSg = styled.p`
margin-left: 8px;
font-size: 16px;
font-family: sans-serif;
font-weight: 600;
color: rgba(100, 105, 112, 1);
`;