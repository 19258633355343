import { parse } from "libphonenumber-js";

function transformDate(dateObject) {
  const { year, month, day, hours, minutes } = dateObject;
  const transformedDate = new Date(year, month - 1, day, hours ?? 0, minutes ?? 0).toISOString();
  return transformedDate;
}

export default async function getSearchProducts(catalog_id, search, token, verified_users, userTier) {
    const url = userTier ? `/api/searchproducts/${catalog_id}/v2?search=${search}&token=${token}&tier_id=${userTier}` : `/api/searchproducts/${catalog_id}/v2?search=${search}&token=${token}`
    const response = await fetch(url)
    const data = await response.json()

    if (verified_users === undefined || verified_users === "undefined") {
      data.data = data.data.filter((product) => {
        return !product.max_units_per_delivery > 0;
      });
  }

    data?.data.map((product) => {
      if (product['product-discount'] && product['product-discount'].initial_date) {
        product['product-discount'].initial_date = transformDate(product['product-discount'].initial_date);
      }
    
      if (product['product-discount'] && product['product-discount'].end_date) {
        product['product-discount'].end_date = transformDate(product['product-discount'].end_date);
      }
      if(product['product-discount']) {
        product['product-discount'].discount_percentage = parseFloat(product['product-discount'].discount_percentage.value);
      }
      return product;
    });
    return (data);
}