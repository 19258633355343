import { FormattedMessage } from 'react-intl';
import { ContainerElements, Card, NameOfProduct, NameOfProductContainer, FinalPrice, PreviousPrice, Signe, PercentajeDiscount, SaveText, SaveContainer, UOMS, ContainerImageProduct, ContainerImageDiscount, Flex, Span, MinimumText, MinimumWarning, ContainerPriceSavings } from './styled';
import { useContext } from 'react';
import { Context } from 'context/Context';
import Image from 'next/image';
import { finalDiscount, finalPrice, hasDiscount } from 'services/producthelpers';
import { currencies } from 'services/helpers'
import formatNumber from 'services/formatNumber';
import nameunitmeasure from 'services/nameunitmeasure';
import { capitalizeFirstLetter } from 'services/helpers'
import AddToCartControl from 'componentes/AddToCartControl';
import CountDown from 'componentes/CountDown';
import calculateTimeDifferenceInSeconds from 'services/calculatedtimediscount';
import DiscountFlag from 'componentes/DiscountFlag';
import { BadgeExpirationDate, ContainerExpirationDate, DateTextExpirationDate } from 'componentes/ProductId/styled';

function CardElementVertical({ marginShowEmptyCard, last, item, percentage, unmissable, flashDeals, refetch, carouselType }) {
  let { pais, isCatalogSG, sucursal, router, isDefaultTier } = useContext(Context);

  const productTierDiscount = item['product-discount']?.tier_id ?? null

  const handleClick = (category_id, product_id) => {
    if(unmissable){
      router.push(`/${sucursal}/${category_id}/${product_id}`);
    }else if(flashDeals){
      router.push(`/${sucursal}/${category_id}/${product_id}`);
    }else{
      if(!isCatalogSG) {
        router.push(`/${sucursal}/${category_id}/${product_id}${['recommended_products', 'viewed_products'].includes(carouselType) ? `?carouselType=${carouselType}` : ""}`);

      }
    }
  };
  
  const endDate = item && item["product-discount"] ? new Date(item["product-discount"]["end_date"]) : null;

  const timeDifferenceInSeconds = calculateTimeDifferenceInSeconds(endDate, pais);
  const minimum = item.minimum_order_value > 0;


  const expiry_date = item['master-product']?.expiry_date
  ? new Intl.DateTimeFormat('es-MX').format(new Date(item['master-product'].expiry_date))
  : false;

  return (
       <Card marginShowEmptyCard={marginShowEmptyCard} last={last} carouselType={carouselType}>
         <ContainerElements onClick={()=> handleClick(item.category_id, item.id)}>
            <ContainerImageProduct>
               <Image src={item['master-product'].image} alt={`${item['master-product'].name}`} width={72} height={72} />
               {minimum && !flashDeals && <MinimumWarning>
                  <MinimumText>
                  <FormattedMessage
                    id='withMinimunTitle'
                    defaultMessage='With minimum'
                  />
                  </MinimumText>
                </MinimumWarning>}
            </ContainerImageProduct>
            {hasDiscount(item) && (
              <DiscountFlag discount={finalDiscount(item)} />
            )}
            <Flex>
              <FinalPrice>
                {
                  unmissable &&
                  <Image src='/imgs/fire.svg' width={16} height={16} alt='fire' />
                }
                {
                  timeDifferenceInSeconds && !unmissable &&
                  <Image src='/imgs/flash.svg' width={12} height={12} alt='flash' />
                }
                {currencies[pais]}{formatNumber(finalPrice(item), pais)}
              </FinalPrice>
  
              {hasDiscount(item) && (
                <>
                  <Span/>
                  <PreviousPrice>
                  {currencies[pais]}{item.local_price > item.price ? item.local_price : item.price}
                  </PreviousPrice>
                </>
                )}
            </Flex>
            {
              expiry_date && (
                <ContainerExpirationDate
                paddingRight={"0px"}
                justifyContent={"flex-end"}
                position={"absolute"}
              >
                <BadgeExpirationDate padding={"1px 4px"} fontSize={"9px"}>
                  <Image
                    src={"/imgs/calendar-x.svg"}
                    alt={"Calendar"}
                    width={12}
                    height={12}
                  />
                  {`Vence: `}
                  <DateTextExpirationDate
                    fontSize={"10px"}
                  >{expiry_date}</DateTextExpirationDate>
                </BadgeExpirationDate>
              </ContainerExpirationDate>
              )}
              {timeDifferenceInSeconds && (<CountDown initialTimeInSeconds={timeDifferenceInSeconds} refetch={refetch}/>)}
              {hasDiscount(item) && (
              (isDefaultTier && productTierDiscount) ? (
              <SaveContainer>
                <SaveText isDefaultTier>
                 PRIMERA COMPRA
                </SaveText>
              </SaveContainer>
              ) : (
                  <ContainerPriceSavings>
                    <Image src='/imgs/badge-percent.svg' alt='icon' width={12} height={12} /> 
                    <FormattedMessage
                    id='textSaveId'
                    defaultMessage="Save"                    
                  />{' '}
                  {currencies[pais]} 
                  {formatNumber(item.local_price > item.price ? item.local_price - finalPrice(item) : item.price - finalPrice(item), pais)}
                </ContainerPriceSavings>
                ))
            }
              <UOMS>
                  /{nameunitmeasure(item.unit_of_measurement.erp_name)}
              </UOMS>
              <NameOfProductContainer>
                <NameOfProduct discount={hasDiscount(item)}>
                  {capitalizeFirstLetter(item.name)}
                </NameOfProduct>
              </NameOfProductContainer>
          </ContainerElements>
              {
              !isCatalogSG &&
              <AddToCartControl
              product={item}
              category={'N/A'}
              maxWidth={'100%'}
              carouselType={carouselType}
              />
              }
      </Card>
  );
}

export default CardElementVertical;
