import styled, { keyframes }  from 'styled-components'

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: center;
background-color:rgb(48, 54, 64, 0.7);
height: 100vh;
width: 100vw;
position: fixed;
top: 0;
z-index: 40;
max-width: 490px;
`

export const DetailBlock = styled.div`
min-height: 60vh;
height: ${props => props.modalReport && 'auto'};
width: 100vw;
background: #FFF;
position: relative;
animation: ${slideIn} 0.5s ease-in-out forwards;
padding: ${props => props.modalReport? '0px 0px 140px 0px' :'16px 16px 24px 16px' };
max-width: 490px;
`

export const Close = styled.div`
display: flex;
justify-content: flex-start;
padding: ${props => props.modalReport ? '16px 0px 8px 12px' : '8px 0px'};
position: ${props => props.modalReport && 'fixed'};
width: 100%;
z-index: ${props => props.modalReport && 9};
background: ${props => props.modalReport && 'white'};
`
export const Title = styled.div`
color: #000;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px;
padding: 8px 0px;
`;

export const Text = styled.div`
color: #000;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
padding: 8px 0;
width: ${props => props.paragraph === 0 ? '72%': '100%' }
`

export const StampImage = styled.div`
position: absolute;
top: 35px;
right: 16px;
`
