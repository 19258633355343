import { Container, Percentage } from './styled'

const DiscountFlag = ({ discount, detail, right }) => {
    return(
     <Container detail={detail} right={right}> 
       <Percentage detail={detail}>
            -{discount}%
       </Percentage>  
    </Container>
    )
}

export default DiscountFlag;