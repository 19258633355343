import styled, { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%)
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  margin: 0 auto;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 490px;
  z-index: 9;
  overflow: hidden;
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  animation: ${({ isOpen }) => (isOpen ? fadeIn : fadeOut)} 0.3s ease;
  max-width: 490px;
  margin: 0 auto;
`;

export const ModalContent = styled.div`
  background-color: #FAFBFA;
  width: 83%;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  padding-bottom: 16px;
`;

export const HeaderGreen = styled.div`
height: 180px;
background-color: ${props => props.theme.secondary};
padding: 16px;
display: flex;
flex-direction: column;
position: relative;
margin-bottom: 24px;
`;

export const ContainerCardWallet = styled.div`
padding: 0px 16px;
display: flex;
justify-content: center;
position: absolute;
z-index: 2;
top: 140px;
width: 100%;
left: 0px;
`

export const CardWallet = styled.div`
width: 100%;
border-radius: 8px;
border: 1px solid #F2F3F2;
background: #FFF;
height: 56px;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0px 8px 0px 16px;
`

export const ContainerAmountWallet = styled.div`
display: flex;
align-items: center;
justify-content: center;
`

export const AmountWallet = styled.p`
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 800;
line-height: 20px;
margin-right: 8px;
`

export const ContainerItems = styled.div`
display: flex;
align-items: center;
height: 52px;
margin: 0px 0px;
cursor: pointer;
background: #FAFBFA;
`;

export const Item = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: 800;
font-size: 16px;
line-height: 16px;
color: #011D0E;
margin-left: 8px;
${({ elipsis }) =>
elipsis &&
`
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box; 
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
width: 90%
`};
`;

export const ContainerUser = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
margin-left: 16px;
`

export const TitleHeader = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 28px;
color: ${props => props.theme.white};
margin-left: 7px;
height: 25px;
`

export const ContainerClose = styled.div`
position: absolute;
top:  55px;
z-index: 4;
right: 5%;
cursor: pointer;
animation: ${fadeIn} 0.3s ease;
opacity: 1; 
`

export const CategoryName = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #011D0E;
padding-right: 16px;
`

export const ContainerButton = styled.div`
display: flex;
justify-content: flex-start;
height: auto;
width: 100%
`

export const TextCard = styled.p`
font-feature-settings: 'clig' off, 'liga' off;
font-family: sans-serif;
font-size: 12px;
font-style: normal;
font-weight: ${({ noBold}) => noBold ? '400' : '600'};
line-height: 18px;
display: ${({ advice }) => advice ? 'block' : 'flex'};
align-items: center;
color: #000;
text-decoration: underline;
margin-top: ${({ advice }) => advice ? '4px' : '0px'};
padding: ${({ advice }) => advice && '0px 8px'};
${({ info }) => info && css`
gap: 6px;
`};
`