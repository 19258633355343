import { defineMessages } from 'react-intl';

export default defineMessages({
recommended: {
  id: 'labelRecommendedIdFilter',
    defaultMessage: 'Recommended',
  },
  price: {
    id: 'labelPriceIdFilter',
    defaultMessage: 'Price: low to high',
  },
  discount: {
    id: 'labelDiscountFilter',
    defaultMessage: 'Discounts: higher to lower',
  },
  newest: {
    id: 'labelNewestFilter',
    defaultMessage: 'The newest',
  },
  alphabetic: {
    id: 'labelAlphabeticFilter',
    defaultMessage: 'Alphabetic',
  },
});
