import styled from  'styled-components'

export const ContainerMessage = styled.div`
background-color: white;
color: ${props => props.theme.primary};
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
margin-left: ${props => props.isSearchButtonMoved && '8px'};
margin-top: ${props => !props.isSearchButtonMoved && '12px'};
padding: 6px 4px;
border-radius: 8px;
height: 40px;
`

export const TextMessage = styled.p`
color: ${props => props.select ? '#303640' : props.theme.primary};
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
text-decoration-line: underline;
${({ truncate }) =>
truncate &&
`
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box; 
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
width: 75%
`};
`

export const Strong = styled.strong`
color: white;
font-size: 14px;
`

export const Title = styled.p`
color: ${props => props.select ? '#303640' : props.theme.primary};
font-size: 10px;
font-style: normal;
font-weight: 500;
font-family: Roboto;
font-style: normal;
line-height: 16px;
`

export const Chip = styled.div`
color: ${props => props.theme.primary};
background: ${props => props.theme.primaryLighter};
padding: 2px 4px;
border-radius: 32px;
display: flex;
align-items: center;
justify-content: center;
position: absolute;
right: 4px;
bottom: 0px;
`;

export const ChipText = styled.p`
font-family: sans-serif;
font-weight: 500;
font-size: 12px;
color: ${props => props.theme.primary};
margin-left: 0.5px;
line-height: 16px;
`;