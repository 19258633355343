import { useTheme } from 'styled-components'
import { useContext, useEffect } from 'react';
import { Context } from 'context/Context';
import { ContainerMinimum, TitleMinimum, ContainerTitle, ContainerBar } from './styled';
import Image from 'next/image';
import LinearProgress, {linearProgressClasses} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { useState } from 'react';
import ModalTyC from './components/modaltyc';
import { FormattedMessage } from 'react-intl';
import { minimumOrderValueBar, modalInfoMinimunOrderValue } from './tracking';

export default function MinimumOrderValue({ block }) {
    const theme = useTheme();
    const { minimumOrderValue, total, pais } = useContext(Context);
    const [showModalTyC, setShowModalTyC] = useState(false);
    const [showConfetti, setShowConfetti] = useState(total >= minimumOrderValue ? null : false);

    const value = Math.round((minimumOrderValue - total)* 100) / 100;
    const exceededAmount = value <= 0;
    const newValue = (total / minimumOrderValue * 100) <= 100 ? (total / minimumOrderValue * 100) : 100;

    const [prevValue, setPrevValue] = useState(newValue);

    const handleMinimumOrderValueBar = () => {
        if (value > 0) {
            minimumOrderValueBar(false, value, total, pais);
        } else {
            minimumOrderValueBar(true, null, total, pais);
        }
    };

    useEffect(() => {
        handleMinimumOrderValueBar();
    }, []);

    useEffect(() => {
        if ((prevValue < 100 && newValue === 100) || (prevValue === 100 && newValue < 100)) {
            handleMinimumOrderValueBar();
        }
        setPrevValue(newValue);
    }, [newValue, prevValue]);

    useEffect(() => {
        if(minimumOrderValue && total && !showConfetti && showConfetti !== null) {
            if(total >= minimumOrderValue) {
                setShowConfetti(true);
            }
        }
        if(total < minimumOrderValue) {
            setShowConfetti(false);
        }
    }, [total, minimumOrderValue])

    const backgroundGradient = (value) => {
        if(value <= 40){
            return `linear-gradient(90deg, ${theme.primary}  0%, ${theme.warning} 100%)`
        }
        if(value < 100){
            return `linear-gradient(90deg, ${theme.primary} 0%, ${theme.warning} 100%)`
        }
        return theme.secondary;
    }

    const BorderLinearProgress = styled(LinearProgress)(({ value }) => ({
        height: 4,
        borderRadius: '8px',
        width:'100%',
        [`&.${linearProgressClasses.root}`]: {
          backgroundColor: 'rgba(217, 217, 217, 1)',
          width:'100%',
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: '8px',
          background: backgroundGradient(value),
          width:'100%',
        }
      }));
    
    return(
        <ContainerMinimum block={block}>
            {showModalTyC && <ModalTyC showModalTyC={showModalTyC} setShowModalTyC={setShowModalTyC} />}
            <ContainerBar showConfetti={showConfetti}>
                {exceededAmount ? (
                <ContainerTitle onClick={()=> {
                    modalInfoMinimunOrderValue(pais, 'Bar view');
                    setShowModalTyC(true);
                }}>
                    <TitleMinimum>
                        <FormattedMessage
                        id="youReachMinimun"
                        defaultMessage="You reached the minimum amount!"
                        />
                    </TitleMinimum>
                    <Image src='/imgs/information-circle.svg' alt='information' width={16} height={16} />
                </ContainerTitle>
                ) : (
                <ContainerTitle onClick={()=> {
                    modalInfoMinimunOrderValue(pais, 'Bar view');
                    setShowModalTyC(true);
                }}>
                    <TitleMinimum>
                    <FormattedMessage
                        id="addToReachMinimun"
                        defaultMessage="Add ${value} to reach the minimum amount"
                        values={{ value }}
                        />
                    </TitleMinimum>
                    <Image src='/imgs/information-circle.svg' alt='information' width={16} height={16} onClick={()=> setShowModalTyC(true)}/>
                </ContainerTitle>
                )}
                 <BorderLinearProgress variant="determinate" value={newValue} />
            </ContainerBar>
        </ContainerMinimum>
    )
}