import MapOrder from 'componentes/MapOrder';
import { Card, TitleCard, SubtitleCard, ContainerText, SelectButton, ButtonText, FlexRow, ContainerDeliveryDate, DeliveryDate, Distance, ContainerFreeHomeDelivery } from './styled';
import Image from 'next/image';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { distanceShopsToUser, haversineDistance } from 'services/helpers';
import { trackShopChosen } from 'componentes/ShopsSlider/tracking';


const ShopCard = ({ shop, selected, setSelectedDeliveryPoint, setModalDeliveryPointSelector, actualLocation, setShopsInBound, setIsList, setCenter }) => {
    const street = shop?.address?.street;
    const number = shop?.address?.number;
    const month = shop?.next_delivery_dates[0]?.split(' ')[1];
    const date = shop?.next_delivery_dates[0]?.split(' ')[2];
    const year = shop?.next_delivery_dates[0]?.split(' ')[3];
    const hasDelivery = shop?.has_delivery;
    const deliveryDate = new Date(`${month} ${date}, ${year}, 12:00:00`);

    return (
      <Card>
        <FlexRow>
          <div
            onClick={() => {
              const location = shop?.ShopLocation ?? shop?.shop_location;
              setShopsInBound([
                {
                  ...shop,
                  shop_location: { ...location },
                },
              ]);
              setIsList(false);
              if (location) {
                setCenter({
                  lat: location?.Latitude,
                  lng: location?.Longitude,
                });
              }
            }}
          >
            <Image src="/imgs/map.png" alt="icon" width={72} height={72} />
          </div>
          <ContainerText>
            <FlexRow>
              <TitleCard>{shop.name}</TitleCard>
              {actualLocation && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Image
                    src="/imgs/walk-outline.svg"
                    alt="ubicationLogo"
                    width={16}
                    height={16}
                  />
                  <Distance>
                    {distanceShopsToUser(
                      haversineDistance(
                        {
                          Latitude: shop.address.latitude,
                          Longitude: shop.address.longitude,
                        },
                        actualLocation
                      ),
                      shop.coutry_id
                    )}
                  </Distance>
                </div>
              )}
            </FlexRow>
            <SubtitleCard>
              <Image
                src="/imgs/map-pin.svg"
                alt="icon"
                width={16}
                height={16}
              />
              {street} {number}
            </SubtitleCard>
            <ContainerDeliveryDate>
              <DeliveryDate>
                <FormattedMessage
                  id="youPickUpText"
                  defaultMessage="You pick-up on"
                />{" "}
                <FormattedDate value={deliveryDate} weekday="long" />{" "}
                <FormattedDate
                  value={deliveryDate}
                  month="long"
                  day="numeric"
                />
              </DeliveryDate>
            </ContainerDeliveryDate>
            {hasDelivery && (
            <ContainerFreeHomeDelivery>
              <Image
                src="/imgs/truck-delivery.svg"
                alt="Free Home Delivery"
                width={16}
                height={16}
              />
              <FormattedMessage
                id="freeHomeDelivery"
                defaultMessage="Free home delivery"
              />
            </ContainerFreeHomeDelivery>)}
          </ContainerText>
        </FlexRow>
        <SelectButton
          selected={selected}
          onClick={() => {
            if (!selected) {
              setSelectedDeliveryPoint(shop);
              setModalDeliveryPointSelector(false);
              trackShopChosen(shop?.id, shop?.community_leader_id);
            }
          }}
        >
          {selected ? (
            <ButtonText>
              <FormattedMessage
                id="youPickUpHere"
                defaultMessage="You pick up here"
              />
            </ButtonText>
          ) : (
            <ButtonText>
              <FormattedMessage
                id="selectThisPoint"
                defaultMessage="Select this delivery point"
              />
            </ButtonText>
          )}
        </SelectButton>
      </Card>
    );
}

export default ShopCard;