import styled, { keyframes }  from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color:rgb(48, 54, 64, 0.7);
height: 100vh;
width: 100vw;
position: fixed;
top: 0;
z-index: 40;
max-width: 490px;
`
export const DetailBlock = styled.div`
display: flex;
width: 312px;
padding: 32px 16px 16px 16px;
flex-direction: column;
align-items: center;
gap: 16px;
border-radius: 8px;
background: #FFF;
`
export const ButtonContainer = styled.div`
display: flex;
height: 44px;
padding: 16px 24px;
justify-content: center;
align-items: center;
gap: 10px;
flex: 1 0 0;
border-radius: 8px;
border: ${props => props.border ? '1px solid #00904B' : 'none'};
color: #00904B;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 800;
line-height: 16px; /* 100% */
width: 100%;
`
export const Title = styled.div`
color: var(--dark-text-primary, #001109);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 26px; /* 130% */
`
export const Text = styled.div`
color: var(--dark-text-primary, #001109);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
`
export const StampImage = styled.div`
position: absolute;
top: 35px;
right: 16px;
`
