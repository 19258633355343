import { track } from 'services/gtm';
import { totalDiscountNumber } from 'services/producthelpers';

export function trackSelectItem(currency, product, categoryName, algolia) {
  track('select_item', {
    ecommerce: {
      items: [
        {
          item_name: product.name,
          item_id: product.id,
          price: product.price,
          discount: totalDiscountNumber(product),
          currency: currency,
          quantity: 1,
          item_category: categoryName,
          item_category2: product.category_id,
        },
      ],
    },
    queryID: algolia?.queryID,
    eventType: 'click',
    index: algolia?.index,
    position: product.position,
    objectID: product.id,
  });
}
