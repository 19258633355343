import styled, {css} from 'styled-components'

export const ContainerTextEnds = styled.div`
display: flex;
width: 64px;
justify-content: flex-start;
position: absolute;
top: 50px;
right: 8px;
background-color: white;
${props => props.productsView && css`
top: 100px;
width: 68px;
`};
${props => props.product && css`
top: 80px;
width: 85px;
right: 16px;
`};
${props => props.cart && css`
top: 60px;
left: 16px
`};
${props => props.verificationPrices && css`
top: 80px;
`};
`

export const ContainerCountDown = styled.div`
display: flex;
width: 64px;
justify-content: space-between;
position: absolute;
top: 60px;
right: 8px;
${props => props.productsView && css`
width: 68px;
top: 110px;
`};
${props => props.product && css`
top: 95px;
width: 94px;
right: 16px;
`};
${props => props.cart && css`
top: 70px;
left: 16px
`};
${props => props.verificationPrices && css`
top: 95px;
`};
`

export const TextEnds = styled.p`
color: #000;
text-align: center;
font-family: sans-serif;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: 10px;
letter-spacing: -0.05px;
text-transform: uppercase;
${props => props.productsView && css`
font-size: 11px;
line-height: 11px;
`};
${props => props.product && css`
font-size: 12px;
line-height: 12px;
`};
`

export const BoxNumber = styled.div`
width: 20px;
height: 16px;
border-radius: 2px;
background: ${props => props.theme.textDefault};
display: flex;
justify-content: center;
${props => props.productsView && css`
width: 22px;
height: 20px;
`};
${props => props.product && css`
width: 30px;
height: 28px;
`};
`

export const TextBox = styled.p`
color: ${props => props.time ?'#FFB69B' : props.theme.secondaryLight};
text-align: center;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 16px;
${props => props.productsView && css`
line-height: 19px;
`};
${props => props.product && css`
line-height: 28px;
font-size: 16px;
`};
`
