import { useRouter } from 'next/router';
import style from 'styles/ProductId.module.scss';
import styles from 'styles/CardCategory.module.scss';
import { Context } from 'context/Context';
import { useContext } from 'react';
import Image from 'next/image';
import { ContainerGrid } from './styled'
import { capitalizeFirstLetter } from 'services/helpers'

function GridCards({items, search}) {
    const router = useRouter();
    const { sucursal } = useContext(Context);

    const handleClick = (category_id) => {
      router.push(`/${sucursal}/${category_id}`);
    };
  
  return (
    <ContainerGrid style={{padding:`${search ? '0px 0px 60px 0px': '0px 16px 30px 16px'}`}}>
        {items.map((item) => {
          const imgUrl = item.image ? item.image : '/defaultproducto.png';
           return (
             <div
              key={item.id}
              onClick={() => handleClick(item.id)}>
                <div className={`card ${styles.customCard}`}>
                   <div className={styles.containerImgList}>
                      <Image
                        src={imgUrl}
                        alt={item?.name}
                        width={200}
                        height={200}
                        />
                    </div>
                    <div className={style.CardSpace}>
                      {item?.data?.nombre === 'promos' ? (
                        <div className={styles.cardOffer}>
                          <p className={styles.cardTitle2}>
                            {capitalizeFirstLetter(item.data.nombre)}
                          </p>
                        </div>
                        ) : (
                        <div className={styles.cardNoOffer2}>
                          <p className={styles.cardTitle2}>
                            {capitalizeFirstLetter(item?.name)}
                          </p>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
    </ContainerGrid>
    );
}

export default GridCards;
