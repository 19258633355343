import { Container, ContainerClose, TermTitleReferred, ContainerTitleAndImage, ElementFix } from './styled';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';
import ProductsHighlightsComponent from 'componentes/ProductsHighlightsComponent';

const ModalThemed = ({ modal, setModal, flashDeals, products, sucursal }) =>  {
    return (
      modal && (
      <Container flashDeals={flashDeals}>
          <ElementFix flashDeals={flashDeals}>
          <ContainerClose>
              <Image src='/imgs/closemodal.svg' alt='close' width={24} height={24} alt='close' onClick={()=> setModal(!modal) } />
          </ContainerClose>
        <ContainerTitleAndImage>
                {
                flashDeals &&
                <Image src='/imgs/flash.svg' width={16} height={16} alt='flash' />
                }
                <TermTitleReferred>
                    {flashDeals &&
                        <FormattedMessage
                        id='titleFlashDeals'
                        defaultMessage='Flash deals'
                        />
                    }
                </TermTitleReferred>
        </ContainerTitleAndImage>
        </ElementFix>
        <ProductsHighlightsComponent  sucursal={sucursal} products={products} flashDeals={flashDeals} />
      </Container>
      )
    );
  }

export default ModalThemed;