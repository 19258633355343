import { useContext } from 'react';
import { Context } from 'context/Context';
import { HeaderGreen, TitleHeader, CircleIcon, Letter, ContainerCardWallet, CardWallet, ContainerAmountWallet, AmountWallet } from './styled';
import Image from 'next/image';
import { capitalizeFirstLetter } from 'services/helpers';
import { FormattedMessage } from 'react-intl';
import formatNumber from 'services/formatNumber';
import { trackViewWallet } from './tracking';

function HeaderDrawerUserWallet({customer, walletAmount}) {
    const { 
      setOpenDrawer,
      sucursal,
      router,
      pais,
      profilePicture
    } = useContext(Context);
  
    const handleCloseDrawer = () => {
      setOpenDrawer(false);
    };
    
    const firstLetterName = customer?.customer?.name ? customer.customer.name.charAt(0).toUpperCase() : '';
    const firstLetterLastName = customer?.customer?.last_name ? customer.customer.last_name.charAt(0).toUpperCase() : '';

    return (
            <HeaderGreen>
                <div style={{display: 'flex', alignItems:'flex-start', justifyContent:'space-between'}}>
                  <div style={{display: 'flex', flexDirection:'column', marginTop:'8px'}}>
                    {
                      profilePicture ? (
                        <CircleIcon onClick={()=> {
                          handleCloseDrawer();
                          router.push(`/${sucursal}/user`);
                        }}  style={{ borderRadius: '50%', overflow: 'hidden', position: 'relative' }}>
                          <Image 
                            src={profilePicture} 
                            alt='profilePicture' 
                            layout="fill" 
                            objectFit="cover" 
                            width={140}
                            height={140}
                          />
                        </CircleIcon>
                      ) : (
                        <CircleIcon onClick={()=> {
                          handleCloseDrawer();
                          router.push(`/${sucursal}/user`);
                        }}>
                            <Letter>{firstLetterName}{firstLetterLastName}</Letter>
                        </CircleIcon>
                      )
                    }
                    <TitleHeader>
                    ¡<FormattedMessage
                        id='textGreetings'
                        defaultMessage="Hi, "                    
                        />
                        {' '}
                        {capitalizeFirstLetter(customer?.customer?.name)}!
                    </TitleHeader>
                  </div>
                  <div style={{height: '40px', display: 'flex', cursor:'pointer'}}>
                        <Image onClick={handleCloseDrawer} src='/imgs/menu.svg' alt='logo' width={24} height={24}/>
                  </div>
               </div>
            <ContainerCardWallet>
                      <CardWallet onClick={()=> {
                        handleCloseDrawer();
                        trackViewWallet(walletAmount);
                        router.push(`/${sucursal}/wallet`);
                      }}>
                        <Image src='/imgs/monederoLogo.svg' width={100} height={16} alt='monedero' />
                        <ContainerAmountWallet>
                          <AmountWallet>{`$${formatNumber(walletAmount, pais)}`}</AmountWallet>
                          <Image src='/imgs/chevronRight.svg' width={16} height={16} alt='arrow' />
                        </ContainerAmountWallet>
                      </CardWallet>
                    </ContainerCardWallet>
            </HeaderGreen>
    );
  }
  
  export default HeaderDrawerUserWallet;