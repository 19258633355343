import styled from 'styled-components';

export const HeaderGreenNotUser = styled.div`
height: 180px;
background-color: ${props => props.theme.secondary};
padding: 16px;
display: flex;
flex-direction: column;
justify-content: space-between;
`;

export const TitleHeader = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 28px;
color: ${props => props.theme.white};
height: 25px;
`

export const CircleIcon = styled.div`
width: 64px;
height: 64px;
border-radius: 100%;
background-color: white;
margin-bottom: 8px;
display: flex;
justify-content: center;
align-items: center;
`

export const Letter = styled.p`
color: ${props => props.theme.information};
font-family: sans-serif;
font-size: 36px;
font-style: normal;
font-weight: 800;
line-height: 36px;
`