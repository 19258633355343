import Image from "next/image"
import { Container, DetailBlock, Close, DateSelector, Title } from "./styled"
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ButtonSolid from "componentes/ButtonSolid";
import { TextField } from "@material-ui/core";
import messages from "./messages";
import { is } from "@react-spring/shared";

const ModalInputQuantity = ({ setModalInputQuantity, quantity, setQuantity, action }) => {
    const [startY, setStartY] = useState(0);
    const intl = useIntl();

    const handleTouchStart = (event) => {
        setStartY(event.touches[0].clientY);
      };
      
    const handleTouchMove = (event) => {
        const currentY = event.touches[0].clientY;
        const deltaY = currentY - startY;
        const threshold = 100; // Umbral de desplazamiento para cerrar el modal
    
        if (deltaY > threshold) {
            setModalInputQuantity(null);
        }
    };

    const handleCloseModal = (event) => {
        if (event.target === event.currentTarget) {
            setModalInputQuantity(null);
        }
    };
    return (
        <Container onClick={handleCloseModal} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} >
            <DetailBlock>
                <div>
                    <Close>
                        <Image src='/imgs/closemodal.svg' width={24} height={24} alt='close' onClick={() => setModalInputQuantity(null)} />
                    </Close>
                    <Title>
                        {intl.formatMessage(messages.titleInputQuantity)}
                    </Title>
                    <DateSelector>
                    <TextField
                        id="quantity"
                        type="number"
                        name="quantity"
                        inputProps={{ inputMode: 'numeric' }}
                        label={intl.formatMessage(messages.labelQuantity)}
                        color="success"
                        variant="outlined"
                        sx={{ width: '100%', borderRadius: '10px' }}
                        defaultValue={quantity}
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if(isNaN(value)) {
                                setQuantity(null);
                            } else {
                                setQuantity(value);
                            }
                        }}
                    />
                    </DateSelector>
                </div>
                <ButtonSolid disabled={quantity === null} defaultMessage={'Confirm'} id={'labelConfirmButton'} action={() => {
                    action(parseInt(quantity))
                    setModalInputQuantity(false);
                    setQuantity(null);
                }} fullWidth={true} />
            </DetailBlock>
        </Container>
    )
}

export default ModalInputQuantity;
