import styled from  'styled-components'
import { snackBarGray, fontPrimary } from '../Styles/Global'

export const Container = styled.div`
width: 100%;
max-width: 490px;
height: 20px;
display: flex;
padding: 0px 16px 12px;
flex-direction: column;
position: fixed;
bottom: ${ props => props.bottom ? props.bottom : '160px'};
top: ${props => props.top && '70px'};
left: 0px;
z-index: 9;
`

export const ContainerArrow = styled.div`
width: 100%;
max-width: 490px;
height:  30px;
display: flex;
justify-content: flex-end;
margin: 0 auto;
position: fixed;
top: 60px;
padding-right: 35px;
`

export const SnackBarStyled = styled.div`
background-color: ${snackBarGray};
width: 100%;
height: auto;
border-radius: 8px;
padding: 16px;
`

export const Title = styled.p`
font-family: ${fontPrimary};
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 22px;
color: #fff;
`

export const Subtitle = styled.p`
font-family: ${fontPrimary};
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #fff;
margin-top: 7px;
`
export const ContainerFlex = styled.div`
display: flex;
justify-content: space-between;
`

export const LinkText = styled.span`
align-self: stretch;
color: #FFF;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 18px; /* 128.571% */
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 800;
line-height: 14px;
letter-spacing: -0.14px;
text-decoration: underline;
margin-left: 8px;
`
