import { useContext } from 'react';
import { Context } from 'context/Context';
import { Card, ContainerImage, ContainerInfoProduct, ContainerImageDiscount, PercentajeDiscount, Signe, Price, PreviousPrice, Span, Flex, SaveContainer, SaveText, NameOfProduct, NameOfProductContainer, ContainerCard, ContainerPriceSavings } from './styled';
import Image from 'next/image';
import { finalDiscount, finalPrice, hasDiscount } from 'services/producthelpers';
import { currencies } from 'services/helpers'
import formatNumber from 'services/formatNumber';
import { FormattedMessage } from 'react-intl';
import { capitalizeFirstLetter } from 'services/helpers'
import AddToCartControl from 'componentes/AddToCartControl';
import DiscountFlag from 'componentes/DiscountFlag';

function CardSingleHorizontal({last, item, deleteProducts, carouselType, modalReport}) {
  let { pais, isCatalogSG, sucursal, router, isDefaultTier } = useContext(Context);
  const productTierDiscount = item['product-discount']?.tier_id ?? null

  let percentage = hasDiscount(item) ? finalDiscount(item).toString() : 0;
  
  const handleClick = (category_id, product_id) => {
    if(!isCatalogSG) {
      router.push(`/${sucursal}/${category_id}/${product_id}${['recommended_products', 'viewed_products'].includes(carouselType) ? `?carouselType=${carouselType}` : ""}`);
    }
  };

  return (
    <ContainerCard>
     <Card last={last}>
       <div style={{display:'flex', width:'100%', alignItems:'center'}}>
         <div style={{width:'80px'}}>
            <ContainerImage onClick={()=> handleClick(item.category_id, item.id)}>
              <Image src={item['master-product'].image} width={68} height={68} alt={`${item['master-product'].name}`}/>
              {hasDiscount(item) && (
                <DiscountFlag discount={finalDiscount(item)} right='50px' />
              )}
            </ContainerImage>
       </div>
       <ContainerInfoProduct onClick={()=> handleClick(item.category_id, item.id)}>
         <Flex>
            <Price>
            {currencies[pais]}{formatNumber(finalPrice(item), pais)}
            </Price>
            {hasDiscount(item) && (
                  <>
                    <Span/>
                    <PreviousPrice>
                    {currencies[pais]}{item.local_price}
                    </PreviousPrice>
                  </>
            )}
          </Flex>
          {hasDiscount(item) && (
          (isDefaultTier && productTierDiscount) ? (
              <SaveContainer>
                <SaveText isDefaultTier>
                 PRIMERA COMPRA
                </SaveText>
              </SaveContainer>
              ) : (
                <ContainerPriceSavings>
                <Image src='/imgs/badge-percent.svg' alt='icon' width={12} height={12} /> 
                <FormattedMessage
                id='textSaveId'
                defaultMessage="Save"                    
              />{' '}
              {currencies[pais]} 
              {formatNumber(item.local_price > item.price ? item.local_price - finalPrice(item) : item.price - finalPrice(item), pais)}
            </ContainerPriceSavings>
                ))
            }
          <NameOfProductContainer>
                <NameOfProduct discount={hasDiscount(item)}>
                  {capitalizeFirstLetter(item.name)}
                </NameOfProduct>
          </NameOfProductContainer>
       </ContainerInfoProduct>
       </div>
       {!modalReport && (
       <AddToCartControl               
       product={item}
       category={'N/A'}
       maxWidth={'100%'}
       cart
       deleteProducts={deleteProducts}
       carouselType={carouselType}
     />
       )}
     </Card>
     </ContainerCard>
  );
}

export default CardSingleHorizontal;
