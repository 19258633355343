import styled, { css }  from 'styled-components'
import { primaryGreen } from '../Styles/Global'

export const ContainerButton = styled.div`
display: flex;
align-self: center;
margin-bottom: 24px;
justify-content: center;
margin-top: 24px;
width: 100%;
`

export const Button = styled.div`
display: flex;
align-self: center;
background: ${props => props.theme.primary};
opacity: ${props => props.disabled ? '0.5' : '1'};
justify-content: center;
align-items: center;
padding: 10px 0px;
border-radius: 24px;
width: ${props => props.fullWidth ? '100%' : '90%'};
height: auto;
`

export const TextButton = styled.p`
font-size: 18px;
color: #FFF;
font-weight: 600;
`