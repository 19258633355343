import { useContext, useEffect, useState } from 'react';
import { Context } from 'context/Context';
import {ContainerButton, Button, TextButton, StyledImage,ContainerButtonCart, ButtonAdd } from './styled'
import { cartLine } from 'services/cart';
import Image from 'next/image';
import { trackAddToCart, trackRemoveFromCart } from './tracking';
import { FormattedMessage, useIntl } from 'react-intl';
import { finalPrice } from 'services/producthelpers';
import SnackBar from 'componentes/SnackBar';
import { inputLineQuantity } from 'services/featureflags';
import ModalInputQuantity from './components/ModalInputQuantity';
//lucide icons
import { Minus, Plus, Trash } from 'lucide-react';
import messages from './messages';

function AddToCartControl({ product, category, reduced, maxWidth, padding, height, cart, algolia, setSoldOut, carouselType, newGrid }) {
  let { carrito, nuevoCarrito, handleBorrar, currentInfoCountry, setDeleteProducts, deleteProducts, pais } = useContext(Context)
  const intl = useIntl();
  const [quantity, setQuantity] = useState(null)
  const [maxUnitPerOrder, setMaxUnitPerOrder] = useState(null)
  const [productReachedMax, setProductReachedMax] = useState(false)
  const [addAdvice, setAddAdvice] = useState(false)
  const [buttonReduced, setButtonReduced] = useState(reduced)
  const [resetTimeOut, setResetTimeOut] = useState(false)
  const inputQuantity = inputLineQuantity(pais);
  const [lineQuantity, setLineQuantity] = useState(inputQuantity ? null : 1)
  const [modalInputQuantity, setModalInputQuantity] = useState(false)
 
  const maxUnits = product.max_units_per_delivery > 0 ? product.max_units_per_delivery : (product.max_units_per_order > 0 ? product.max_units_per_order: null);

  product.notes = carrito.filter((line)=> line.productid === product.id)[0]?.notes;

  const currency = currentInfoCountry?.currency_code;
  
  useEffect(() => {
    carrito &&
    setQuantity(carrito.filter(line => line.productid === product.id).length > 0 ? carrito.filter(line => line.productid === product.id)[0]?.cantidad : 0)
    carrito && inputLineQuantity &&
    setLineQuantity(carrito.filter(line => line.productid === product.id).length > 0 ? carrito.filter(line => line.productid === product.id)[0]?.cantidad : null)
  }, [carrito, inputLineQuantity])

  useEffect(() => {
    if(quantity) {
      setMaxUnitPerOrder(maxUnits > 0 ? quantity >= maxUnits : false)
      setSoldOut && setSoldOut(maxUnits > 0 ? quantity >= maxUnits : false)
    }
  }, [quantity])

  useEffect(() => {
    if(productReachedMax) {
      const timeoutId = setTimeout(() => {
        setProductReachedMax(false)
      }, 5000)
      return () => clearTimeout(timeoutId)
    }
  }, [productReachedMax])

  useEffect(() => {
    if(addAdvice) {
      const timeoutId = setTimeout(() => {
        setAddAdvice(false)
      }, 5000)
      return () => clearTimeout(timeoutId)
    }
  }, [addAdvice]);

  const handleSubstract = (e) => {
    e.stopPropagation();
    trackRemoveFromCart(currency, category, product, 1, carouselType);
    let line = cartLine(product, category, 1, 'substract')
    setButtonReduced(false)
    setResetTimeOut(true)
    nuevoCarrito(line);
  }
  const addQuantity = (q) => {
      const quantityToAdd = q >= 0 ? q - quantity : lineQuantity
      const finalQuantity = maxUnits > 0 && q >= maxUnits ? maxUnits - quantity : quantityToAdd
      trackAddToCart(currency, category, product, finalQuantity, algolia, carouselType);
      let line = cartLine(product, category, finalQuantity, 'add')
      setButtonReduced(false)
      setResetTimeOut(true)
      if(maxUnits === 0 || maxUnits === null || quantity + finalQuantity <= maxUnits) {
        nuevoCarrito(line);
        carouselType !== 'lastrecommendedproducts' && setAddAdvice(true)
      }
      if(maxUnits > 0 && quantity + finalQuantity >= maxUnits) {
        carouselType !== 'lastrecommendedproducts' && setProductReachedMax(true)
      }
  }
  const handleAdd = (e) => {
    e.stopPropagation();
    if(inputQuantity && quantity === 0) {
      setModalInputQuantity(true)
    } else {
      addQuantity(quantity + 1)
    }
  }

  const handleInput = (e) => {
    e.stopPropagation();
    setModalInputQuantity(true)
  }

  if(product && category && buttonReduced && quantity === 0) return (
    <>
      <ContainerButton newGrid={newGrid} onClick={handleAdd}>
        <Button maxWidth={maxWidth}>
        <TextButton>+</TextButton>
        </Button>
      </ContainerButton>
      {
        productReachedMax &&
        <SnackBar
          title={intl.formatMessage(messages.maximum)}
          bottom={'120px'}
          setShowPopUp={setProductReachedMax}
        />
      }
      {
        modalInputQuantity &&
        <ModalInputQuantity quantity={lineQuantity} setQuantity={setLineQuantity} action={addQuantity} setModalInputQuantity={setModalInputQuantity}/>
      }
    </>
  )

  if(product && category && quantity > 0 && !buttonReduced) return(
    <>
  {cart ? ( <ContainerButtonCart newGrid={newGrid} onClick={handleAdd}>
    <ButtonAdd newGrid={newGrid}>
      <Image src='/imgs/sum.svg' alt='sum' width={24} height={24}/>
    </ButtonAdd>
    </ContainerButtonCart>):(
        <ContainerButton padding={padding} newGrid={newGrid}>
        <Button maxWidth={maxWidth} height={height}>
          {
            quantity > 1 ?
            <Minus size={24} onClick={handleSubstract}/>:
            <Trash size={24} onClick={(e) => {
              handleBorrar(product.id, 1, finalPrice(product), e)
              let newArr = [...deleteProducts];
              newArr.push(product.id);
              setDeleteProducts(newArr);
              trackRemoveFromCart(currency, category?.name || undefined, product, 1, carouselType);
            }} />
          }
          <TextButton quantity onClick={handleInput}>{quantity}</TextButton>
          {
            !maxUnitPerOrder ?
            <Plus size={24} onClick={handleAdd}/> :
            <TextButton>máx</TextButton>
          }
        </Button>
    </ContainerButton>
  )}
    {
      productReachedMax &&
      <SnackBar
        title={intl.formatMessage(messages.maximum)}
        bottom={'120px'}
        setShowPopUp={setProductReachedMax}
      />
    }
    {
        addAdvice && 
        <SnackBar
            title={intl.formatMessage(messages.productAdded)}
            text={intl.formatMessage(messages.seeCart)}
            link={true}
            top={true}
            setShowPopUp={setAddAdvice}
            polygon={true}
        />
    }
    {
        modalInputQuantity &&
        <ModalInputQuantity quantity={lineQuantity} setQuantity={setLineQuantity} action={addQuantity} setModalInputQuantity={setModalInputQuantity}/>
      }
  </>

  )

  if(product && category && quantity === 0 && !buttonReduced) return (
    <>
{
  cart ? (
    <ContainerButtonCart onClick={handleAdd}>
    <ButtonAdd>
      <Image src='/imgs/sum.svg' alt='sum' width={24} height={24}/>
    </ButtonAdd>
 </ContainerButtonCart>
  ) : (
    <ContainerButton newGrid={newGrid} padding={padding}>
    <Button active maxWidth={maxWidth} height={height} padding={padding} center onClick={handleAdd}>
       <TextButton>           
         <FormattedMessage
        id='labelAddProductToCart'
        defaultMessage='Add to cart'
        />
       </TextButton> 
    </Button>
</ContainerButton>
  )
}
  {
      productReachedMax &&
      <SnackBar
        title={intl.formatMessage(messages.maximum)}
        bottom={'120px'}
        setShowPopUp={setProductReachedMax}
      />
    }
    {
        addAdvice && 
        <SnackBar
            title={intl.formatMessage(messages.productAdded)}
            text={intl.formatMessage(messages.seeCart)}
            link={true}
            top={true}
            setShowPopUp={setAddAdvice}
            polygon={true}
        />
    }
    {
        modalInputQuantity &&
        <ModalInputQuantity quantity={lineQuantity} setQuantity={setLineQuantity} action={addQuantity} setModalInputQuantity={setModalInputQuantity}/>
      }
    </>
  )
  return <></>
}

export default AddToCartControl;
