import React, { useEffect, useState } from 'react';
import { ContainerTextEnds, TextEnds, BoxNumber, TextBox, ContainerCountDown } from './styled';
import { FormattedMessage } from 'react-intl';

function generateCountdown(initialTimeInSeconds) {
  if (initialTimeInSeconds <= 0) {
    return {
      hours: '00',
      minutes: '00',
      seconds: '00',
    };
  }

  const hours = Math.floor(initialTimeInSeconds / 3600);
  const minutes = Math.floor((initialTimeInSeconds % 3600) / 60);
  const seconds = initialTimeInSeconds % 60;

  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

  return {
    hours: formattedHours,
    minutes: formattedMinutes,
    seconds: formattedSeconds,
  };
}

function CountDown({ initialTimeInSeconds, productsView, product, cart, setShowSnackPopUp, refetch, verificationPrices}) {
  const [remainingTime, setRemainingTime] = useState(generateCountdown(initialTimeInSeconds));
  useEffect(() => {
    const interval = setInterval(() => {
      if (initialTimeInSeconds > 0) {
        initialTimeInSeconds -= 1;
        const updatedTime = generateCountdown(initialTimeInSeconds);
        setRemainingTime(updatedTime);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [initialTimeInSeconds]);

 useEffect(() => {
   if (parseInt(remainingTime.hours) === 0 && parseInt(remainingTime.minutes) === 0 && parseInt(remainingTime.seconds) === 0 && refetch) {
     refetch()
   }
 }
 , [remainingTime]);

  const timeIsLessThanOneHour = parseInt(remainingTime.hours) < 1;
  if (parseInt(remainingTime.hours) === 0 && parseInt(remainingTime.minutes) === 0 && parseInt(remainingTime.seconds) === 0) {
    cart && setShowSnackPopUp(true);
    return null;
  }
  return (
    <>
      <ContainerTextEnds productsView={productsView} product={product} cart={cart} verificationPrices={verificationPrices}>
        <TextEnds productsView={productsView} product={product} cart={cart}> 
          <FormattedMessage
          id='endsInId'
          defaultMessage='Ends in'
          />
        </TextEnds>
      </ContainerTextEnds>
      <ContainerCountDown productsView={productsView} product={product} cart={cart} verificationPrices={verificationPrices}>
        <BoxNumber productsView={productsView} product={product} cart={cart}>
          <TextBox productsView={productsView} time={timeIsLessThanOneHour} product={product} cart={cart}>
            {remainingTime.hours}
          </TextBox>
        </BoxNumber>
        <BoxNumber productsView={productsView} product={product} cart={cart}>
          <TextBox productsView={productsView} time={timeIsLessThanOneHour} product={product} cart={cart}>
            {remainingTime.minutes}
          </TextBox>
        </BoxNumber>
        <BoxNumber productsView={productsView} product={product} cart={cart}>
          <TextBox productsView={productsView} time={timeIsLessThanOneHour} product={product}>
            {remainingTime.seconds}
          </TextBox>
        </BoxNumber>
      </ContainerCountDown>
    </>
  );
}

export default CountDown;
