import styled from 'styled-components';

export const Container = styled.div`
width: 100%;
height: ${({type}) => type === 'checkout' ? '70vh' : '100%'};
`

export const Card = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
gap: 8px;
align-items: flex-start;
align-self: stretch;
border-radius: 8px;
background: #FFF;
box-shadow: 0px 1px 14px 0px rgba(52, 74, 62, 0.12);
`;

export const TitleCard = styled.p`
color: ${props => props.theme.textDefault};
font-size: 14px;
font-family: sans-serif;
font-style: normal;
font-weight: 600;
line-height: 24px;
`;

export const SubtitleCard = styled.div`
color: ${props => props.theme.textDefault};
font-size: 14px;
font-family: sans-serif;
font-style: normal;
font-weight: 400;
display: flex;
align-items: center;
gap: 3px;
`;

export const ContainerText = styled.div`
display: flex;
width: 80%;
flex-direction: column;
align-items: flex-start;
`;

export const SelectButton = styled.button`
display: flex;
height: 40px;
padding: 12px 24px;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border: none;
border-radius: 24px;
background:${({selected}) => selected ? props => props.theme.secondaryDark : props => props.theme.primary};
`
export const ButtonText = styled.p`
color: var(--White-White, #FFF);
text-align: center;
font-feature-settings: 'liga' off, 'clig' off;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 800;
line-height: 16px;
`

export const FlexRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
gap: 10px;
width: 100%;
`

export const ContainerDeliveryDate = styled.p`
display: flex;
padding: 2px 8px;
align-items: center;
border-radius: 16px;
background: #E7EEFB;
`;

export const ContainerFreeHomeDelivery = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 4px;
padding: 2px 8px;
border-radius: 16px;
background-color: #E3FF88;
margin-top: 5px;
color: var(--Fill-Primary-action-hover, #006B3A);
font-feature-settings: 'liga' off, 'clig' off;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
`;

export const DeliveryDate = styled.p`
font-size: 12px;
font-family: sans-serif;
font-style: normal;
font-weight: 400;
line-height: 16px;
color: #3560D5;
margin-top: 3px;
`;

export const Distance = styled.p`
color: ${props => props.theme.textTertiary};
font-family: sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
`