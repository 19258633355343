import { getLogoByCountryId,getBrandNameByCountryId } from "services/getLogosByCountryId";
import {
  Container,
  ContainerActions,
  ContainerLogos,
  ContainerImgLogo,
  NameOfShop,
  ContainerName,
  ContainerIconAndLogo,
  ContainerMessageAndDrawer,
  ContainerTotalCart,
  TotalItems,
} from "./styled";
import AlertDeliveryDate from "componentes/AlertDeliveryDate";
import AlertChooseDeliveryPoint from "componentes/AlertChooseDeliveryPoint";
import SearchButton from "componentes/SearchButton";
import { capitalizeFirstLetter } from "services/helpers";
import Image from "next/image";
import { useContext, useState, useEffect } from "react";
import { Context } from "context/Context";
import Drawer from "componentes/Drawer";
import { useIntl } from "react-intl";
import AnimatedCart from "componentes/AnimatedCart";
import { showPaymentMethods } from "services/featureflags";
import { trackNavClick } from "./tracking";
import ModalDeliveryPointSelector from "componentes/Checkout/components/ModalDeliveryPointSelector";
import SearchInput from 'componentes/SearchInput';

function DrawerNavigation({
  confirmationPage,
  isSearchButtonMoved,
  isCheckout,
  routerBack,
}) {
  const intl = useIntl();

  const {
    sucursal,
    router,
    cantidad,
    dataShop,
    setOpenDrawer,
    openDrawer,
    isSnackBarShown,
    setIsSnackBarShown,
    deliveryPointSelector,
    pais,
    communityLeader,
    modalDeliveryPointSelector,
    setSelectedDeliveryPoint,
    setModalDeliveryPointSelector,
  } = useContext(Context);

  const showPaymentMethodsCountry = showPaymentMethods(pais);
  const [prevCantidad, setPrevCantidad] = useState(cantidad);
  const [productAdded, setProductAdded] = useState(false);
  const [productRemove, setProductRemove] = useState(false);
  const [isActiveSearch, setIsActiveSearch] = useState(false);

  useEffect(() => {
    if (cantidad - prevCantidad > 0) {
      setProductAdded(true);
      setProductRemove(false);
    } else if (cantidad - prevCantidad < 0) {
      setProductRemove(true);
      setProductAdded(false);
    }
    if (cantidad !== prevCantidad) {
      setPrevCantidad(cantidad);
    }
  }, [cantidad]);

  useEffect(() => {
    let intervalId;
    if (productAdded || productRemove) {
      intervalId = setInterval(() => {
        setProductAdded(false);
        setProductRemove(false);
      }, 500);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [productAdded, productRemove]);

  useEffect(() => {
    if (cantidad === 1 && !isSnackBarShown && !isCheckout) {
      setIsSnackBarShown(true);
    }
  }, [cantidad, isSnackBarShown, isCheckout]);

  return (
    <>
      <ContainerMessageAndDrawer>
        <AlertDeliveryDate confirmationPage={confirmationPage} />
        {!isCheckout ? (
          <Container>
            <ContainerActions>
              <ContainerIconAndLogo isSearchButtonMoved={isSearchButtonMoved}>
                <div style={{ minWidth: "30px" }}>
                  <Image
                    style={{ cursor: "pointer" }}
                    src="/imgs/menu.svg"
                    alt="menu"
                    width={24}
                    height={30}
                    onClick={() => {
                      trackNavClick();
                      setOpenDrawer(!openDrawer);
                    }}
                  />
                </div>
                <ContainerLogos>
                  {getLogoByCountryId(pais)}
                  {!isSearchButtonMoved && deliveryPointSelector && (
                    <ContainerName
                      deliveryPointSelector={deliveryPointSelector}
                    >
                      {getBrandNameByCountryId(pais)}
                      {!deliveryPointSelector && (
                        <NameOfShop>{`${capitalizeFirstLetter(
                          dataShop.name
                        )}`}</NameOfShop>
                      )}
                    </ContainerName>
                  )}

                  {/* texto de logo comentado, no forma parte del diseño nuevo*/}

                  {/* {!isActiveSearch && (
                    <ContainerName
                      deliveryPointSelector={deliveryPointSelector}
                    >
                      {getBrandNameByCountryId(pais)}
                      {!deliveryPointSelector && (
                        <NameOfShop>{`${capitalizeFirstLetter(
                          dataShop.name
                        )}`}</NameOfShop>
                      )}
                    </ContainerName>
                  )} */}
                </ContainerLogos>
              </ContainerIconAndLogo>
              <div style={{display:'flex', width: isActiveSearch ? '100%' : '45%', marginLeft:isActiveSearch ?'8px':'0px'}}>
              {
                isActiveSearch ? (<SearchInput setIsActiveSearch={setIsActiveSearch}/>):(
                  <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    alignItems: "center",
                    width: '100%',
                    justifyContent: "flex-end",
                    gap: "16px"
                  }}
                >
                    <SearchButton
                      deliveryPointSelector={deliveryPointSelector}
                      routerBack={routerBack}
                      isSearchButtonMoved={isSearchButtonMoved}
                      noMargin
                      setIsActiveSearch={setIsActiveSearch}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:'center'
                      }}
                      onClick={() =>
                        window.open(
                          `https://wa.me/${communityLeader?.phone}`,
                          "_blank"
                        )
                      }
                    >
                      <Image
                        src="/imgs/wsIcon.svg"
                        alt="wsIcon"
                        width={24}
                        height={24}
                      />
                    </div>
                    <Image
                      src="/imgs/userDrawer.svg"
                      alt="user"
                      width={24}
                      height={24}
                      onClick={() => router.push(`/${sucursal}/user?login=true`)}
                    />
                   </div>
                )
              }
                <ContainerTotalCart
                  isSearchButtonMoved={isSearchButtonMoved}
                  onClick={() => router.push(`/${sucursal}/mi-carrito`)}
                >
                  <AnimatedCart
                    productAdded={productAdded}
                    productRemove={productRemove}
                  />
                  {cantidad > 0 && (
                    <TotalItems length={cantidad.toString().length}>
                      {cantidad.toString().length <= 2 ? (
                        cantidad
                      ) : (
                        <p>
                          99<span style={{ fontSize: "10px" }}>+</span>
                        </p>
                      )}
                    </TotalItems>
                  )}
                </ContainerTotalCart>
                </div>
            </ContainerActions>
            {deliveryPointSelector && (
              <AlertChooseDeliveryPoint confirmationPage={confirmationPage} />
            )}
          </Container>
        ) : (
          <Container>
            <ContainerIconAndLogo>
              <ContainerLogos
                isCheckout
                onClick={() => router.push(`/${sucursal}`)}
              >
                {getLogoByCountryId(pais)}
                <ContainerName deliveryPointSelector={deliveryPointSelector}>
                  {getBrandNameByCountryId(pais)}
                  {!deliveryPointSelector && (
                    <NameOfShop>{`${capitalizeFirstLetter(
                      dataShop.name
                    )}`}</NameOfShop>
                  )}
                </ContainerName>
              </ContainerLogos>
            </ContainerIconAndLogo>
          </Container>
        )}
        {modalDeliveryPointSelector && (
          <ModalDeliveryPointSelector
            setModalDeliveryPointSelector={setModalDeliveryPointSelector}
            setSelectedDeliveryPoint={setSelectedDeliveryPoint}
            country={dataShop.country_id}
          />
        )}
      </ContainerMessageAndDrawer>
      {openDrawer && <Drawer />}
    </>
  );
}

export default DrawerNavigation;
