import { track } from 'services/gtm';

export function minimumOrderValueBar(is_complete_bar, minimum_amount_to_purchase, total, country_id) {
    track('minimum_order_value_bar', {
      ecommerce: {
        is_complete_bar,
        minimum_amount_to_purchase,
        amount_cart: total,
        country_id,
      },
    });
}

export function modalInfoMinimunOrderValue(country_id, view) {
  track('modal_info_minimun_order_value', {
    ecommerce: {
      country_id,
      view,
    },
  });
}