import styled, { css }  from 'styled-components'
import Image from 'next/image';

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
padding: 0px 16px;
height: 56px;
width: 100%;
margin: 8px 0px 24px 0px;
`
export const TextContainer = styled.div`
border-radius: 0 8px 8px 0;
background:${props => props.theme.informationLight};
height: 56px;
width: 90%;
z-index: 0;
display: flex;
align-items: center;
justify-content: center;
padding: 0px 16px 0px 8px;

`
export const Text = styled.div`
color: ${props => props.theme.informationDark};
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 16px;
`

export const StampImage = styled.div`
top: 0;
left: 0;
z-index: 1;
padding: 0;
height: 56px;
background-image: linear-gradient(to right, #FAFBFA 60%, ${props => props.theme.informationLight} 60%);
`
