import styled from  'styled-components'

export const ContainerGrid = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 15px;
gap: 15px;
align-items: stretch;
width: 100%;
margin: 0 auto;
padding: 0px 18px 30px 18px;
`
