import { useRouter } from 'next/router';
import { ContainerNavbar, ContainerTitle, Title, ContainerImage, Container } from './styled';
import Image from 'next/image';
import AlertDeliveryDate from 'componentes/AlertDeliveryDate';

function Navbar({ title, redirection, cart, preview, action, isMenuView }) {
  const router = useRouter();
  
  return (
    <Container > 
    <AlertDeliveryDate />   
    <ContainerNavbar isMenuView={isMenuView}>
        <ContainerImage>
          <Image
            src={cart || preview ? '/imgs/Icon.svg' : '/imgs/arrowBack.svg'}
            alt='arrow'
            width={cart ? 14 : 24}
            height={cart ? 14 : 24}
            onClick={action || (() => router.push(redirection))}
          />
        </ContainerImage>
        <ContainerTitle>
          {
            cart &&
            <Image
            src={'/imgs/vectorcart.svg'}
            alt='arrow'
            width={21}
            height={21}
          />
          }
          <Title largeTitle={title?.length > 20}>{title}</Title>
        </ContainerTitle>
    </ContainerNavbar>
    </Container>
  );
}

export default Navbar;