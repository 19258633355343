import { act, useContext, useEffect, useState } from 'react';
import { Context } from 'context/Context';
import { ContainerMessage, TextMessage, Title, Chip, ChipText } from './styled'
import { FormattedMessage } from 'react-intl';
import { distanceShopsToUser, haversineDistance } from 'services/helpers';
import { trackAddressAutocompleted } from './tracking';
import { getLocalStorage, removeItem } from 'services/localstorage';
import { MapPin, RefreshCw} from 'lucide-react';
import getdatostienda from 'services/getdatostienda';


function AlertChooseDeliveryPoint({ confirmationPage, isSearchButtonMoved }) {
  let { deliveryPointSelector, selectedDeliveryPoint, setSelectedDeliveryPoint, isCatalogSG, setModalDeliveryPointSelector, nearestShops, pais, actualLocation } = useContext(Context);
  const [distance, setDistance] = useState(null);
  const [suggestedAddress, setSuggestedAddress] = useState(null)

  useEffect(() => {
    if (nearestShops?.length > 0 && !selectedDeliveryPoint) {
      const localDeliveryPoint = getLocalStorage('selectedDeliveryPoint')
      if(localDeliveryPoint){
        const pointSllug = localDeliveryPoint?.slug;
        getdatostienda(pointSllug).then((data) => {
          const { status } = data.data;
          if(status === 'active'){
            setSelectedDeliveryPoint(localDeliveryPoint)
            setDistance(distanceShopsToUser(localDeliveryPoint.DistanceToUser, pais));
            setSuggestedAddress({
              street: localDeliveryPoint?.address?.street,
              number: localDeliveryPoint?.address?.number
            });
            return
          } else {
            removeItem('selectedDeliveryPoint')
            const sortedData = nearestShops.sort((a, b) => a.distance_to_user - b.distance_to_user);
            setDistance(distanceShopsToUser(sortedData[0].DistanceToUser, pais));
            setSuggestedAddress({
              street: sortedData[0]?.address?.street,
              number: sortedData[0]?.address?.number
            });
            setSelectedDeliveryPoint(sortedData[0]);
          }
        })
      } else {
        const sortedData = nearestShops.sort((a, b) => a.distance_to_user - b.distance_to_user);
        setDistance(distanceShopsToUser(sortedData[0].DistanceToUser, pais));
        setSuggestedAddress({
          street: sortedData[0]?.address?.street,
          number: sortedData[0]?.address?.number
        });
        setSelectedDeliveryPoint(sortedData[0]);
      }
        trackAddressAutocompleted()
    } else if (nearestShops?.length > 0 && selectedDeliveryPoint) {
      const location = selectedDeliveryPoint?.ShopLocation ?? selectedDeliveryPoint?.shop_location;
      const latitude = location?.Latitude ?? actualLocation?.lat ?? selectedDeliveryPoint?.ShopLocation?.Latitude;
      const longitude = location?.Longitude ?? actualLocation?.lng ?? selectedDeliveryPoint?.ShopLocation?.Longitude;
      
      setDistance(
        distanceShopsToUser(haversineDistance(location, { lat: latitude, lng: longitude }), pais)
      );
    }
  }, [nearestShops, selectedDeliveryPoint]);
  

  let address = '';

  if (selectedDeliveryPoint) {
    const { street, number } = selectedDeliveryPoint.address || {};
    address = street || '';
    if (number) {
      address += ` ${number}`;
    }
  }

  const showDistance = () => {
    const splitData = distance?.split(' ');
    splitData && splitData[0] > 0 ? true : false;
  };

  if(deliveryPointSelector) return (
    !isCatalogSG && (
     <> 
     {selectedDeliveryPoint ? (
     <>
      <ContainerMessage isSearchButtonMoved={isSearchButtonMoved} onClick={()=> !confirmationPage && setModalDeliveryPointSelector(true)}>
        <MapPin size={28}/>
        <div style={{display:'flex', flexDirection:'column', marginLeft:'12px', width: '100%'}}>
            <Title select>
                <FormattedMessage
                   id='labelpickupmakert'
                   defaultMessage="Pick up your market at:"
                />
            </Title>
            <div style={{position:'relative', display:'flex', justifyContent:'flex-start', width:'100%', postion: 'relative'}}>
            <TextMessage truncate={address.split('').length > 10} select>
                    {' '}
                    {address}
                    {
                      showDistance(distance) && (
                        <>
                          {' '}
                          (a
                          {' '}
                          {distance})
                        </>
                      )
                    }
            </TextMessage>
            <Chip>
              <RefreshCw size={12}/>
              <ChipText>
                Cambiar
              </ChipText>
            </Chip>
            </div>
          </div>
        </ContainerMessage>
        </>
        ):(
        <ContainerMessage isSearchButtonMoved={isSearchButtonMoved} onClick={()=> !confirmationPage && setModalDeliveryPointSelector(true)}>
            <MapPin size={24}/>
            <div style={{display:'flex', flexDirection:'column', marginLeft:'12px'}}>
            <Title>
              {distance ? (
                'Punto de entrega sugerido:'
              ):(
                 <FormattedMessage
                  id='labelpickupmakert'
                  defaultMessage="Pick up your market at:"
                  />
              )}
             </Title>
            <TextMessage>
              {distance && suggestedAddress ? `${suggestedAddress.street ?? ''} ${suggestedAddress.number ?? ''} a ${distance}` : 'Selecciona punto de entrega'}
            </TextMessage>
            </div>
        </ContainerMessage>
     )}
      </>
    )
  );
  return <></>
}

export default AlertChooseDeliveryPoint;
