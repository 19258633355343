import styled from  'styled-components'

export const ContainerView = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top: ${props => props.sg ? '100px' : '24px'};
`

export const TitleEmpty = styled.p`
color: #011D0E;
font-size: 28px;
font-weight: 600;
text-align: center;
line-height: 34px;
`

export const Subtitle = styled.p`
color: #011D0E;
font-size: 16px;
font-weight: 400;
text-align: center;
line-height: 24px;
margin-top: 16px;
margin-bottom: 50px;
max-width: 70%;
`
export const Padding = styled.div`
padding: 0px 16px;
`