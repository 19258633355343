import styled, { css }  from 'styled-components'

export const ContainerProducts = styled.div`
height: 100%;
display: flex;
align-items: flex-start;
padding-bottom: 10px;
background-color: #FAFBFA;
padding-top: ${props => props.search ? '12px' : '0px'};
padding-top: ${props => props.lastest && '80px'};
padding-top: ${props => props.unmissable && '0px'};
${props => props.flashDeals && css`
    background: ${props.theme.warning};
    padding-bottom: 0px;
    padding-top: 0px;
`};
`;

export const ContainerSaving = styled.div`
display: flex;
padding: 0px;
align-items: center;
color: #FF3025;
text-align: center;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 800;
line-height: 16px;
gap: 2px;
`;


export const ContainerContent = styled.div`
min-height: 100vh;
background: #FAFBFA;
height: 100%;
position: relative;
overflow: scroll;
overflow-y: auto;
padding-bottom: ${props => props.block && '72px'};
${props => props.search && css`
display: flex;
flex-direction: column;
justify-content: space-between;
`}
${props => props.unmissable && css`
padding-top: 110px;
background: ${props => props.theme.secondary};
`};
${props => props.flashDeals && css`
padding-top: 110px;
background: ${props.theme.warning};
`};
`

export const CardsContainer = styled.div`
display: ${props => props.grid ? 'grid': 'flex'};
grid-template-columns: 1fr 1fr;
gap: 15px;
align-items: stretch;
justify-content: ${props => !props.grid && 'center'};
width:  ${props => props.grid ? '100%': '100%'};
margin: 0 auto;
background-color: #FAFBFA;
padding: ${props => props.isCatalogSG ? '80px 16px 30px 16px' : '10px 16px 30px 16px' };
${props => props.flashDeals && css`
background-color: ${props.theme.warning};
`};
`;

export const Card = styled.div`
min-height: 100%;
display: flex;
width: 100%;
flex-direction: column;
border-radius: 0.75rem;
background: ${props => props.theme.white};
border: 2px solid #F6F6F7;
box-shadow: 0px 4px 10px rgba(48, 54, 64, 0.02);
border-radius: 12px;
justify-content: space-between;
position: relative;
${props => props.flashDeals && css`
background: ${props.theme.warning};
`};
`;


export const InfoCard = styled.div`
&:active {
    background-color: #FAFBFA;
    opacity: 0.3;
}
`;


export const ContainerInfo = styled.div`
display: flex;
justify-content: center;
align-self: center;
margin-top: 7px;
`;

export const StrongUOM = styled.strong`
color: #7e8b94;
font-size: 13px;
`;

export const PercentajeDiscount = styled.p`
position: absolute;
font-family: sans-serif;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 16px;
text-align: center;
color: #011D0E;
top: 19px;
right: ${props => props.percentage === 1 ? '17.3px' : ' 12.5px'};
z-index: 2;
align-self: center;
`;

export const ImageDiscount = styled.div`
position: absolute;
z-index: 1;
width: 44px;
height: 44px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
right: 6px;
top: 6px;
align-items: center;
`;

export const Signe = styled.span`
font-size: 12px;
font-weight: 500;
`;

export const InfoProduct = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
padding: 0px 8px;
`;

export const ProductName = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
color: #011D0E;
margin-top: 5px;
max-height: 54px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box; 
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
`;

export const ContainerPriceAndDiscount = styled.div`
display: flex;
align-items: flex-end;
${props => props.flashDeals && css`
margin-top: 6px;
`};
`;

export const PriceDiscount = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 12px;
text-decoration-line: line-through;
color: #011D0E;
margin-left: 5px;
`;

export const Price = styled.p`
font-size: 19px;
font-weight: 900;
font-family: sans-serif;
color: #011D0E;
align-self: flex-start;
line-height: 16px;
`;

export const ContainerPriceSavings = styled.div`
display: flex;
width: 100%;
margin-top: 5px;
color: #FF3025;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
gap: 2px;
`;

export const TextSavings = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: ${props => props.isDefaultTier ? '800' : '500'};
font-size: ${props => props.isDefaultTier ? '14px' : '14px'};
line-height: 16px;
color: ${props => props.isDefaultTier ? '#FFF' : '#011D0E'};
background: ${props => props.isDefaultTier ? props.theme.primary : props.theme.warning};
padding: ${props => props.isDefaultTier ? '0px 3px' : '0px 0.5px'};
`;

export const UOMS = styled.p`
color: #646970;
font-family: Roboto;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 16px;
`
export const CategoryTitle = styled.h1`
font-family: sans-serif;
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
color: #011D0E;
background: #FAFBFA;
// change color and background color if unmissable
${props => props.unmissable && css`
    color: ${props.theme.white};
    background: none;
    font-size: 20px;
    font-weight: 600;
    background-i
`}
`

export const SubText = styled.p`
color: #FFF;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 133.333% */
text-wrap: balance;
margin-top: 5px;
`

export const CategoryDescription = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #011D0E;
background: #FAFBFA;
`

export const Filter = styled.div`
display: inline-flex;
padding: 8px;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid #E5E7E6;
&:active {
    opacity: 0.6
};
`

export const FilterText = styled.p`
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px;
`

export const ContainerFilterAndCategory = styled.header`
width: 100%;
display: flex;
justify-content: space-between;
background-color: #FAFBFA;
align-items: ${props => props.description ? 'flex-end' : 'center'};
padding: 12px 16px 0px 16px;
min-height: 38px;
height: ${props => props.unmissable ? '120px' : 'auto'};
background-color: ${props => props.unmissable ? props.theme.secondary : '#FAFBFA'};
${props => props.unmissable && css`
    background-image: url('/imgs/bg_pattern_fire.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 150px;
`};
`

export const FilterQuantity = styled.div`
width: 20px;
height: 20px;
display: flex;
justify-content: center;
align-items: center;
background-color: ${props => props.theme.secondary};
border-radius: 999px;
color: white;
text-align: center;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 800;
line-height: 12px; /* 100% */
letter-spacing: 0.12px;
padding: 2px;
`

export const QuantityOfProducts = styled.span`
color: ${props => props.theme.textTertiary};
font-family: sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 600;
margin-left: 4px;
line-height: 20px;
`

export const TitleNoResults = styled.p`
color: #011D0E;
text-align: center;
font-family: sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 24px;
margin-top: 50px;
`

export const SubtitleNoResults = styled.p`
color: #011D0E;
text-align: center;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
margin-top: 16px;
`

export const ContainerButton = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
`

export const Button = styled.div`
padding: 16px 32px;
border: 1px solid #00904B;
border-radius: 8px;
display: flex;
justify-content: center;
align-items: center;
height: 56px;
margin-top: 50px;
&:active{
    opacity: 0.5;
}
`

export const TextButton = styled.p`
color: #00904B;
font-family: sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 20px;
`

export const ImageContainer = styled.div`
position: absolute;
right: 0px;
z-index: 1;
`

export const SpanMoreInfo = styled.span`
text-decoration-line: underline;
cursor: pointer;
margin-left: 5px;
`

export const MinimumWarning = styled.div`
border-radius: 2px;
background: #F7F8F7;
position: absolute;
top: ${props => props.flashDeals ? '4px': '0px'};
left: ${props => props.flashDeals ? '4px': '0px'};
width: auto;
z-index: 1;
padding: 0px 2px;
`

export const MinimumText = styled.p`
color: #344A3E;
font-family: sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; 
`