
export const getRecommendations = async (customerId, catalogId, type, endpoint, extraParams) => {
    const url = `/api/retailrocket?endpoint=${endpoint}&customerId=${customerId}&catalogId=${catalogId}&type=${type}&extraParams=${encodeURIComponent(extraParams)}`

    try {
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
        const data = await response.json();

        return data.error ? [] : data.data;
    } catch (error) {
        return { error };
    }
};