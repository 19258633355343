import styled, { keyframes } from 'styled-components';

export const Input = styled.input`
  width: 100%;
  color:${props => props.theme.secondary};
  border-radius: 24px;
  height: 40px;
  border: none;
  padding: 0 16px 0 40px; 
  font-size: 16px;
  color: ${props => props.theme.secondary};
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  &::placeholder {
    color:${props => props.theme.secondary};
    font-size: 16px;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${props => props.theme.secondary};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.secondary};
  border-radius: 24px;
  width: 100%;
  position: relative;
  img {
    color: white;
    margin-right: 8px;
  }
  button {
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 20px;
    margin-left: 8px;
  }
`;

export const FloatIcon = styled.div`
  color:${props => props.theme.secondary};
  display: flex;
  align-items: center;
  position: absolute;
  ${props => props.start ? 'left: 8px' : 'right: 8px'};
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  overflow-y: auto;
  position: fixed;
  background: white;
  top: ${(props) => (props.deliveryPointSelector ? '124px' : '72px')};
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 490px;
  z-index: 8;
  min-height: 100vh;
  max-height: calc(100vh - ${(props) => (props.deliveryPointSelector ? '124px' : '72px')});
  overflow-y: auto;
  margin: 0 auto;
  padding-bottom: 120px;
  animation: fadeInSmooth 0.3s ease-out;

  @keyframes fadeInSmooth {
    from {
      opacity: 0;
      transform: translateX(-50%) scale(0.95);
    }
    to {
      opacity: 1;
      transform: translateX(-50%) scale(1);
    }
  }
`;


export const ContainerLoader = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 100vh;
`