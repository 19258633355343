import { track } from 'services/gtm';
import { totalDiscountNumber } from 'services/producthelpers';

export function trackAddToCart(currency, categoryName, product, quantity, algolia, carouselType) {
  track('add_to_cart', {
    ecommerce: {
      currency: currency,
      value: product.price,
      items: [
        {
          item_name: product.name,
          item_id: product.id,
          price: product.price,
          discount: totalDiscountNumber(product),
          currency: currency,
          quantity: quantity,
          item_category: categoryName,
          item_category2: product.category_id,
        },
      ],
    },
    queryID: algolia?.queryID,
    eventType: 'conversion',
    index: algolia?.index,
    position: product.position,
    objectID: product.id,
    carouselType: carouselType || null,
  });
}
export function trackRemoveFromCart(currency, categoryName, product, quantity, carouselType) {
  track('remove_from_cart', {
    ecommerce: {
      currency: currency,
      value: product.price,
      items: [
        {
          item_name: product.name,
          item_id: product.id,
          price: product.price,
          discount: totalDiscountNumber(product),
          currency: currency,
          quantity: quantity,
          item_category: categoryName,
          item_category2: product.category_id,
        },
      ],
    },
    carouselType: carouselType || null,
  });
}
