import { ContainerView, TitleEmpty, Subtitle, Padding } from './styled'
import GridCards from 'componentes/GridCards';
import { useContext } from 'react';
import { Context } from 'context/Context';
import { trackSearchNotFound } from './tracking';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

function SearchEmpty({ value, sg }) {
    const { categories, } = useContext(Context);

    useEffect(() => {
      trackSearchNotFound(value);
    },[]);

  return (
    <>
      <ContainerView sg={sg}>
        <TitleEmpty>
        <FormattedMessage
        id='textCouldNotBe'
        defaultMessage="Could not"
      />
      <br />
        <FormattedMessage
        id='textBeFound'
        defaultMessage="be found"
      />
      {' '}
      {`"${value}"`}  
        </TitleEmpty>

        <Subtitle>
            <FormattedMessage
            id='subtitleEmptySearch'
            defaultMessage="Try to search for another word or choose one of the categories"
          />
        </Subtitle>
        <Padding>
           <GridCards items={categories || []} search={true} />
        </Padding>
      </ContainerView>
    </>
 )
}

export default SearchEmpty;