import { Context } from 'context/Context';
import { Container, SnackBarStyled, Title, Subtitle, ContainerFlex, ContainerArrow, LinkText } from './styled'
import CloseIcon from '@mui/icons-material/Close';
import Image from 'next/image';
import Link from 'next/link';
import { useContext } from 'react';

function SnackBar({ setShowPopUp, title, text, bottom, top, polygon, link }) {
  let { sucursal } = useContext(Context);
  return (
    <Container bottom={bottom} top={top}>
      <ContainerArrow>
        {polygon && (
          <Image src='/imgs/polygon.svg' alt='arrow' width={46} height={34} />
        )}
      </ContainerArrow>
        <SnackBarStyled top={top}>
          <ContainerFlex> 
            <Title>
              {title}
              {
                !link ?
                <Subtitle>{text}</Subtitle> :
                <Link href={`/${sucursal}/mi-carrito`}>
                  <LinkText>{text}</LinkText>
                </Link>
              }
            </Title>
            <CloseIcon style={{ color: '#fff' }} onClick={()=> setShowPopUp(false)} />
          </ContainerFlex>
        </SnackBarStyled>
    </Container>
  );
}

export default SnackBar;