import styled, { keyframes, css } from "styled-components";

export const ContainerMessageAndDrawer = styled.div`
position: fixed;
width: 100%;
z-index: 3;
max-width: 490px;
margin: 0 auto;
`

export const Container = styled.div`
background-color: ${props => props.theme.secondary};
width: 100%;
z-index: 3;
padding: 16px;
`

export const ContainerActions = styled.div`
width: 100%;
display: flex; 
align-items: center;
position: relative;
justify-content: space-between;
`

export const ContainerIconAndLogo = styled.div`
display: flex; 
align-items: center;
width: 100%;
`

export const ContainerLogos = styled.div`
display: flex;
align-items: center;
margin-left: ${props => props.isCheckout ? '0px': '16px'}
`

export const ContainerImgLogo = styled.figure`
display: flex;
justify-content: center;
align-items: center;
&:active {
    opacity: 0.8;
}
width: 40px;
min-width: 40px;
`

export const NameOfShop = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 16px;
color: white;
line-height: 10px;
`

export const ContainerName = styled.div`
margin-left: 5px;
position: ${props => (props.isSearchButtonMoved ? 'relative' : 'static')};
top: ${props => (props.isSearchButtonMoved ? '-10px' : 'auto')};
width: 100%;
`

export const ContainerTotalCart = styled.div`
display: flex;
align-items: center;
width: auto;
justify-content: flex-end;
min-width: 48px;
margin-left: 8px;
cursor: pointer;
&:active {
    opacity: 0.8;
}
`

export const TotalItems = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: 800;
font-size: 16px;
line-height: 16px;
color: ${props => props.theme.white};
margin-left: 5px;
`