const NEXT_PUBLIC_CATEGORIES_IN_GUARANTEES='{"expiration":[9,77,226,323,354,390,432,457,556,581],"freshness":[2,86,219,332,363,383,425,466,549,590],"fair_weight":[3,90,220,336,367,384,426,468,550,592],"cheaper_price":[486,487,488,489,490,491,492,493,566,598]}';

export const guaranteeTypes = {
    expiration: {
        id: 'expiration',
        name: 'Expiration guarantee',
        description: 'Our dairy products are always valid for the future.',
        image: '/imgs/guarantee/expirationGuarantee.svg',
        more_info_text: [
            'Our dairy products are always valid for the future',
            'We do not sell expired products and all dairy products are valid for a minimum of 7 days for fresh products and 15 days to a month for ultra-pasteurized products.',
            'Any product that does not meet these characteristics will be refunded and you will obtain a coupon for financial compensation.',
            'Applies only to perishable products, presenting your Mercadito purchase receipt and the product, with complaints submitted a maximum of 24 hours after delivery to the leader.'
        ],
        image: '/imgs/guarantee/expirationGuarantee.svg',
        category_ids: JSON.parse(NEXT_PUBLIC_CATEGORIES_IN_GUARANTEES).expiration 
    },
    freshness: {
        id: 'freshness',
        name: 'Freshness guarantee',
        description: 'The meats arrive in good condition, refrigerated and vacuum packed.',
        image: '/imgs/guarantee/freshnessGuarantee.svg',
        more_info_text: [
            'The meats arrive in good condition, refrigerated and vacuum packed.',
            'Any product that does not meet these characteristics will be refunded and you will obtain a coupon for financial compensation.',
            'Applies only to perishable products, presenting your Mercadito purchase receipt and the product, with complaints submitted a maximum of 24 hours after delivery to the leader.'
        ],
        image: '/imgs/guarantee/freshnessGuarantee.svg',
        category_ids: JSON.parse(NEXT_PUBLIC_CATEGORIES_IN_GUARANTEES).freshness
    },
    fair_weight: {
        id: 'fair_weight',
        name: 'Fair weight guarantee',
        description: 'The fruits and vegetables delivered always have the guaranteed weight.',
        more_info_text: [
            'The fruits and vegetables delivered always have the guaranteed weight. One kilo is actually equivalent to one kilo.*',
            'Any product that does not meet these characteristics, we give you a coupon for financial compensation.',
            'Applies only to perishable products, presenting your Mercadito purchase receipt and the product, with complaints submitted a maximum of 24 hours after delivery to the leader.',
            '* % deviation +-50gr'

        ],
        image: '/imgs/guarantee/weightGuarantee.svg',
        category_ids: JSON.parse(NEXT_PUBLIC_CATEGORIES_IN_GUARANTEES).fair_weight
    },
    cheaper_price: {
        id: 'cheaper_price',
        name: 'Cheaper price guarantee',
        description: 'The cumulative purchase of products with the lowest price seal guarantees that the total of your basic basket is the lowest on the market.',
        more_info_text: [
            'The accumulated purchase of products from the basic basket category at Mercadito guarantees savings compared to the same purchase at any other supermarket in your city.',
            'We sell products from the basic basket at the lowest price, guaranteeing that the total of your basic basket is the lowest. Any product, with cumulative purchase, that does not meet these characteristics, will be refunded and you will obtain a coupon for financial compensation. Look for the warranty seal!',
            'Aplica solo presentando tu comprobante de compra Mercadito y el producto, con quejas presentadas máximo 24 horas después de la entrega a la líder.'
        ],
        image: '/imgs/guarantee/cheaperPriceGuarantee.svg',
        category_ids: JSON.parse(NEXT_PUBLIC_CATEGORIES_IN_GUARANTEES).cheaper_price
    },
}