import styled, { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
max-height: 100%;
overflow-y: auto;
position: fixed;
background: white;
top: 0px;
width: 100%;
max-width: 490px;
z-index: 10;
animation: ${fadeIn} 0.3s ease;
`

export const ContainerClose = styled.div`
width: 100%;
padding: 20px 0px 20px 16px;
&:active{
  opacity: 0.6;
};
cursor: pointer;
`


export const TermTitleReferred = styled.p`
padding: 0px 78px 0px 24px;
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px;
`

export const HowDoesItWork = styled.p`
padding: 24px 42px 0px 24px;
color: #000;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px
`

export const TextBlock1 = styled.p`
padding: 8px 42px 0px 24px;
color: #000;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
`

export const TextBlock2 = styled.p`
padding: 0px 42px 0px 24px;
font-family: sans-serif;
color: #000;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
white-space: pre-wrap
`

export const Ul = styled.ul`
padding: 20px 42px 0px 24px;
`

export const TermTitle = styled.p`
padding: 24px 42px 0px 24px;
font-family: sans-serif;
color: #000;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px;
`

export const ContainerButton = styled.div`
width: 100%;
padding: 24px 24px 35px 24px;
display: flex;
justify-content: center;
align-items: center;
`

export const Button = styled.div`
display: flex;
height: 48px;
padding: 16px;
justify-content: center;
align-items: center;
border-radius: 24px;
border: 1px solid #00904B;
width: 100%;
&:active{
  opacity: 0.8;
};
cursor: pointer;
`

export const ButtonText = styled.p`
color: #00904B;
text-align: center;
font-family: sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 800;
line-height: 20px;
letter-spacing: 0.1px;
`

export const ListItem = styled.li`
padding: 4px 42px 0px 14px;
position: relative;
color: #000;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
  &::before {
    content: "•";
    position: absolute;
    left: 0;
    top: 0.3rem;
    font-size: 1.2rem;
    color: #000;
  }
`;