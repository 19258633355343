import { track } from "services/gtm";
import { totalDiscountNumber } from "services/producthelpers";

export const trackShareProduct = (product, currency) => {
  track('share_product', {
    ecommerce: {
        items: [
          {
            item_name: product.name,
            item_id: product.id,
            price: product.price,
            discount: totalDiscountNumber(product),
            currency: currency,
            quantity: 1,
            item_category2: product.category_id,
          },
        ],
      },
      objectID: product.id,
  });
}

export const trackViewItem = (currency, categoryName, product, priceComparison, carouselType) => {
  track('view_item', {
    ecommerce: {
      currency: currency,
      value: product?.price,
      price_comparison: priceComparison,
      items: [
        {
          item_id: product?.id,
          item_name: product?.name,
          currency: currency,
          discount: totalDiscountNumber(product),
          item_category: categoryName,
          item_category2: product?.category_id,
          price: product?.price,
          quantity: 1,
        },
      ],
    },
    carouselType: carouselType,
    eventType: 'view',
  });
}

export const trackPriceComparison = (productId, type) => {
  track('price_comparison', {
    action: type,
    product_id: productId,
  });
}

export const trackClickNotes = (productId) => {
  track('click_notes', {
    product_id: productId,
  });
}

export function trackRecommendationBlockViewed(carouselType) {
  track('recommendation_block_viewed', {
    event: 'recommendation_block_viewed',
    carouselType: carouselType
  });
}