import {
  finalPrice,
  hasDiscount,
} from "./producthelpers";

export const applyFilters = async (products, filters) => {
  let filteredProducts = [...products];

  if (filters.discount) {
    filteredProducts = filteredProducts.filter((product) => hasDiscount(product));
  }

  if (filters.range && filters.range.minPrice && filters.range.maxPrice) {
    filteredProducts = filteredProducts.filter((product) => {
      const price = finalPrice(product);
      return price >= filters.range.minPrice && price <= filters.range.maxPrice;
    });
  }

  if (filters?.brands?.length) {
    filteredProducts = filteredProducts.filter((product) => {
      if (product.brand) {
        return filters.brands.includes(product.brand.id);
      }
      return false;
    });
  }

  if (filters.order) {
    switch (filters.order) {
      case "recommended_sort":
        break;
      case "price_sort":
        filteredProducts = filteredProducts.sort((a, b) => finalPrice(a) - finalPrice(b));
        break;
      case "name_sort":
        filteredProducts = filteredProducts.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case "discount_sort":
        filteredProducts = filteredProducts.sort((a, b) => {
          const totalDiscountA = ((a.local_price || a.price) - finalPrice(a)) / finalPrice(a);
          const totalDiscountB = ((b.local_price || b.price) - finalPrice(b)) / finalPrice(b);
          return totalDiscountB - totalDiscountA;
        });
        break;
      case "created_at_sort":
        filteredProducts = filteredProducts.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        break;
      default:
        break;
    }
  }

  return filteredProducts;
};

export const getPriceRange = (products) => {
  let minPrice = Number.MAX_VALUE;
  let maxPrice = Number.MIN_VALUE;
  products.forEach((product) => {
    const price = finalPrice(product);

    if (price <= minPrice) {
      minPrice = price;
    }

    if (price >= maxPrice) {
      maxPrice = price;
    }
  });

  return { minPrice, maxPrice };
};
