import styled, { keyframes } from 'styled-components';
import { textDark, fontPrimary, primaryGreen, fillDark } from '../Styles/Global'

export const ContainerMinimum = styled.div`
  width: 100%;
  max-width: 490px;
  height: ${props => props.block ? '40px' : '72px'};
  background-color:  ${props => !props.block && '#fff'};
  box-shadow: ${props => !props.block && '0px -4px 12px 0px rgba(1, 29, 14, 0.04)'};
  padding: ${props => !props.block && '8px 16px 8px 16px'};
  position: ${props => props.block ? 'relative' : 'fixed'};
  bottom: 0;
  z-index: 8;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${props => !props.block ? '0px' : '4px'};
`;

export const ContainerTitle = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 4px;
`

export const TitleMinimum = styled.p`
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 16px; 
margin-right: 4px;
`

export const ContainerBar = styled.div`
background-image: url('/imgs/confetti.svg');
width: 100%;
max-width: 360px;
height: 35px;
position: relative;
display: flex;
align-items: center;
flex-direction: column;
justify-content: end;
margin-bottom: 8px;
${props =>
  props.showConfetti &&
  `
  @-webkit-keyframes minimumConfetti {
    0% { background-position: left; }
    100% { background-position: right; }
  }

  @keyframes minimumConfetti {
    0% { background-position: left; }
    100% { background-position: right; }
  }

  animation-name: minimumConfetti;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
  animation-timing-function: steps(9);
`}
`

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: center;
background-color:rgb(48, 54, 64, 0.7);
height: 100vh;
width: 100vw;
position: fixed;
top: 0;
z-index: 8;
max-width: 490px;
`
export const DetailBlock = styled.div`
width: 100vw;
background: #FFF;
position: relative;
animation: ${slideIn} 0.5s ease-in-out forwards;
padding: 16px 16px 24px 16px;
max-width: 490px;
height: auto;
`
export const Close = styled.div`
display: flex;
justify-content: flex-start;
padding: 8px 0
`
export const Title = styled.div`
color: #000;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px;
padding: 16px 0

`
export const Text = styled.div`
color: #000;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
padding: 8px 0;
width: ${props => props.paragraph === 0 ? '72%': '100%' }
`
export const StampImage = styled.div`
position: absolute;
top: 35px;
right: 16px;
`

export const Subtitle = styled.p`
color: #303640;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: ${props => props.title ? '600' : '400'};
line-height: 18px;
margin-bottom: ${props => props.title ? '0px' : '16px'};
`

export const ContainerButtons = styled.div`
display: flex;
flex-direction: column;
width: 100%;
margin-top: 24px;
`

export const Button = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 12px 32px;
gap: 10px;
border: 1px solid ${primaryGreen};
border-radius: 24px;
color: ${primaryGreen};
font-family: ${fontPrimary};
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 16px;
width: 100%;
&:active{
    opacity: 0.6
};
`