import Image from "next/image";
import ShopCard from "./components/ShopCard";
import {
  Container,
  SearchArea,
  SearchButton,
  EmptyCard,
  Card,
  TitleType,
  SubtitleCard,
  ContainerText,
  FlexRowJustify,
  SwitchMap,
} from "./styled";
import { useContext, useEffect } from "react";
import { notFoundNearestShop } from "./tracking";
import { Context } from "context/Context";

const ListnearestShops = ({
  country,
  nearestShops,
  selectedDeliveryPoint,
  setSelectedDeliveryPoint,
  setModalDeliveryPointSelector,
  setIsList,
  actualLocation,
  setShopsInBound,
  setCenter,
  setRefreshMap,
  setNewActualLocation,
  newActualLocation,
  center,
}) => {
  const {
   customer,
  } = useContext(Context);

  const nearestShopsHasSelectedShopId = nearestShops?.find(
    (shop) => shop.id === selectedDeliveryPoint?.id
  );

  if (nearestShopsHasSelectedShopId) {
    nearestShops = nearestShops.filter(
      (shop) => shop.id !== selectedDeliveryPoint.id
    );
  }

  useEffect(() => {
    if (nearestShops?.length === 0 && !selectedDeliveryPoint)
      notFoundNearestShop(
        customer?.customer.name,
        customer?.customer.last_name,
        customer?.customer.phone,
        actualLocation?.lat,
        actualLocation?.lng
      );
  }, [nearestShops, selectedDeliveryPoint]);

  return (
    <Container>
      {selectedDeliveryPoint && (
        <>
          <FlexRowJustify>
            <TitleType>
              {" "}
              {country === "ar"
                ? "Tu punto de retiro"
                : "Tu punto de entrega"}{" "}
            </TitleType>
            <SwitchMap
              onClick={() => {
                if (newActualLocation) {
                  setCenter({
                    lat: newActualLocation.lat,
                    lng: newActualLocation.lng,
                  });
                  setIsList(false);
                }
                setIsList(false);
                // setRefreshMap(true);
              }}
            >
              <Image src="/imgs/map.svg" alt="icon" width={16} height={16} />
              Ver Mapa
            </SwitchMap>
          </FlexRowJustify>
          <ShopCard
            shop={selectedDeliveryPoint}
            selected={true}
            setSelectedDeliveryPoint={setSelectedDeliveryPoint}
            setModalDeliveryPointSelector={setModalDeliveryPointSelector}
            actualLocation={actualLocation}
            setIsList={setIsList}
            setShopsInBound={setShopsInBound}
            setCenter={setCenter}
          />
        </>
      )}
      {nearestShops?.length === 0 && selectedDeliveryPoint && (
        <TitleType>No hay puntos de retiro cercanos</TitleType>
      )}
      {nearestShops?.length === 0 && !selectedDeliveryPoint && (
        <FlexRowJustify>
          <TitleType>No hay puntos de retiro cercanos</TitleType>
          <SwitchMap onClick={() => setIsList(false)}>
            <Image src="/imgs/map.svg" alt="icon" width={16} height={16} />
            Ver Mapa
          </SwitchMap>
        </FlexRowJustify>
      )}
      {!nearestShops && !selectedDeliveryPoint && (
        <FlexRowJustify>
          <TitleType>Debes ingresar tu ubicación</TitleType>
          <SwitchMap onClick={() => setIsList(false)}>
            <Image src="/imgs/map.svg" alt="icon" width={16} height={16} />
            Ver Mapa
          </SwitchMap>
        </FlexRowJustify>
      )}
      {nearestShops?.length > 0 && selectedDeliveryPoint && (
        <TitleType>Otros puntos de retiro</TitleType>
      )}
      {nearestShops?.length > 0 && !selectedDeliveryPoint && (
        <FlexRowJustify>
          <TitleType>Puntos de retiro cercanos</TitleType>
          <SwitchMap onClick={() => setIsList(false)}>
            <Image src="/imgs/map.svg" alt="icon" width={16} height={16} />
            Ver Mapa
          </SwitchMap>
        </FlexRowJustify>
      )}
      {nearestShops?.map((shop, index) => (
        <ShopCard
          key={index}
          shop={shop}
          selected={false}
          setSelectedDeliveryPoint={setSelectedDeliveryPoint}
          setModalDeliveryPointSelector={setModalDeliveryPointSelector}
          actualLocation={actualLocation}
          setShopsInBound={setShopsInBound}
          setCenter={setCenter}
          setIsList={setIsList}
        />
      ))}
    </Container>
  );
};

export default ListnearestShops;
