import styled, { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  margin: 0 auto;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 490px;
  z-index: 9;
  overflow: hidden;
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  animation: ${({ isOpen }) => (isOpen ? fadeIn : fadeOut)} 0.3s ease;
  max-width: 490px;
  margin: 0 auto;
  padding-top: 17%;
  flex-direction: column;
`;

export const ModalContent = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  padding: 60px 16px 90px 16px;
  position: relative;
  overflow: scroll;
`;

export const ContainerClose = styled.div`
cursor: pointer;
animation: ${fadeIn} 0.3s ease;
opacity: 1; 
margin-left: -16px;
margin-top: -55px;
z-index: 4;
height: 55px;
padding: 16px;
display: flex;
justify-content: space-between;
align-items: center;
position: fixed;
background-color: white;
width: 100%;
max-width: 490px;
}
`

export const TextBold = styled.p`
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 26px;
margin-top: 16px;
`

export const SubtitleBold = styled.p`
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: ${props => props.active? '600': '400'};
line-height: 20px;
`

export const TitleBold = styled.p`
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px;
`

export const TextGray = styled.p`
color: ${props => props.theme.textSecondary};
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px;
margin-top: 8px;
`

export const Button = styled.button`
display: flex;
height: 56px;
justify-content: center;
align-items: center;
border-radius: 8px;
background: ${props => props.theme.primary};
width: 100%;
&:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
border: none;
`

export const ContainerButton = styled.div`
padding: 24px 0px;
height: auto;
display: flex;
justify-content: center;
background: white;
position: fixed;
bottom: 0px;
width: 100%;
max-width: 490px;
padding: 16px;
`

export const ButtonText = styled.p`
color: #FBFBFB;
font-family: sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 20px;
`

export const DeleteFiltersText = styled.p`
color: ${props => props.theme.secondaryDark};
text-align: right;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 800;
line-height: 14px
letter-spacing: 0.14px;
&:active{
  opacity: 0.5;
}
`

export const CloseTab = styled.div`
position: fixed;
top: 0px;
height: 10%;
width: 100%;
`

export const TitleBrand = styled.p`
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px;
`