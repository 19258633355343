import styled, { css }  from 'styled-components'
import Image from 'next/image';

export const StyledImage = styled(Image)`
&:active {
    opacity: 0.3;
}
`;

export const ContainerButton = styled.div`
display: flex;
padding: 8px ${props => props.padding};
justify-content: center;
align-items: center;
width: ${props => props.newGrid && '100%'};
color: ${props => props.theme.secondary};
`

export const Button = styled.div`
justify-content: ${props => props.center ? 'center' : 'space-between'};
box-sizing: border-box;
display: flex;
align-items: center;
padding: 0px 8px;
height: ${props => props.height ? '44px' : '40px'};
width:  ${props => props.maxWidth};
border: 1px solid ${props => props.theme.secondaryDark};
border-radius: 24px;
&:active {
    opacity: ${props => props.active ? 0.3 : 1};
}
cursor: pointer;
${props => props.disabledButton && css`
    opacity: 0.5;
    pointer-events: none;
`}
`

export const TextButton = styled.p`
font-size: ${props => props.quantity ? '16px' : ' 14px'};
font-family: sans-serif;
font-style: normal;
font-weight: 800;
line-height: 14px;
text-align: center;
color: ${props => props.theme.secondaryDark};
`

export const ContainerButtonCart = styled.div`
padding:${props => props.newGrid ? '8px 0px 8px 0px' : '8px 8px 8px 0px'}; 
width: ${props => props.newGrid && '100%'};
`

export  const ButtonAdd = styled.div`
display: flex;
width: ${props => props.newGrid ? '100%' : '36px'};
justify-content: center;
align-items: center;
flex-shrink: 0;
align-self: stretch;
border-radius: 24px;
border: 1px solid ${props => props.theme.secondaryDark};
height: 100%;
&:active{
    opacity: 0.5;
}
`