import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import Image from "next/image";
import styled from "styled-components";
const containerStyle = {
  width: "100%",
  height: "200px",
};

const libraries = ["places"];

const MapOrder = ({
  lat,
  lng,
  showHelpButton,
  setHelpModal,
  newContainerStyle,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const center = { lat, lng };

  const handleMapClick = () => {
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(mapsUrl, "_blank");
  };

  return (
    <div style={{ marginBottom: "2px" }}>
      {isLoaded ? (
        <div>
          <GoogleMap
            mapContainerStyle={newContainerStyle ?? containerStyle}
            center={center}
            zoom={!newContainerStyle ? 15 : 12}
            onClick={handleMapClick}
            options={{
              gestureHandling: "none",
              zoomControl: false,
              mapTypeControl: false,
              streetViewControl: false,
              fullscreenControl: false,
              clickableIcons: false,
            }}
          >
            <Marker
              position={center}
              clickable={false}
              optimized={false}
              icon={{
                url: "/imgs/logo_mercadito.png",
                anchor: new google.maps.Point(48, 48),
              }}
            />
          </GoogleMap>
        </div>
      ) : (
        <></>
      )}
      {showHelpButton && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            position: "relative",
            top: "-190px",
            padding: "5px 10px",
          }}
          onClick={() => setHelpModal(true)}
        >
          <HelpButton>
            <Image
              src="/imgs/headset.svg"
              width={20}
              height={20}
              alt="headset"
            />
            Ayuda con tu pedido
          </HelpButton>
        </div>
      )}
    </div>
  );
};

export default MapOrder;

const HelpButton = styled.div`
  background-color: white;
  color: ${(props) => props.theme.primary};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 40px;
`;
