import Paper from "@mui/material/Paper";
import getCurrentLocation from "services/getcurrentlocation";
import Image from "next/image";
import {
    Autocomplete
  } from "@react-google-maps/api";
import { FormattedMessage, useIntl } from "react-intl";
import messages from './messages';
import { ErrorText } from './styled';
import { LocateFixed } from "lucide-react";

const { Container, Title, GeoContainer, GeoText } = require("./styled")
const MapForm = ({ onLoad, onLocationChanged, searchValue, setSearchValue, setNewActualLocation, setActualLocationActivated, autocompleteAddress, setAutocompleteAddress, type, sharedLocation, setModal, nearestShops, showTextError, setShowTextError }) => {

    const intl = useIntl();
    const handleChange = (e) => {
        setShowTextError(false);
        setSearchValue(e.target.value);
    }
    const handleThisLocation = () => {
        if(!sharedLocation){
            setModal(true);
        }else{
            setActualLocationActivated(true)
            setAutocompleteAddress(null)
            getCurrentLocation()
            .then((currentLocation) => {
                setNewActualLocation({...currentLocation, zoom: 13})
            })
        }
    }
    return(
        <Container type={type}>
            <Title>
                {
                    (type === 'checkout' && nearestShops) ? 
                    <FormattedMessage 
                    id='mapForm.deliveryPoint' 
                    defaultMessage='Select a delivery point'
                    /> :
                    <FormattedMessage 
                        id='mapForm.title' 
                        defaultMessage='Find the nearest Market'
                    />
                }
            </Title>
            {
                showTextError && <ErrorText>Debes seleccionar una dirección</ErrorText> 
            }
            <Paper
                component="form"
                variant="outlined"
                sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: 'auto',
                    minWidth: '100%',
                    borderRadius: "8px",
                    boxShadow: "none",
                    justifyContent:'flex-start',
                    padding: '12px 12px',
                    height: '48px',
                    margin: '12px 0 16px 0'
                }}
            >
                <Image src='/imgs/searchmap.png' width={16} height={16} alt='search' />
                <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={onLocationChanged}
                >
                    <input
                        name='searchInput' 
                        type='search'
                        value={searchValue}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                            }
                        }}
                        style={{
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 0.6)",
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            minWidth: '300px',
                            width: '100%',
                            border: 'none',
                            outline: 'none',
                            marginLeft: '8px'
                        }}
                        placeholder={autocompleteAddress ? autocompleteAddress :intl.formatMessage(messages.textSearchDirection)}
                    />
                </Autocomplete>
            </Paper>
            {/* {sharedLocation && (  */}
            <GeoContainer onClick={handleThisLocation}>
                <LocateFixed size={20} />
                <GeoText>
                    <FormattedMessage
                        id='currentLocation'
                        defaultMessage='Use my current location'
                    />
                </GeoText>
            </GeoContainer>
            {/* ) } */}
        </Container>
    )
}

export default MapForm