import styled from 'styled-components';

export const HeaderGreen = styled.div`
height: 100px;
background-color: ${props => props.theme.secondary};
padding: 16px;
display: flex;
flex-direction: column;
`;

export const ContainerUser = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
margin-left: 16px;
`;
