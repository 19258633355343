import styled, { keyframes }  from 'styled-components'

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: center;
background-color:rgb(48, 54, 64, 0.7);
height: 100vh;
width: 100vw;
position: fixed;
top: 0;
z-index: 40;
max-width: 490px;
`

export const DetailBlock = styled.div`
min-height: 40vh;
height: ${props => props.modalReport && 'auto'};
width: 100vw;
background: #FFF;
position: relative;
animation: ${slideIn} 0.5s ease-in-out forwards;
padding: ${props => props.modalReport? '0px 0px 140px 0px' :'16px 16px 24px 16px' };
max-width: 490px;
`

export const Close = styled.div`
display: flex;
justify-content: flex-start;
padding: ${props => props.modalReport ? '16px 0px 8px 12px' : '8px 0px'};
position: ${props => props.modalReport && 'fixed'};
width: 100%;
z-index: ${props => props.modalReport && 9};
background: ${props => props.modalReport && 'white'};
`
export const Title = styled.div`
color: #000;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px;
padding: 8px 0px;
`;

export const Text = styled.p`
color: #000;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
padding: 8px 0;
width: ${props => props.paragraph === 0 ? '72%': '100%' }
`

export const GuaranteeImage = styled.div`
position: absolute;
top: 35px;
right: 16px;
`


export const Container2 = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
padding: 0px 16px;
height: 56px;
width: 100%;
margin: 0px 0px 16px 0px;
`

export const TextContainer = styled.div`
border-radius: 0 8px 8px 0;
background: #E5F3EC;
height: 48px;
width: 90%;
z-index: 0;
display: flex;
align-items: center;
justify-content: center;
padding: 0px 16px 0px 8px;
`

export const Text2 = styled.div`
color: #008648;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 16px;
`

export const StampImage = styled.div`
top: 0;
left: 0;
z-index: 1;
padding: 0;
height: 48px;
background-image: linear-gradient(to right, #FAFBFA 60%, #E5F3EC 60%);
`