import styled, { keyframes }  from 'styled-components'

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: center;
background-color:rgb(48, 54, 64, 0.7);
height: 100vh;
width: 100vw;
position: fixed;
bottom: 0;
left: 0;
z-index: 40;
max-width: 490px;
`
export const DetailBlock = styled.div`
width: 100vw;
background: #FFF;
position: relative;
animation: ${slideIn} 0.5s ease-in-out forwards;
padding: 16px 24px 0px 24px;
max-width: 490px;
height: auto;
display: flex;
flex-direction: column;
justify-content: space-between;
`
export const Close = styled.div`
display: flex;
justify-content: flex-start;
padding: 8px 0
`
export const Title = styled.div`
color: #303640;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: 34px;
margin-top: 24px;
`
export const Text = styled.div`
color: #000;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
padding: 8px 0;
width: ${props => props.paragraph === 0 ? '72%': '100%' }
`
export const StampImage = styled.div`
position: absolute;
top: 35px;
right: 16px;
`

export const DateSelector = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
margin-top: 24px;
gap: 8px
`
