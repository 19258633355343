import { defineMessages } from 'react-intl';

export default defineMessages({
  labelHome: {
    id: 'labelHomeBreadCrumb',
    defaultMessage: 'Home',
  },
  labelOrders: {
    id: 'labelOrdersBreadCrumb',
    defaultMessage: 'Your orders',
  },
  labelInquiries: {
    id: 'labelInquiriesBreadCrumb',
    defaultMessage: 'Questions',
  },
  labelChangeLocation: {
    id: 'labelChangeLocationBreadcrumb',
    defaultMessage: 'Change location',
  },
  labelCategories: {
    id: 'categoriesTextIdLabel',
    defaultMessage: 'Categories',
  },
  aboutMercaditoId: {
    id: 'aboutMercaditoId',
    defaultMessage: 'About Mercadito',
  },
  joinMercaditoId: {
    id: 'joinMercaditoId',
    defaultMessage: 'Join Mercadito',
  },
  yourDataId: {
    id: 'yourDataId',
    defaultMessage: 'Your data',
  },
  labelPickUpDrawer: {
    id: 'labelPickUpDrawerId',
    defaultMessage: 'Yout pick up:',
  },
  labelSelectDeliveryPoint: {
    id: 'labelSelectDeliveryPointId',
    defaultMessage: 'Select the delivery point',
  },
});
