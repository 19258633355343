import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
width: 100%;
height: auto;
padding: 16px;
box-shadow: ${({type}) => type === 'checkout' ? 'none' : '0px 2px 14px 0px rgba(52, 74, 62, 0.30)'};
z-index: 7;
`

export const Title = styled.div`
display: flex;
width: 270px;
flex-direction: column;
justify-content: center;
color: var(--dark-text-primary, #001109);
font-size: 16px;
font-family: Roboto;
font-style: normal;
font-weight: 600;
`
export const GeoContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
border-radius: 8px;
border: 1px solid ${props => props.theme.secondary};
color: ${props => props.theme.secondary};
background: ${props => props.theme.secondaryLighter};
padding: 8px;
cursor: pointer;
&:active{
    opacity: 0.6;
};
`
export const GeoText = styled.p`
margin-left: 8px;
color: ${props => props.theme.secondary};
font-size: 14px;
font-family: sans-serif;
font-style: normal;
font-weight: 800;
line-height: 16px;
`

export const ErrorText = styled.p`
color: red;
font-size: 14px;
font-family: Roboto;
font-style: normal;
font-weight: 600;
line-height: 16px;
`