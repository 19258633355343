import { ContainerModal, DetailBlock, Close, Title, Location, Subtitle, Disclaimer } from './styled';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';

function ModalActivateLocation({ openModal, setOpenModal, sucursal }) {
    const router = useRouter();
    
    const handleClose = () => {
        setOpenModal(false);
    };
    
    return (
      <>
        {openModal && (
          <ContainerModal>
            <DetailBlock isOpen={openModal}>
                <Close>
                    <Image src='/imgs/closemodal.svg' width={24} height={24} alt='close' onClick={handleClose} />
                </Close>
                <Image style={{marginBotton:'4px'}} src='/imgs/map-pin-green.svg' width={40} height={40} alt='icon' />
                <Title>
                <FormattedMessage
                    id='locationdisabledId'
                    defaultMessage="Your location is disabled"
                />
                </Title>
                <Disclaimer>
                <FormattedMessage
                    id='activateAndroidChromeId'
                    defaultMessage="Activate it on your Android with Chrome"
                />
                </Disclaimer>
                <div>
                <Subtitle>
                <FormattedMessage
                    id='locationsubtitleId'
                    defaultMessage="To see the nearest delivery points, activate the location by following these steps:"
                />
                </Subtitle>
                <Subtitle>
                <FormattedMessage
                    id='step1location'
                    defaultMessage="1. At the top of the browser tap here:"
                />
                </Subtitle>
                <div style={{marginTop:'16px'}}>
                <Image src='/imgs/toolbar.svg' width={294} height={49} />
                </div>
                <Subtitle>
                <FormattedMessage
                    id='step2location'
                    defaultMessage="2. Enter the “Permissions” section"
                />
                </Subtitle>
                <Subtitle>
                <FormattedMessage
                    id='step3location'
                    defaultMessage="3. Activate the use of location on our website"
                />
                </Subtitle>
                <div style={{marginTop:'16px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                  <div style={{display:'flex', alignItems:'flex-start'}}>
                  <Image src='/imgs/locationcomplete.svg' width={24} height={24} alt='locationicon' />
                  <div style={{display:'flex', flexDirection:'column', marginLeft:'12px', alignItems:'flex-start'}}>
                    <Location>
                    <FormattedMessage
                        id='locationId'
                        defaultMessage="Location"
                    />
                    </Location>
                    <Disclaimer>
                    <FormattedMessage
                        id='allowedId'
                        defaultMessage="Allowed"
                    />
                    </Disclaimer>
                  </div>
                  </div>
                  <Image src='/imgs/switch.png' width={44} height={44} alt='switchicon' />
                </div>
                <Subtitle>
                <FormattedMessage
                    id='step4location'
                    defaultMessage="4. Refresh the page"
                />
                </Subtitle>
                <Subtitle>
                <FormattedMessage
                    id='step5location'
                    defaultMessage="5. Choose the delivery point closest to your location"
                />
                </Subtitle>
                </div>
            </DetailBlock>
          </ContainerModal>
        )}
      </>
    );
  }
  
  export default ModalActivateLocation;