import styled from  'styled-components'

export const Container = styled.div`
z-index: 3;
max-width: 490px;
background: #fff;
width: 100%;
top: 0px;
display: flex;
flex-direction: column;
position: fixed;
`

export const ContainerNavbar = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 4px 8px;
height: ${props => props.isMenuView ? '80px': '64px'};
justify-content:center;
`

export const ContainerTitle = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 232px;
height: 56px;
gap: 12px;
`

export const Title = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: 600;
font-size: ${props => props.largeTitle ? '16px' : '19px'};
line-height: 26px;
display: flex;
align-items: flex-end;
color: #011D0E;
text-align: center;
`

export const ContainerImage = styled.div`
padding: 12px;
display: flex;
justify-content: center;
position: absolute;
left: 1%;
cursor: pointer;
&:active{
    opacity: 0.5
}
`