import styled from 'styled-components'

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ itemCount }) =>
    itemCount % 2 === 0 ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'};
  gap: 44px;
  padding: 30px 28px 40px 28px;
  background-color: #f7f8f7;
`;

export const Subtitle = styled.p`
color: ${props => props.theme.informationDark};
text-align: center;
font-family: sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px;
margin-top: 16px;
`

export const SubtitleBold = styled.p`
color: ${props => props.theme.informationDark};
font-family: sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 800;
line-height: 12px;
letter-spacing: 0.12px;
text-decoration-line: underline;
margin-top: 8px;
`

export const ContainerCard = styled.div`
display: flex; 
flex-direction: column;
align-items: center;
height: 144px;
justify-content: space-between;
`