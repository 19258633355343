import styled from 'styled-components';

export const CartAnimated = styled.div`
  background-image: url('/imgs/cart_frames_new.svg');
  width: 28px;
  height: 24px;
  background-position: left;
  background-repeat: no-repeat;
  background-size: 448px;

  ${(props) =>
    props.productAdded &&
    `
    @-webkit-keyframes cartBlastAdd {
      0% { background-position: left; }
      100% { background-position: right; }
    }

    @keyframes cartBlastAdd {
      0% { background-position: left; }
      100% { background-position: right; }
    }

    animation-name: cartBlastAdd;
    animation-duration: 530ms;
    animation-iteration-count: 1;
    animation-timing-function: steps(15);
  `};

  ${(props) =>
    props.productRemove &&
    `
    @-webkit-keyframes cartBlastRemove {
      0% { background-position: right; }
      100% { background-position: left; }
    }

    @keyframes cartBlastRemove {
      0% { background-position: right; }
      100% { background-position: left; }
    }

    animation-name: cartBlastRemove;
    animation-duration: 530ms;
    animation-iteration-count: 1;
    animation-timing-function: steps(15);
  `};
`;