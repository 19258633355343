import Image from "next/image"
import { Container, DetailBlock, Close, Text, Title, StampImage } from "./styled"
import { useState } from "react";
import { FormattedMessage } from "react-intl";

const ModalStamp = ({ guarantee, setModalStamp }) => {
    const [startY, setStartY] = useState(0);

    const handleTouchStart = (event) => {
        setStartY(event.touches[0].clientY);
      };
      
    const handleTouchMove = (event) => {
        const currentY = event.touches[0].clientY;
        const deltaY = currentY - startY;
        const threshold = 100; // Umbral de desplazamiento para cerrar el modal
    
        if (deltaY > threshold) {
            setModalStamp(null);
        }
    };

    const handleCloseModal = (event) => {
        if (event.target === event.currentTarget) {
            setModalStamp(null);
        }
    };

    return (
        guarantee &&
        <Container onClick={handleCloseModal} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} >
            <DetailBlock>
                <Close>
                    <Image src='/imgs/closemodal.svg' width={24} height={24} alt='close' onClick={() => setModalStamp(null)} />
                </Close>
                <Title>
                    <FormattedMessage
                        id={`${guarantee.id}_title`}
                        defaultMessage={guarantee.name}
                    />
                </Title>
                {
                    guarantee?.more_info_text?.map((text, index) => {
                        return (
                            <Text key={index} paragraph={index}>
                                <FormattedMessage
                                    id={`${guarantee.id}_p_${index + 1}`}
                                    defaultMessage={text}
                                />
                            </Text>
                        )
                    })

                }
                <StampImage>
                    <Image src={guarantee.image} width={100} height={100} alt='image' />
                </StampImage>
            </DetailBlock>
            
        </Container>
    )
}

export default ModalStamp;
