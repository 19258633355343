import {InputContainer, Input, FloatIcon } from './styled'
import { useContext, useState } from 'react';
import { Context } from 'context/Context';
import getSearchProducts from 'services/getsearchproducts'
import { localNames } from "services/helpers";
import ModalSearch from './ModalSearch';
import { Search, X } from 'lucide-react';
import { trackSearch } from './track';
import messages from './messages';
import { useIntl } from 'react-intl';

function SearchInput({ setIsActiveSearch }) {
  const intl = useIntl();
  let { sucursal, dataShop, user, userTier, deliveryPointSelector } = useContext(Context);
  
  const [value, setValue] = useState('');
  const [productsMatch, setProductsMatch] = useState(null);
  const [modalProducts, setModalProducts] = useState(false);
  const [loading, setLoading] = useState(false);

  const algoliaToken = user ? user.customer_id : (localStorage.getItem(localNames.anonymousId) || localStorage.getItem(localNames.phone));

  const handleChange = (e) => {  
    setValue(e.target.value);
    setProductsMatch(null);
}

  const handleSearch = (item) => {
    setLoading(true);
    setModalProducts(true);
    getSearchProducts(dataShop?.catalog_id, (item || value), algoliaToken, dataShop.verified_purchase, userTier).then(res => {
      if(!res.error) {
        setProductsMatch(res);
        trackSearch(value)
        setLoading(false);
      } 
      if(res.error) {
        setProductsMatch({ data: []});
        setLoading(false);
      } 
    });
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (value.length > 1) {
        handleSearch();
      }
    }
  };

  return (
    <InputContainer>
        <FloatIcon start="true">
            <Search size={20} />
        </FloatIcon>
      <Input 
      value={value}
      onChange={handleChange}  
      placeholder={intl.formatMessage(messages.searchLabel)}
      onKeyUp={handleKeyUp} 
      />
        <FloatIcon>
            <X size={20} onClick={()=> {
            setIsActiveSearch(false);
            setValue('');
            setProductsMatch(null);
            setModalProducts(false);
            }} />
        </FloatIcon>
        {modalProducts && <ModalSearch loading={loading} search={productsMatch?.data?.length > 0} modal={modalProducts} productsMatch={productsMatch} sucursal={sucursal} value={value} deliveryPointSelector={deliveryPointSelector} />}
    </InputContainer>
  )
}

export default SearchInput;