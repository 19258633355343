import Image from "next/image"
import { Container, DetailBlock, Close, Text, Title, GuaranteeImage } from "./styled"
import { useState } from "react";
import { FormattedMessage } from 'react-intl';

const ModalGuarantee = ({ setModalGuarantee }) => {
    const [startY, setStartY] = useState(0);

    const handleTouchStart = (event) => {
        setStartY(event.touches[0].clientY);
      };
      
    const handleTouchMove = (event) => {
        const currentY = event.touches[0].clientY;
        const deltaY = currentY - startY;
        const threshold = 100; 
    
        if (deltaY > threshold) {
            setModalGuarantee(null);
        }
    };

    const handleCloseModal = (event) => {
        if (event.target === event.currentTarget) {
            setModalGuarantee(null);
        }
    };

    const guarantee = {
        title: 'Garantía de Satisfacción',
        image: '/imgs/devolucion.png'
    }

    return (
        <Container onClick={handleCloseModal} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} >
            <DetailBlock>
                <Close>
                    <Image src='/imgs/closemodal.svg' width={24} height={24} alt='close' onClick={() => setModalGuarantee(null)} />
                </Close>
                <Title>
                    {guarantee.title}
                </Title>
                    <Text paragraph={0}>
                    <FormattedMessage
                        id='text1ModalGuarantee'
                        defaultMessage="If an item hasn't met your total satisfaction, with Mercadito, you have 24 hours to return it, and we'll refund your money immediately in Mercadito coupons."
                    />
                    </Text>
                    <Text>
                    <FormattedMessage
                        id='text2ModalGuarantee'
                        defaultMessage='This applies only to complaints made within 24 hours after delivery and by returning the product to the coordinator.'
                    />
                    </Text>
                <GuaranteeImage>
                    <Image src={guarantee.image} width={80} height={80} alt='image' />
                </GuaranteeImage>
            </DetailBlock>
        </Container>
    )
}

export default ModalGuarantee;
