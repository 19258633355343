  export const getUserEligibility = async (username) => {
    const url = `/api/productfruits/check-user-eligibility?username=${username}`
    try {
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
        const data = await response.json();
        return data.error ? [] : data.data;
    } catch (error) {
        return { error };
    }
}
