import Image from "next/image"
import { guaranteeTypes } from "services/guarantee"
import { GridContainer, Subtitle, SubtitleBold, ContainerCard } from "./styled"
import { FormattedMessage } from "react-intl"

const GridGuarantee = ({ setModalStamp }) => {
    const guaranteeArray = Object.values(guaranteeTypes);
    return (
        <GridContainer itemCount={guaranteeArray.length}>
           {guaranteeArray.map((guarantee)=> {
               return(
                <ContainerCard key={guarantee.id}>
                    <Image width={72} height={72} src={guarantee.image} alt='icon' />
                    <Subtitle>
                        <FormattedMessage
                        id={`${guarantee.id}_title`}
                        defaultMessage={guarantee.name}
                        />
                    </Subtitle>
                    <SubtitleBold onClick={() => {
                        setModalStamp(guarantee);
                    }}>
                        <FormattedMessage
                            id='more_info'
                            defaultMessage='More info'
                        />
                    </SubtitleBold>
                </ContainerCard>   
               )
           })}
        </GridContainer>
    )
}

export default GridGuarantee;
