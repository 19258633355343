import styled from  'styled-components'

export const ContainerMessage = styled.div`
background-color: #011D0E;
width: 100%;
display: flex;
justify-content: center;
height: 34px;
align-items: center;
`

export const TextMessage = styled.p`
color: white;
font-size: 14px;
font-weight: 600;
`

export const Strong = styled.strong`
color: white;
font-size: 14px;
`