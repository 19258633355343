import style from 'styles/CardCategory.module.scss';
import { useRouter } from 'next/router';
import { Context } from 'context/Context';
import { useContext, useEffect, useState } from 'react';
import ProgressBar from 'componentes/ProgressBar';
import Image from 'next/image';
import nameunitmeasure from 'services/nameunitmeasure';
import { finalDiscount, finalPrice, hasDiscount } from 'services/producthelpers';
import formatNumber from 'services/formatNumber';
import { currencies } from 'services/helpers'
import { ContainerProducts, CardsContainer, Card, InfoCard, ContainerInfo, PercentajeDiscount, ImageDiscount, Signe, InfoProduct, ProductName, ContainerPriceAndDiscount, PriceDiscount, Price, ContainerPriceSavings, TextSavings, UOMS, ContainerContent, ContainerButton, Button, TextButton, TitleNoResults, SubtitleNoResults, MinimumWarning, MinimumText, ContainerSaving} from 'componentes/CardCategoryId/styled'
import AddToCartControl from 'componentes/AddToCartControl';
import { capitalizeFirstLetter } from 'services/helpers'
import DrawerNavigation from 'componentes/DrawerNavigation';
import Navbar from 'componentes/Navbar';
import { FormattedMessage, useIntl } from 'react-intl'
import ModalFilters from 'componentes/ModalFilters/ModalFilters';
import { applyFilters, getPriceRange } from 'services/filter';
import { showFilters } from 'services/featureflags';
import FilterAndCategory from './components/FilterAndCategory';
import ModalUnmissablePromotions from 'componentes/ModalUnmissablePromotions';
import CountDown from 'componentes/CountDown';
import calculateTimeDifferenceInSeconds from 'services/calculatedtimediscount';
import DiscountFlag from 'componentes/DiscountFlag';

function ProductsHighlightsComponent({ products, sucursal, search, title, unmissable, flashDeals }) {
  const intl = useIntl();
  let { pais, isCatalogSG, isDefaultTier } = useContext(Context);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [filterObject, setFilterObject] = useState(null);
  const [countFilters, setCountFilters] = useState(0);
  const [modalUnmissablePromotions, setModalUnmissablePromotions] = useState(null);

  const [showFiltersFlag, setShowFiltersFlag] = useState(null);
  const router = useRouter();
  const [openFilters, setOpenFilters] = useState(false);
  
  const handleClick = (category_id, product_id) => {
    if(unmissable){
      router.push(`/${sucursal}/${category_id}/${product_id}`);
    }else if(flashDeals){
      router.push(`/${sucursal}/${category_id}/${product_id}`);
    }else{
      router.push(`/${sucursal}/${category_id}/${product_id}`);
    }
  };

  useEffect(() => {
    showFilters()
    .then(setShowFiltersFlag)
  }, [])

  useEffect(() => {
    (!products && sucursal) && router.push(`/${sucursal}`)
  }, [products, sucursal])

  useEffect(() => {
    if(filterObject) {
      let count = 0;
      if(filterObject.order && filterObject.order !== 'recommended_sort') count++;
      if(filterObject.discount) count++;
      if(filterObject.range.minPrice !== getPriceRange(products).minPrice || filterObject.range.maxPrice !== getPriceRange(products).maxPrice) count++;
      setCountFilters(count);
    }
  }, [filterObject])

  useEffect(() => {
    products && setFilteredProducts(products)
    products && setFilterObject({...filterObject, range: getPriceRange(products)})
  }, [products])

  useEffect(() => {
    filterObject &&
    applyFilters(products, filterObject)
    .then(setFilteredProducts)
  }, [filterObject])


  const handleFilters = (type, value) => {
    switch (type) {
      case 'discount_filter':
        setFilterObject({...filterObject, discount: value})
        break;
      case 'price_filter':
        setFilterObject({...filterObject, range: value})
        break;
      case 'order_filter':
        setFilterObject({...filterObject, order: value})
        break;
      default:
        break;
    }
  }

  const progress = () => {
    return (
      <div>
        <ProgressBar />
      </div>
    );
  };
  setTimeout(progress, 2000);

  let lastest = router.route.includes('products-highlights');
  const isShowFilters = products?.length > 2;
  return (
    filteredProducts && showFiltersFlag !== null &&
    <>
    <DrawerNavigation isSearchButtonMoved routerBack={`/${sucursal}`} />
    {isCatalogSG && !search && (<Navbar title={'Destacados para ti'} redirection={`/${sucursal}`} />)}
    <ContainerContent unmissable={unmissable} flashDeals={flashDeals}>
     {!flashDeals && (<FilterAndCategory filteredProducts={filteredProducts} isShowFilters={isShowFilters} title={title} showFiltersFlag={showFiltersFlag} countFilters={countFilters} flashDeals={flashDeals} unmissable={unmissable} setModalUnmissablePromotions={setModalUnmissablePromotions} />)} 
      <ContainerProducts flashDeals={flashDeals} lastest={lastest} unmissable={unmissable}>
        {products?.length > 0 ?
          <>
          <CardsContainer flashDeals={flashDeals} grid={filteredProducts.length > 0}>
            {filteredProducts.length > 0 ?
              filteredProducts.map((item) => {
                let percentage = hasDiscount(item) ? finalDiscount(item).toString() : 0;
                const productTierDiscount = item['product-discount']?.tier_id ?? null
                const endDate = item && item["product-discount"] ? new Date(item["product-discount"]["end_date"]) : null;
                const timeDifferenceInSeconds = calculateTimeDifferenceInSeconds(endDate, pais);
                const minimum = item.minimum_order_value > 0;
                return (
                    <Card
                      key={item.id}
                    >
                      <InfoCard 
                      onClick={() => {
                      handleClick(item.category_id, item.id)
                      }}>
                      <ContainerInfo>
                        {hasDiscount(item) && (
                        <div>
                          <DiscountFlag discount={finalDiscount(item)} />
                        </div>
                        )}
                        <InfoProduct>
                          <Image
                            src={`${item['master-product'].image}`}
                            alt={`${item['master-product'].name}`}
                            width={300}
                            height={280}
                          />
                          {minimum && <MinimumWarning flashDeals>
                          <MinimumText>
                          <FormattedMessage
                            id='withMinimunTitle'
                            defaultMessage='With minimum'
                            />
                          </MinimumText>
                          </MinimumWarning>}
                          <ContainerPriceAndDiscount flashDeals={flashDeals}>
                          {
                             !unmissable && timeDifferenceInSeconds > 0 &&
                            <Image src='/imgs/flash.svg' width={16} height={16} alt='flash' />
                          }
                           {
                              unmissable &&
                              <Image src='/imgs/fire.svg' width={16} height={16} alt='fire' />
                            }
                            <Price>
                              {currencies[pais]}<span style={{width: '2px'}} />{formatNumber(finalPrice(item), pais)}
                            </Price>
                            
                            {hasDiscount(item) && (
                              <PriceDiscount>
                                {currencies[pais]}<span style={{width: '2px'}} />{item.local_price > item.price ? item.local_price : item.price}
                              </PriceDiscount>
                            )}
                         </ContainerPriceAndDiscount>
                         {timeDifferenceInSeconds && (<CountDown initialTimeInSeconds={timeDifferenceInSeconds} productsView/>)}
                         {hasDiscount(item) && (
                         (isDefaultTier && productTierDiscount) ? (
                              <ContainerPriceSavings>
                                <TextSavings isDefaultTier>
                                PRIMERA COMPRA
                                </TextSavings>
                              </ContainerPriceSavings>
                          ) : (
                            <ContainerSaving>
                            <Image src='/imgs/badge-percent.svg' alt='icon' width={12} height={12} /> 
                              -{finalDiscount(item)}%
                                {' '}
                                <FormattedMessage
                                id='textSaveId'
                                defaultMessage="Save"                    
                               />
                                {' '}
                              {currencies[pais]}{formatNumber(item.local_price > item.price ? item.local_price - finalPrice(item) : item.price - finalPrice(item), pais)}
                          </ContainerSaving>
                        ))
                        }
                          <UOMS>
                          /{nameunitmeasure(item.unit_of_measurement.erp_name)}
                          </UOMS>
                              <ProductName>
                                {capitalizeFirstLetter(item.name)}
                              </ProductName>
                        </InfoProduct>
                      </ContainerInfo>
                      </InfoCard>
                      {
                        !isCatalogSG &&
                        <AddToCartControl
                            product={item}
                            category={'N/A'}
                            maxWidth={'100%'}
                        />
                      }
                    </Card>
                );
              }) :
              <div>
                <TitleNoResults>
                     <FormattedMessage 
                          id='noResultsFoundText'
                          defaultMessage='No results found'
                        />
                </TitleNoResults>
                <SubtitleNoResults>
                     <FormattedMessage
                      id='removingFilterSubtitle'
                      defaultMessage='Try removing filters to get results.'
                     />
                </SubtitleNoResults>
                <ContainerButton>
                  <Button onClick={()=> setFilterObject({order:'recommended_sort', range: getPriceRange(products), discount: false})}>
                    <TextButton>
                      <FormattedMessage 
                          id='removeFiltersText'
                          defaultMessage='Remove filters'
                        />
                    </TextButton>
                  </Button>
                </ContainerButton>
              </div>
            }
            </CardsContainer>
              {openFilters && <ModalFilters setFilterObject={setFilterObject} countFilters={countFilters} setFilteredProducts={setFilteredProducts} openFilters={openFilters} setOpenFilters={setOpenFilters} handleFilters={handleFilters} filteredProducts={filteredProducts} products={products} filterObject={filterObject} />}
           </> :
          <div className={style.noProducts}>
            <h1 className={style.noProductsText}>
              <FormattedMessage
               id='noProductsAvailable'
               defaultMessage="There are no products in this category 😔"                    
              />
            </h1>
          </div>
        }
      </ContainerProducts>
    </ContainerContent>
    {
      modalUnmissablePromotions &&
      <ModalUnmissablePromotions setModalUnmissablePromotions={setModalUnmissablePromotions} />
    }
    </>
    )
}

export default ProductsHighlightsComponent;
