import { useState, useEffect } from 'react';

const useDetectOS = () => {
  const [os, setOs] = useState('Unknown');

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      setOs('Android');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setOs('iOS');
    } else {
      setOs('Unknown');
    }
  }, []);

  return os;
};

export default useDetectOS;
