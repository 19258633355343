import { defineMessages } from 'react-intl';

export default defineMessages({
  titleInputQuantity: {
  id: 'titleInputQuantity',
    defaultMessage: 'How much do you want?',
  },
  labelQuantity: {
    id: 'labelQuantity',
    defaultMessage: 'Quantity'
  },
});
