import styled, { css }  from 'styled-components'

export const Container = styled.div`
padding-top: 16px;
height: 100%;
${props => props.unmissable && css`
    background: ${props => props.theme.secondary};
    margin-top: 16px;
    padding: 20px 0 20px 0;
    background-image: url('/imgs/bg_pattern_fire.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 150px;
`};
${props => props.flashDeals && css`
    background: ${props.theme.warning};
    padding: 20px 0 20px 0;
    background-image: url('/imgs/bg_pattern_flash.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 150px;
    margin-top: 16px;
`};
`;

export const Title = styled.p`
color: #011D0E;
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 20px;
display: flex;
align-items: ${props => props.unmissable ? 'flex-end' : 'center'};
${props => props.unmissable && css`
color: white;
`};
${props => props.flashDeals && css`
color: black;
`};
`;

export const ButtonAllProducts = styled.div`
color: #00904B;
text-decoration: none;
${props => props.unmissable && css`
color: white;
text-decoration: underline;
`};
${props => props.flashDeals && css`
color: black;
text-decoration: underline;
`};
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 800;
line-height: normal;
&:active {
    opacity: 0.6
};
cursor: pointer;
`

export const ContainerHeader = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 0px 16px;
`

export const ContainerCarousel = styled.ul`
width: ${props => `${props.windowWidth}px`};
max-width: 490px;
height: 224px;
overflow-x: auto;
overflow-y: hidden;
white-space: nowrap;
padding: 0px 16px;
display: flex;
margin-top: 8px;
::-webkit-scrollbar {
  display: none;
}
`;

export const ContainerCarouselHorizontal = styled.ul`
width: ${props => `${props.windowWidth}px`};
max-width: 490px;
height: 70px;
overflow-x: auto;
overflow-y: hidden;
white-space: nowrap;
padding: 0px 16px;
display: flex;
margin-top: 8px;
::-webkit-scrollbar {
  display: none;
}
`

export const CardEmpty = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 120px;
min-width: 120px;
height: 224px;
background-color: #f0f0f0;
border-radius: 8px;
background: #FFF;
position: relative;
&:active{
  opacity: 0.5;
};
cursor: pointer;
`

export const TextCard = styled.p`
color: #011D0E;
font-family: sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 800;
letter-spacing: 0.12px;
margin-right: 4px;
margin-top: -2px;
`

export const ContainerCardHorizontal = styled.div`
width: 100%;
background: ${props => props.view === 'ProductPage' ? '#FFF' : '#FAFBFA'};
padding-top: 16px;
`