export default function calculateTimeDifferenceInSeconds(endDate, countryId) {
    const now = new Date();

    const optionsTimezone = {
      1 : { timeZone: 'America/Buenos_Aires' },
      2: { timeZone: 'America/Mexico_City' },
      180: { timeZone: 'America/Lima' }
    }

    const startDate = new Date(now.toLocaleString('en-US', optionsTimezone[countryId]));
    
    const hours = 24;

    const secondsToEnd = hours * 60 * 60;
    
    if (!startDate || !endDate) {
      return null;
    }
  
    const timeDifferenceInMilliseconds = endDate - startDate;
    const timeDifferenceInSeconds = Math.floor(timeDifferenceInMilliseconds / 1000);

    if (timeDifferenceInSeconds <= secondsToEnd) {
      return timeDifferenceInSeconds;
    }

    return null;
  }