import { useRouter } from 'next/router';
import { Context } from 'context/Context';
import { useContext, useEffect, useState } from 'react';
import Image from 'next/image';
import {CategoryTitle, CategoryDescription, Filter, FilterText, ContainerFilterAndCategory, FilterQuantity, ContainerContent, QuantityOfProducts } from './styled'
import { capitalizeFirstLetter, localNames } from 'services/helpers'
import DrawerNavigation from 'componentes/DrawerNavigation';
import Navbar from 'componentes/Navbar';
import { FormattedMessage, useIntl } from 'react-intl'
import GuaranteeStamp from 'componentes/GuaranteeStamp';
import ModalStamp from 'componentes/ModalStamp';
import ModalGuarantee from 'componentes/CashBackGuarantee/ModalGuarantee';
import { applyFilters, getPriceRange } from 'services/filter';
import { dateSelector } from 'services/featureflags';
import messages from './messages';
import GridGuarantee from 'componentes/GridGuarantee';
import MinimumOrderValue from 'componentes/MinimumOrderValue';
import ProductsInCategory from 'componentes/ProductsInCategory';
import ModalFilters from 'componentes/ModalFilters/ModalFilters';
import CashBackGuarantee from 'componentes/CashBackGuarantee';
import useFeatureFlags from '../useFeatureFlags';
import {
  getRecommendations,
} from "services/retailrocket";
import { useQuery } from "react-query";
import ProductsCarousel from "componentes/ProductsCarousel";
import { trackRecommendationBlockViewed } from 'componentes/ProductId/track';

function CardCategoryId({ products, sucursal, categoria, search, isDescription, algolia }) {
  const intl = useIntl();

  const { pais, isCatalogSG, isSG, minimumOrderValue, dataShop, deliveryPointSelector, user } = useContext(Context);
  const dateSelectorFeature = dateSelector(dataShop?.country_id);

  const [modalStamp, setModalStamp] = useState(null);
  const [modalGuarantee, setModalGuarantee] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [filterObject, setFilterObject] = useState(null);
  const [countFilters, setCountFilters] = useState(0);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const router = useRouter();
  const [openFilters, setOpenFilters] = useState(false);
  const showGuarantee = useFeatureFlags('guarantee_cashback');

  const userId =
    user?.customer_id || localStorage.getItem(localNames.anonymousId) || '';

    const { data: categoryRecommendationsData } = useQuery(
      ["categoryRecommendationsData"],
      async () => await getRecommendations(userId, dataShop?.catalog_id, 'popularByCategoryPath', 'categoryRecommendations'),
      {
        enabled:
          !!user?.customer_id ||
          (!!localStorage.getItem(localNames.anonymousId) &&
            !!dataShop.catalog_id),
      }
    );

    const extraParams = `categoryPaths=${categoria?.name}`;

    const { data: categoryRecommendationsByUser } = useQuery(
      ["categoryRecommendationsByUser"],
      async () => await getRecommendations(userId, dataShop?.catalog_id, 'personalCompositeForCategoryByCategoryPath', 'partnerRecommendations', extraParams),
      {
        enabled:
          !!user?.customer_id ||
          (!!localStorage.getItem(localNames.anonymousId) &&
            !!dataShop.catalog_id),
      }
    );

    useEffect(() => {
      if (categoryRecommendationsData?.length > 0) {
        trackRecommendationBlockViewed("popularByCategoryPath");
      }
    }, [categoryRecommendationsData]);

    useEffect(() => {
      if (categoryRecommendationsByUser?.length > 0) {
        trackRecommendationBlockViewed(
          "personalCompositeForCategoryByCategoryPath"
        );
      }
    }, [categoryRecommendationsByUser]);

  useEffect(() => {
    if(filterObject) {
      let count = 0;
      if(filterObject.order && filterObject.order !== 'recommended_sort') count++;
      if(filterObject.discount) count++;
      if(filterObject.range.minPrice !== getPriceRange(products).minPrice || filterObject.range.maxPrice !== getPriceRange(products).maxPrice) count++;
      if(filterObject.brands) count++;
      setCountFilters(count);
    }
  }, [filterObject])

  useEffect(() => {
    products && setFilteredProducts(products)
    products && setFilterObject({...filterObject, range: getPriceRange(products)})
  }, [products])

  useEffect(() => {
    filterObject &&
    applyFilters(products, filterObject)
    .then(setFilteredProducts)
  }, [filterObject])

  const handleClick = (product_id) => {
    !isCatalogSG && !search &&
    router.push(`/${sucursal}/${categoria?.id}/${product_id}`);
    !isCatalogSG && search &&
    router.push(`/${sucursal}/${categoria?.id}/${product_id}`);
  };

  const handleClickSearch = (product_id, category_id) => {
    !isCatalogSG && !search &&
    router.push(`/${sucursal}/${category_id}/${product_id}`);
    !isCatalogSG && search &&
    router.push(`/${sucursal}/${category_id}/${product_id}?search=${search}`);
  };

  const handleFilters = (type, value) => {
    switch (type) {
      case 'discount_filter':
        setFilterObject({...filterObject, discount: value})
        break;
      case 'price_filter':
        setFilterObject({...filterObject, range: value})
        break;
      case 'order_filter':
        setFilterObject({...filterObject, order: value})
        break;
      case 'brands_flter':
        setFilterObject({...filterObject, brands: value})
        break;
      default:
        break;
    }
  }

  const isShowFilters = products?.length >= 4;

  return (
    isSG !== null && filteredProducts &&
    <>
    {!search && !isCatalogSG && (<DrawerNavigation isSearchButtonMoved routerBack={`/${categoria?.id}`} />)}
    {isCatalogSG && !search && (<Navbar title={categoria?.name} redirection={`/${sucursal}`} />)}
    <ContainerContent 
    block={minimumOrderValue} 
    search={search} 
    style={{
      paddingTop: (dateSelectorFeature && !deliveryPointSelector && !isCatalogSG) && !search ? '90px' : !search && !isCatalogSG && '140px', 
      background:'#FAFBFA'
    }}>
        {
          modalStamp && <ModalStamp guarantee={modalStamp} setModalStamp={setModalStamp}/>
        }
        {modalGuarantee && <ModalGuarantee setModalGuarantee={setModalGuarantee} />}
        {
          !search && !isCatalogSG &&
          <GuaranteeStamp category={categoria?.id} setModalStamp={setModalStamp} />
        }
        {
          showGuarantee && !search && !isCatalogSG &&
          <CashBackGuarantee setModalGuarantee={setModalGuarantee} country={pais} />
        }
            {categoryRecommendationsData?.length > 2 && (
            <ProductsCarousel
              view="ProductPage"
              title={"Populares de la categoría"}
              products={categoryRecommendationsData}
              type={"recommended_products_category"}
            />
        )}

        {categoryRecommendationsByUser?.length > 2 && (
            <ProductsCarousel
              view="ProductPage"
              title={"Especiales para ti"}
              products={categoryRecommendationsByUser}
              type={"recommended_products_category_by_user"}
            />
        )}

        {!search && !isCatalogSG  && (
        <ContainerFilterAndCategory description={categoria?.description}>
        <div style={{maxWidth: isShowFilters && '65%'}}>
          <div style={{display: 'flex', flexDirection:'row'}}>
            <CategoryTitle>
              {capitalizeFirstLetter(categoria?.name)}
              <QuantityOfProducts>{`(${filteredProducts?.length > 0 ? filteredProducts.length : intl.formatMessage(messages.noresults)})`}</QuantityOfProducts>
            </CategoryTitle>
          </div>
          {categoria?.description && (<CategoryDescription>{categoria?.description}</CategoryDescription>)}
        </div>
        {
           isShowFilters && 
          <Filter onClick={()=> setOpenFilters(!openFilters)}>
            <Image src='/imgs/filterIcon.svg' width={20} height={20} alt='image' />
            <FilterText>
            <FormattedMessage
                id='textFilterId'
                defaultMessage="Filter"
            />
            </FilterText>
            {
              countFilters > 0 &&
              <FilterQuantity>{countFilters}</FilterQuantity>
            }
          </Filter>
        }
        </ContainerFilterAndCategory>
        )}

        <ProductsInCategory 
        isCatalogSG={isCatalogSG}
        products={products} 
        sucursal={sucursal} 
        categoria={categoria} 
        search={search} 
        isDescription={isDescription} 
        algolia={algolia}
        handleClick={handleClick} 
        handleClickSearch={handleClickSearch}
        filteredProducts={filteredProducts}
        setFilterObject={setFilterObject}
        />

        {openFilters && <ModalFilters 
        setFilterObject={setFilterObject} 
        countFilters={countFilters} 
        setFilteredProducts={setFilteredProducts} 
        openFilters={openFilters} 
        setOpenFilters={setOpenFilters} 
        handleFilters={handleFilters} 
        filteredProducts={filteredProducts} 
        products={products} 
        filterObject={filterObject} 
        selectedBrands={selectedBrands} 
        setSelectedBrands={setSelectedBrands} 
        pais={pais}/>}

        {search && (
        <GridGuarantee 
        setModalStamp={setModalStamp}
        />
        )}
    </ContainerContent>
    {
      minimumOrderValue > 0 && !openFilters && !search &&
      <MinimumOrderValue />
    }
    </>
  );
}

export default CardCategoryId;
