import styled from 'styled-components';

export const HeaderGreen= styled.div`
height: 180px;
background-color: ${props => props.theme.secondary};
padding: 16px;
display: flex;
flex-direction: column;
justify-content: space-between;
margin-bottom: 24px;
position: relative;
`;

export const TitleHeader = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 28px;
color: ${props => props.theme.white};
height: 25px;
`

export const CircleIcon = styled.div`
width: 64px;
height: 64px;
border-radius: 100%;
background-color: white;
margin-bottom: 8px;
display: flex;
justify-content: center;
align-items: center;
`

export const Letter = styled.p`
color: ${props => props.theme.information};
font-family: sans-serif;
font-size: 36px;
font-style: normal;
font-weight: 800;
line-height: 36px;
`


export const ContainerCardWallet = styled.div`
padding: 0px 16px;
display: flex;
justify-content: center;
position: absolute;
z-index: 2;
top: 140px;
width: 100%;
left: 0px;
`

export const CardWallet = styled.div`
width: 100%;
border-radius: 24px;
border: 1px solid #F2F3F2;
background: #FFF;
height: 56px;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0px 8px 0px 16px;
`

export const ContainerAmountWallet = styled.div`
display: flex;
align-items: center;
justify-content: center;
`

export const AmountWallet = styled.p`
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 800;
line-height: 20px;
margin-right: 8px;
`