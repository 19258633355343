import { track } from 'services/gtm';

export function trackSelectBrand(country_id, brand_id, brand_name, view) {
    track('select_brand', {
      ecommerce: {
        country_id,
        brand_id,
        brand_name,
        view,
      }
    });
};
  

export function trackSelectViewAll() {
  track('view_all_brands', {
    ecommerce: {
      view:'view_all_brannds',
    }
  });
};

export function trackViewAllBrandList(country_id, brands) {
  track('view_all_brand_list', {
    ecommerce: {
      country_id,
      items: brands.map(brand => {
        return {
          brand_id: brand.id,
          brand_name: brand.name,
        }
      })
    }
  });
};

export function trackViewBrandList(country_id, brand_id, brand_name, products) {
  track('view_brand_list', {
    ecommerce: {
      country_id,
      brand_id,
      brand_name,
      items: products.map(product => {
        return {
          product_id: product.id,
          product_name: product.name,
        }
      })
    }
  });
};