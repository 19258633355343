import { useContext } from 'react';
import { Context } from 'context/Context';

const useFeatureFlags = (featureKey) => {
    let { featureFlags } = useContext(Context);

    return featureFlags?.includes(featureKey);
};

export default useFeatureFlags;
