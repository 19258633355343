import { TextBold } from './styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

function RadioButtonsFilter({setSelectedValue, selectedValue }) {
    const intl = useIntl();

    const handleChange = (event) => {
      setSelectedValue(event.target.value); 
    };

    const BpIcon = styled('span')(({ theme }) => ({
      borderRadius: '50%',
      width: 24,
      height: 24,
      padding: '4px',
      margin: 'auto', 
      boxShadow: 'inset 0 0 0 2px #CCD2CF',
      backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
      backgroundImage:
        theme.palette.mode === 'dark'
          ? '#00904B'
          : '#00904B',
      '.Mui-focusVisible &': {
        outline: '2px auto #00A656',
        outlineOffset: 2,
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background:
          theme.palette.mode === 'dark' ? '#00904B' : '#00904B',
      },
    }));

    const BpCheckedIcon = styled(BpIcon)({
      backgroundColor: '#00904B',
      backgroundImage: '#00904B',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      '& ~ .MuiRadio-colorDefault.Mui-checked': {
        color: '#00A656',
        '&:hover': {
          backgroundColor: '#00A656',
        }
      }
    });

    function BpRadio(props) {
      const { checked } = props;
      return (
        <Radio
          disableRipple
          color="default"
          checkedIcon={<BpCheckedIcon />}
          icon={<BpIcon />}
          sx={{
            "&:checked ~ .MuiSvgIcon-root": {
              padding: '0px',
              background: checked ? 'green' : 'green',
            },
          }}
          {...props}
        />
      );
    }

    const ReversedFormControlLabel = styled(FormControlLabel)(({ theme, value }) => ({
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginRight:'0px',
      marginLeft:'0px',
      '& .MuiFormControlLabel-label': {
        marginLeft:'0px',
        fontWeight: ((value === selectedValue) || (selectedValue === undefined && value === 'recommended_sort')) && '600',
      },
      width: '100%',
      minWidth:'100%'
    }));

    return (
            <>
                  <TextBold>
                    <FormattedMessage
                      id='sortById'
                      defaultMessage='Sort by'
                    />
                  </TextBold>
                  <FormControl sx={{mt:'8px', width:'100%'}}>
                      <RadioGroup
                        aria-labelledby="demo-customized-radios"
                        name="customized-radios"
                        value={selectedValue || 'recommended_sort'}
                        onChange={handleChange}
                      >
                      <ReversedFormControlLabel value='recommended_sort' label={intl.formatMessage(messages.recommended)} control={<BpRadio />} sx={{height:'55px'}} /> 

                        <ReversedFormControlLabel value="price_sort" control={<BpRadio />} label={intl.formatMessage(messages.price)} sx={{height:'55px', fontWeight:'600'}} />

                        <ReversedFormControlLabel value="discount_sort" control={<BpRadio />} label={intl.formatMessage(messages.discount)}  sx={{height:'55px'}} />

                        <ReversedFormControlLabel value="created_at_sort" control={<BpRadio />} label={intl.formatMessage(messages.newest)}  sx={{height:'55px'}} />

                        <ReversedFormControlLabel value="name_sort" control={<BpRadio />} label={intl.formatMessage(messages.alphabetic)}   sx={{height:'55px'}} />
                      </RadioGroup>
                 </FormControl>
          </>
    );
  }
  
  export default RadioButtonsFilter;