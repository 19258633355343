import styled, { css }  from 'styled-components'

export const Card = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
width: 118px;
min-width: ${props => props.carouselType !== 'lastrecommendedproducts' ? '118px' : '30vw'};
height: 224px;
margin-right: ${props => props.last && !props.marginShowEmptyCard && props.carouselType !== 'lastrecommendedproducts' ? '0px' : '8px'};
background-color: #f0f0f0;
border-radius: 8px;
background: #FFF;
position: relative;
`;

export const ContainerElements = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
padding: 0px 8px;
overflow: hidden;
cursor: pointer;
`;

export const NameOfProductContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NameOfProduct = styled.p`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #011D0E;
max-height: ${props => (props.discount ? '36px' : '54px')};
overflow: hidden;
text-overflow: ellipsis;
white-space: normal;
display: -webkit-box;
-webkit-line-clamp: ${props => (props.discount ? 2 : 3)};
-webkit-box-orient: vertical;
`;

export const FinalPrice = styled.p`
color: #011D0E;
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 800;
line-height: 16px;
display: flex;
align-items: center;
`

export const PreviousPrice = styled.p`
color: #344A3E;
font-family: sans-serif;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: 12px;
text-decoration: line-through;
`

export const Signe = styled.span`
font-size: 12px;
font-weight: 800;
margin-left: -1px;
`;

export const PercentajeDiscount = styled.p`
position: absolute;
font-family: sans-serif;
font-style: normal;
font-weight: 800;
display: flex;
align-items: center;
font-size: 16px;
line-height: 16px;
text-align: center;
color: #011D0E;
right: ${props => props.percentage === 1 ? '8.5px' : ' 3.5px'};
z-index: 2;
align-self: center;
top: 9px; 
`;

export const SaveText = styled.p`
color: ${props => props.isDefaultTier ? '#FFF' : '#011D0E'};
font-family: sans-serif;
font-size: ${props => props.isDefaultTier ? '10px' : '11px'};
font-style: normal;
font-weight: ${props => props.isDefaultTier ? '800' : '500'};
text-transform: uppercase;
line-height: 16px;
background: ${props => props.isDefaultTier ? props.theme.primary : props.theme.warning};
padding: ${props => props.isDefaultTier ? '0px 3px' : '0px 0.5px'};
`

export const SaveContainer = styled.div`
border-radius: 1px;
display: flex;
align-items: center;
width: auto;
margin-top: 2px;
`

export const ContainerPriceSavings = styled.div`
display: flex;
width: 100%;
margin-top: 5px;
color: #FF3025;
font-family: Roboto;
font-size: 11px;
font-style: normal;
font-weight: 500;
line-height: 16px;
gap: 2px;
`;


export const UOMS = styled.p`
color: #344A3E;
font-family: sans-serif;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: 16px; 
`

export const ContainerImageProduct = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
position: relative;
` 

export const ContainerImageDiscount = styled.div`
position: absolute;
top: 4px;
right: 4px;
`

export const Flex = styled.div`
display: flex;
align-items: center;
margin-top: 8px;
`

export const Span = styled.span`
width: 2px;
`

export const MinimumWarning = styled.div`
border-radius: 2px;
background: #F7F8F7;
position: absolute;
top: 4px;
left: 0px;
width: auto;
z-index: 1;
padding: 0px 2px;
`

export const MinimumText = styled.p`
color: #344A3E;
font-family: sans-serif;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: 16px; 
`