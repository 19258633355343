import { CategoryTitle, Filter, FilterText, SubText, ContainerFilterAndCategory, FilterQuantity, QuantityOfProducts, ImageContainer, SpanMoreInfo } from 'componentes/CardCategoryId/styled'
import Image from 'next/image'
import { FormattedMessage } from 'react-intl'
import { capitalizeFirstLetter } from 'services/helpers'


const FilterAndCategory = ({ filteredProducts, isShowFilters, title, showFiltersFlag, countFilters, unmissable, setModalUnmissablePromotions }) => {
    return (
        <ContainerFilterAndCategory unmissable={unmissable} >
            <div style={{maxWidth: isShowFilters && !unmissable && '65%'}}>
                <div style={{display: 'flex', flexDirection: unmissable ? 'column' : 'row', alignItems: unmissable ? 'flex.start' : 'center'}}>
                <CategoryTitle unmissable={unmissable} >{capitalizeFirstLetter(title)}</CategoryTitle>
                {
                    unmissable &&
                    <>
                        <SubText>
                            <FormattedMessage
                                id='textUnmissablePromotions'
                                defaultMessage="You can purchase a limited quantity of these products per delivery."
                            />
                            <SpanMoreInfo onClick={() => setModalUnmissablePromotions(true)}>
                                <FormattedMessage
                                    id='guaranteeStamp.moreInfo'
                                    defaultMessage="More info"
                                />
                            </SpanMoreInfo> </SubText>
                        <ImageContainer>
                            <Image src='/imgs/fire.svg' width={110} height={110} alt='image' />
                        </ImageContainer>
                    </>
                }
                {
                    !unmissable &&
                    <QuantityOfProducts>{`(${filteredProducts?.length > 0 ? filteredProducts.length : intl.formatMessage(messages.noresults)})`}</QuantityOfProducts>
                }
                </div>
            </div>

            {
                showFiltersFlag && isShowFilters && !unmissable &&
                <Filter onClick={()=> setOpenFilters(!openFilters)}>
                <Image src='/imgs/filterIcon.svg' width={20} height={20} alt='image' />
                <FilterText unmissable={unmissable} >
                <FormattedMessage
                    id='textFilterId'
                    defaultMessage="Filter"                    
                />
                </FilterText>
                {
                    countFilters > 0 && !unmissable &&
                    <FilterQuantity>{countFilters}</FilterQuantity>
                }
                </Filter>
            }
        </ContainerFilterAndCategory>
    )
}

export default FilterAndCategory