import { TitleBrand } from './styled';
import { FormattedMessage } from 'react-intl';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import { trackSelectBrand } from 'componentes/BrandsCarousel/track';

function BrandsFilter({ products, selectedBrands, setSelectedBrands, handleFilters, pais }) {
    const handleBrandClick = (brandId) => {
      setSelectedBrands((prevSelectedBrands) => {
        if (prevSelectedBrands.includes(brandId)) {
          return prevSelectedBrands.filter((id) => id !== brandId);
        } else {
          return [...prevSelectedBrands, brandId];
        }
      });
    };

    useEffect(()=> {
      handleFilters('brands_flter', selectedBrands);
    },[selectedBrands])

    const getBrands = products.reduce((element, product) => {
      if (product.brand) {
        element.add(product.brand);
      }
      return element;
    }, new Set());
    
    const parseBrands = Array.from(getBrands);
    const sliceBrands = parseBrands.slice(0, 9);

    return (
      <>
    {sliceBrands.length > 0 && (                  
                      <Grid spacing={12} sx={{mt: '14px', mb: '8px'}}>
                      <TitleBrand>
                        <FormattedMessage
                        id='brandtitleproductid'
                        defaultMessage='Brand'
                        />
                      </TitleBrand>
                    </Grid>
                  )}
                  <Grid container spacing={1}>
                    {sliceBrands?.map((brand) => (
                      <Grid item key={brand.id}>
                        <Chip onClick={() => {
                        trackSelectBrand(pais, brand.id, brand.name, 'filters');
                        handleBrandClick(brand.id)
                        }} label={brand.name} variant="outlined" color="success"             
                        sx={{
                        color: '#001109',
                        fontWeight: '600',
                        backgroundColor: selectedBrands?.includes(brand.id)  ? 'rgba(230, 247, 239, 1)' : 'transparent',
                        }}/>
                      </Grid>
                    ))}
                  </Grid>
                  </>
    );
  }
  
  export default BrandsFilter;