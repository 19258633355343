import { ContainerSliders, SliderCard, SubtitleDirection, ContentWrapper, TitleShop, DeliveryDate, Button, TextButton, ContainerAv, TextAv, ContainerDeliveryDate, ContainerTitle, Distance } from './styled';
import { FormattedDate, FormattedMessage } from 'react-intl';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useRef, useContext } from 'react';
import { Context } from 'context/Context';
import { trackShopChosen } from './tracking'
import { trackShopClicked } from 'componentes/Map/tracking';
import { haversineDistance, distanceShopsToUser } from 'services/helpers';
import { ContainerFreeHomeDelivery } from 'componentes/ListNearestShops/components/styled';

const ShopsSlider = ({ shops, shopIndex, setShopIndex, setCenter, setZoom, type, setSelectedDeliveryPoint, setModalDeliveryPointSelector, nextModal, actualLocation, selectedDeliveryPoint }) => {
    const { setModalDateSelector, pais } = useContext(Context)

    const router = useRouter();
    const ref = useRef();
    const selectedShopRef = useRef(null);

    const handleShopSelection = () => {
        const selectedShop = shops.find((shop, index) => shopIndex === index);
        if (selectedShop) {
          selectedShopRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
        }
      };

     useEffect(() => {
        if(shopIndex !== null){
            handleShopSelection();
        }
    },[shopIndex])


    return(
        <ContainerSliders id='scroll-bar' ref={ref}>
            {shops.map((shop, index)=> {
             const month = shop.next_delivery_dates[0].split(' ')[1];
             const date = shop.next_delivery_dates[0].split(' ')[2];
             const year = shop.next_delivery_dates[0].split(' ')[3];
             const deliveryDate = new Date(`${month} ${date}, ${year}, 12:00:00`);
             const street = shop.address.street;
             const number = shop.address.number;
             const hasDelivery = shop.has_delivery;
             const isSelected = shop.id === selectedDeliveryPoint?.id;
             return (
               <SliderCard
                 key={shop.id.toString() + index}
                 ref={shopIndex === index ? selectedShopRef : null}
                 onClick={() => {
                   trackShopClicked(shop.id, shop.community_leader_id, "card");
                   setCenter({
                     lat: shop.shop_location.Latitude,
                     lng: shop.shop_location.Longitude,
                   });
                   setZoom(15);
                   setShopIndex(index);
                 }}
               >
                <div style={{ display: "flex", flexDirection: "column" }}>
                 <ContainerTitle>
                   <TitleShop>{shop.name}</TitleShop>
                   {actualLocation && (
                     <div style={{ display: "flex", alignItems: "center" }}>
                       <Image
                         src="/imgs/walk-outline.svg"
                         alt="ubicationLogo"
                         width={16}
                         height={16}
                       />
                       <Distance>
                         {distanceShopsToUser(
                           haversineDistance(
                             shop.shop_location,
                             actualLocation
                           ),
                           pais
                         )}
                       </Distance>
                     </div>
                   )}
                 </ContainerTitle>
                 <ContainerAv>
                   <Image
                     src="/imgs/map-pin.svg"
                     alt="ubicationLogo"
                     width={16}
                     height={16}
                   />
                   <TextAv>
                     {street} {number}
                   </TextAv>
                 </ContainerAv>
                 <ContainerDeliveryDate>
                   <DeliveryDate>
                     <FormattedMessage
                       id="youPickUpText"
                       defaultMessage="You pick-up on"
                     />{" "}
                     <FormattedDate value={deliveryDate} weekday="long" />{" "}
                     <FormattedDate
                       value={deliveryDate}
                       month="long"
                       day="numeric"
                     />
                   </DeliveryDate>
                 </ContainerDeliveryDate>
                 {hasDelivery && (
                   <ContainerFreeHomeDelivery>
                     <Image
                       src="/imgs/truck-delivery.svg"
                       alt="Free Home Delivery"
                       width={16}
                       height={16}
                     />
                     <FormattedMessage
                       id="freeHomeDelivery"
                       defaultMessage="Free home delivery"
                     />
                   </ContainerFreeHomeDelivery>
                 )}
                </div>
                 <ContentWrapper></ContentWrapper>
                 <Button
                   selected={isSelected}
                   onClick={() => {
                     if (type === "checkout") {
                       trackShopChosen(shop.id, shop.community_leader_id);
                       setSelectedDeliveryPoint(shop);
                       localStorage.setItem(
                         "selectedDeliveryPoint",
                         JSON.stringify(shop)
                       );
                       setModalDeliveryPointSelector(false);
                       if (nextModal === "deliveryDate") {
                         setModalDateSelector(true);
                       }
                     } else {
                       router.push(`/${shop.slug}`);
                     }
                   }}
                 >
                   <TextButton>
                     {type === "checkout" ? (
                       isSelected ? (
                         <FormattedMessage
                           id="youPickUpHere"
                           defaultMessage="You pick up here"
                         />
                       ) : (
                         <FormattedMessage
                           id="selectThisPoint"
                           defaultMessage="Select this delivery point"
                         />
                       )
                     ) : (
                       <FormattedMessage
                         id="startOrderText"
                         defaultMessage="Start order"
                       />
                     )}
                   </TextButton>
                 </Button>
               </SliderCard>
             ); 
            })}
        </ContainerSliders>
    )
}

export default ShopsSlider