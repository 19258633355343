import {  TitleBold, TextGray } from './styled';
import Slider from '@mui/material/Slider';
import { FormattedMessage } from 'react-intl';
import formatNumber from 'services/formatNumber';
import { useTheme } from 'styled-components';

function SliderFilter({ priceRange, handleFilters, value, setValue }) {
    const theme = useTheme();
    const minDistance = 1;
    const handleChange = (event, newValue) => {
      if (newValue[1] - newValue[0] >= minDistance) {
        setValue(newValue);
        handleFilters('price_filter', { minPrice: newValue[0], maxPrice: newValue[1] });
      }
    };
    let range = `$${formatNumber(value[0])} - $${formatNumber(value[1])}`;
    return (
    <div>
        <TitleBold>
          <FormattedMessage
            id='rangePriceId'
            defaultMessage='Price range'
          />
        </TitleBold>
        <TextGray>
          {range}
        </TextGray>
        <div style={{marginTop:'16px', paddingLeft:'12px', paddingRight:'12px'}}>
            <Slider
            min={priceRange.minPrice}
            max={priceRange.maxPrice}
            getAriaLabel={() => 'Minimum distance'}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            disableSwap
            sx={{color: theme.secondary}}
            mindistance={minDistance} 
            />
        </div>
    </div> 
    );
  }
  
  export default SliderFilter;