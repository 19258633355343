import { defineMessages } from 'react-intl';

export default defineMessages({
  productAdded: {
    id: 'labeLprouctAdded',
    defaultMessage: 'Product added to cart',
  },
  maximum:{
    id:'labelMaximum',
    defaultMessage: 'You have reached the maximum for this product'
  },
  seeCart:{
    id:'seeCartId',
    defaultMessage: 'See cart'
  },
  itemsInCart: {
    id: 'labelItemsInCart',
    defaultMessage: 'You have {unit} in your cart',
  }
});
