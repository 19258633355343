import { ContainerBanner, TextButton, TextBanner, Button, ContainerIlustration, ContainerText } from './styled';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { Context } from 'context/Context';
import SnackBar from 'componentes/SnackBar';
import { trackShareCode } from 'componentes/ReferredComponent/tracking';

function ReferredBanner({ icon, alt, sucursal, setOpenDrawer, confirmPage, drawer}) {
  const [toastReferred, setToastReferred] = useState(false)
  const { user } = useContext(Context)
  const router = useRouter();

  useEffect(() => {
    if(toastReferred) {
        setTimeout(() => {
            setToastReferred(false)
        }, 3000)
    }
  }, [toastReferred])

    const handleClick = () => {
      if(confirmPage){
        if(!user.error) {
          trackShareCode(sucursal, user?.customer_id)
          setToastReferred(true)
        } else {
            router.push(`/${sucursal}/registro?redirect=referred&edit=true`)
        }
      } else {
        router.push(`/${sucursal}/referred`);
      }
      setOpenDrawer ? setOpenDrawer(false) : null;
    }
    return (
      <ContainerBanner onClick={handleClick} confirmPage={confirmPage} drawer={drawer}>
        <ContainerText>
          <TextBanner>
            <FormattedMessage
            id='textShareShopId'
            defaultMessage="Share Mercadito and earn money"                    
            />
            </TextBanner>
        </ContainerText>
        <Button confirmPage={confirmPage}>
          <TextButton>
            {
              confirmPage ? (
                <FormattedMessage
                id='shareCodeButtonId'
                defaultMessage='Receive code'
                />
              ) : (
                <FormattedMessage
                id='labelSeeDetail'
                defaultMessage="See details"                    
                />
              )
            }

          </TextButton>
        </Button>
        <ContainerIlustration>
          <Image
            src={icon}
            alt={alt}
            width={124}
            height={124}
          />
        </ContainerIlustration>
        {
            toastReferred &&
            <SnackBar
                setShowPopUp={setToastReferred}
                title={'El código fue enviado a tu whatsapp'}
            />
        }
      </ContainerBanner>
                         
    );
  }
  
  export default ReferredBanner;