import { useContext, useState, useEffect } from 'react';
import { Context } from 'context/Context';
import { HeaderGreenNotUser, TitleHeader, CircleIcon, Letter } from './styled';
import Image from 'next/image';
import { capitalizeFirstLetter } from 'services/helpers';
import { FormattedMessage } from 'react-intl';
import { getProfilePicture } from 'services/user';

function HeaderDrawerUserNotWallet({customer}) {
    const { 
      setOpenDrawer, profilePicture, router, sucursal
    } = useContext(Context);
  
    const handleCloseDrawer = () => {
      setOpenDrawer(false);
    };

    const firstLetterName = customer?.customer?.name ? customer.customer.name.charAt(0).toUpperCase() : '';
    const firstLetterLastName = customer?.customer?.last_name ? customer.customer.last_name.charAt(0).toUpperCase() : '';

    return (
            <HeaderGreenNotUser>
                <div style={{display: 'flex', alignItems:'center', justifyContent:'flex-end'}}>
                    <div style={{height: '40px', display: 'flex', cursor:'pointer'}}>
                        <Image onClick={handleCloseDrawer} src='/imgs/menu.svg' alt='logo' width={24} height={24}/>
                  </div>
                </div>
            <div style={{display: 'flex', flexDirection:'column'}}>
              {
                profilePicture ? (
                  <CircleIcon onClick={()=> {
                    handleCloseDrawer();
                    router.push(`/${sucursal}/user`);
                  }} style={{ borderRadius: '50%', overflow: 'hidden', position: 'relative' }}>
                    <Image 
                      src={profilePicture} 
                      alt='profilePicture' 
                      layout="fill" 
                      objectFit="cover" 
                      width={140}
                      height={140}
                    />
                  </CircleIcon>
                ) : (
                  <CircleIcon onClick={()=> {
                    handleCloseDrawer();
                    router.push(`/${sucursal}/user`);
                  }}>
                      <Letter>{firstLetterName}{firstLetterLastName}</Letter>
                  </CircleIcon>
                )
              }
              <TitleHeader>
              ¡<FormattedMessage
                  id='textGreetings'
                  defaultMessage="Hi, "                    
                  />
                  {' '}
                  {capitalizeFirstLetter(customer?.customer?.name)}!
              </TitleHeader>
            </div>
            </HeaderGreenNotUser>
    );
  }
  
  export default HeaderDrawerUserNotWallet;