import Image from "next/image"
import { Container, DetailBlock, Close, Text, Title } from "./styled"
import { useState } from "react";
import { FormattedMessage } from "react-intl";

const ModalUnmissablePromotions = ({ setModalUnmissablePromotions }) => {
    const [startY, setStartY] = useState(0);

    const handleTouchStart = (event) => {
        setStartY(event.touches[0].clientY);
      };
      
    const handleTouchMove = (event) => {
        const currentY = event.touches[0].clientY;
        const deltaY = currentY - startY;
        const threshold = 100; // Umbral de desplazamiento para cerrar el modal
    
        if (deltaY > threshold) {
            setModalUnmissablePromotions(null);
        }
    };

    const handleCloseModal = (event) => {
        if (event.target === event.currentTarget) {
            setModalUnmissablePromotions(null);
        }
    };

    return (
        <Container onClick={handleCloseModal} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} >
            <DetailBlock>
                <Close>
                    <Image src='/imgs/closemodal.svg' width={24} height={24} alt='close' onClick={() => setModalUnmissablePromotions(null)} />
                </Close>
                <Title>
                    <FormattedMessage
                        id={`titleUmissablePromotions`}
                        defaultMessage={'Unmissable Promotions'}
                    />
                </Title>
                <Text>
                    <FormattedMessage
                        id={`textUmissablePromotions_1`}
                        defaultMessage={'All products have unit limits indicated in the product description.'}
                    />
                </Text>
                <Text>
                    <FormattedMessage
                        id={`textUmissablePromotions_2`}
                        defaultMessage={'Promotions are valid until the date indicated in the campaign or while supplies last.'}
                    />
                </Text>
                <Text>
                    <FormattedMessage
                        id={`textUmissablePromotions_3`}
                        defaultMessage={'All products sold and distributed by Mercadito are protected by the Mercadito Guarantees (freshness, fair weight and expiration).'}
                    />
                </Text>
            </DetailBlock>
            
        </Container>
    )
}

export default ModalUnmissablePromotions;
