import { FormattedMessage } from 'react-intl';
import { Container, Title, ContainerHeader, ButtonAllProducts, ContainerCarousel, CardEmpty, TextCard, ContainerCardHorizontal, ContainerCarouselHorizontal } from './styled';
import { useContext, useState, useEffect } from 'react';
import { Context } from 'context/Context';
import { finalDiscount, hasDiscount } from 'services/producthelpers';
import CardElementVertical from './CardElementVertical';
import Image from 'next/image';
import { QuantityOfCardsToShowInHome } from 'services/featureflags'
import CardElementHorizontal from './CardElementHorizontal';
import CardSingleHorizontal from './CardSingleHorizontal';
import ModalThemed from 'componentes/ModalThemed';
import { trackViewAllFromCarousel } from './track';


function ProductsCarousel({ title, view, products, unmissable, flashDeals, refetch, type, categoryId }) {
  let { router, sucursal, carrito, deleteProducts, setProductsHighlights, setProductsHighlightsTitle } = useContext(Context);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [modal, setModal] = useState(false);
  const number = 4;
 
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const showButtonSeeAll = products?.length > number;

  const sliceArray = products?.slice(0, number);


  let newArray = [];
  if (view === 'Cart' || view === 'ProductPage') {
    newArray = sliceArray?.filter((element2) => {
      const existsInArray1 = carrito.some((element1) => element1.productid === element2.id);
      const existsInDeleteProducts = deleteProducts.includes(element2.id);
  
      if (existsInArray1 || existsInDeleteProducts) {
        for (const element1 of carrito) {
          if (element1.productid === element2.id) {
            newArray.push(element1.productid);
            break;
          }
        }
      }
  
      return !(existsInArray1 || existsInDeleteProducts);
    });
  }

  const handleClick = (e) => {
    e.preventDefault();
    if(unmissable){
      router.push(`/${sucursal}/products-highlights?unmissable=true`);
    }else if(flashDeals){
      setModal(true);
    }else if(categoryId){
      router.push(`/${sucursal}/${categoryId}`);
    }else{
      router.push(`/${sucursal}/products-highlights`);
    }
    trackViewAllFromCarousel(type)
  };

  return (
  products && (
  <>
  {
    view === 'Home' && (
      <Container unmissable={unmissable} flashDeals={flashDeals}>
      <ContainerHeader>
          <Title unmissable={unmissable} flashDeals={flashDeals}>
          {
              flashDeals &&
              <Image src='/imgs/flash.svg' width={16} height={16} alt='flash' />
            }
            {title}
            {
              unmissable &&
              <Image src='/imgs/fire.svg' width={24} height={24} alt='fire' />
            }
          </Title>
          {
            showButtonSeeAll && (
              <ButtonAllProducts flashDeals={flashDeals} unmissable={unmissable} onClick={(e)=> {
                setProductsHighlights(products);
                setProductsHighlightsTitle(title);
                handleClick(e);
              }}>
                  <FormattedMessage
                  id='seeAllTextId'
                  defaultMessage="See all"                    
                  />
             </ButtonAllProducts>
            )
          }
      </ContainerHeader>
      <ContainerCarousel windowWidth={windowWidth}>
                  {sliceArray?.map((item, i)=> {
                    let percentage = hasDiscount(item) ? finalDiscount(item).toString() : 0;
                    return (
                      <CardElementVertical marginShowEmptyCard={showButtonSeeAll} length={sliceArray.length} percentage={percentage} key={item.id} last={i === sliceArray.length -1} item={item} unmissable={unmissable} flashDeals={flashDeals} lastPromotions refetch={refetch} carouselType={type} />
                    )}
                  )} 
                  {
                    showButtonSeeAll && (
                      <CardEmpty onClick={(e)=> {
                        setProductsHighlights(products);
                        setProductsHighlightsTitle(title);
                        handleClick(e);
                      }}>
                        <TextCard>
                           <FormattedMessage
                           id='seeAllTextId'
                           defaultMessage="See all"                    
                           />
                        </TextCard>
                        <Image src='/imgs/arrow.svg' width={16} height={16} />
                      </CardEmpty>
                    )
                  }
      </ContainerCarousel>
      {modal && <ModalThemed  sucursal={sucursal} modal={modal} setModal={setModal} flashDeals={flashDeals} products={products}/>}
   </Container>
    )
  }
  {
    (view === 'Cart' || view === 'ProductPage') && (
      newArray?.length > 0 &&
      <ContainerCardHorizontal type={type} view={view} >
        <ContainerHeader>
            <Title>{title}</Title>
        </ContainerHeader>
        {newArray?.length === 1 ? (<CardSingleHorizontal item={newArray[0]} deleteProducts={deleteProducts} carouselType={type} />) : (
                  <ContainerCarouselHorizontal windowWidth={windowWidth}>
                  {newArray?.map((item, i)=> {
                          let percentage = hasDiscount(item) ? finalDiscount(item).toString() : 0;
                          return (
                            <CardElementHorizontal last={i === newArray.length -1} length={newArray.length} percentage={percentage} key={item.id}
                            item={item} carouselType={type} />
                          )}
                  )} 
              </ContainerCarouselHorizontal>
        )}
     </ContainerCardHorizontal>
    )
  }
  </>
  )
  );
}

export default ProductsCarousel;
