import { useContext, useState, useEffect } from "react";
import { Context } from "context/Context";
import {
  ModalContainer,
  Modal,
  ModalContent,
  ContainerItems,
  Item,
  ContainerClose,
  CategoryName,
  TextCard,
} from "./styled";
import AlertDeliveryDate from "componentes/AlertDeliveryDate";
import Image from "next/image";
import { capitalizeFirstLetter } from "services/helpers";
import { useIntl } from "react-intl";
import messages from "./messages";
import ModalProductsInCart from "componentes/ModalProductsInCart";
import {
  showDrawerOption,
  showBannerReferred,
  showPaymentMethods,
  featureLogout,
} from "services/featureflags";
import ReferredBanner from "componentes/ReferredBanner";
import HeaderDrawerNotVerified from "componentes/HeaderDrawerNotVerified";
import HeaderDrawerVerifiedNotLogin from "componentes/HeaderDrawerVerifiedNotLogin";
import HeaderDrawerUserNotWallet from "componentes/HeaderDrawerUserNotWallet";
import HeaderDrawerUserWallet from "componentes/HeaderDrawerUserWallet";
import ModalTermsAndConditions from "componentes/TermAndConditions";
import { termsConditionsByCountry } from "componentes/TermAndConditions/helpers";
import { track } from "services/gtm";
import { getUserEligibility } from "services/getUserEligibility";
import useFeatureFlags from "componentes/useFeatureFlags";

function Drawer() {
  const intl = useIntl();
  const [modalWarningCart, setModalWarningCart] = useState(false);
  const [showChangeDirection, setShowChangeDirection] = useState(null);

  const [modal, setModal] = useState(false);
  const [showBanner, setShowBanner] = useState(null);
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  useEffect(() => {
    if (user !== null) {
      setIsUserLoaded(true);
    }
  }, [user]);

  const {
    openDrawer,
    setOpenDrawer,
    sucursal,
    communityLeader,
    router,
    dataShop,
    categories,
    user,
    customer,
    pais,
    walletAmount,
    walletTransactions,
    setRefetchWallet,
    deliveryPointSelector,
    selectedDeliveryPoint,
    setModalDeliveryPointSelector,
    setUser,
    showProductFruits 
  } = useContext(Context);

  const [doTutorial, setDoTutorial] = useState(null);

  useEffect(() => {
    if (!doTutorial)
      setDoTutorial(getUserEligibility(localStorage.getItem("anonymousId")));
  }, [doTutorial]);

  const showPaymentMethodsCountry = showPaymentMethods(pais);

  useEffect(() => {
    showDrawerOption().then((res) => setShowChangeDirection(res));
    showBannerReferred().then((res) => setShowBanner(res));
    setRefetchWallet(true);
  }, []);

  const startTutorial = () => {
    const toursAPI = window.productFruits?.api?.tours;
    if (!toursAPI) return;
    const tours = toursAPI.getTours();
    const tourName = customer ? `Onboarding ${pais === 1 ? "AR" : pais === 2 && "MX"} (Logged in)` : `Onboarding ${pais === 1 ? "AR" : pais === 2 && "MX"} (Not logged in)`;
    const targetTour = tours.find((tour) => tour.name === tourName);
    if (!targetTour) return;
    toursAPI.tryStartTour(targetTour.id);
  };
  

  const menuOptions = [
    {
      id: 4,
      isShow: deliveryPointSelector,
      label: selectedDeliveryPoint
        ? `${intl.formatMessage(messages.labelPickUpDrawer)} ${
            selectedDeliveryPoint?.address?.street
          } ${" "} ${selectedDeliveryPoint?.address?.number} `
        : `${intl.formatMessage(messages.labelSelectDeliveryPoint)}`,
      icon: "/imgs/locationicon.svg",
      navigation: () => {
        setModalDeliveryPointSelector(true);
        setOpenDrawer(false);
      },
      subsection: false,
      divider: true,
    },
    {
      id: 1,
      isShow: true,
      label: intl.formatMessage(messages.labelHome),
      icon: "/imgs/home.svg",
      navigation: () => {
        track("menu_home_click", {
          event: "menu_home_click",
        });
        router.push(`/${sucursal}`);
        setOpenDrawer(false);
      },
      subsection: false,
      divider: true,
    },
    {
      id: 6,
      isShow: true,
      label: intl.formatMessage(messages.labelCategories),
      icon: "/imgs/categories.svg",
      subsection: false,
      divider: false,
      navigation: () => {
        track("menu_categories_click", {
          event: "menu_categories_click",
        });
        router.push(`/${sucursal}/categories`);
        setOpenDrawer(false);
      },
    },
    {
      id: 2,
      isShow: dataShop.verified_purchase,
      label: intl.formatMessage(messages.labelOrders),
      icon: "/imgs/myOrders.svg",
      navigation: () => {
        track("menu_your_orders_click", {
          event: "menu_your_orders_click",
        });
        if (!user) {
          router.push(`/${dataShop.slug}/registro?purchases=true`);
        } else {
          router.push(`/${dataShop.slug}/mis-pedidos`);
        }
        setOpenDrawer(false);
      },
      subsection: false,
      divider: true,
    },
    {
      id: 7,
      isShow: user?.customer_id,
      label: intl.formatMessage(messages.yourDataId),
      icon: "/imgs/userProfile.svg",
      navigation: () => {
        router.push(`/${dataShop.slug}/user`);
        setOpenDrawer(false);
      },
      subsection: false,
      divider: true,
    },
    {
      id: 8,
      isShow: true && showPaymentMethodsCountry,
      label: intl.formatMessage(messages.aboutMercaditoId),
      icon: "/imgs/info.svg",
      navigation: () => {
        track("menu_about_mercadito_click", {
          event: "menu_about_mercadito_click",
        });
        router.push(`/${dataShop.slug}/about`);
        setOpenDrawer(false);
      },
    },
    {
      id: 9,
      isShow: deliveryPointSelector,
      label: intl.formatMessage(messages.joinMercaditoId),
      icon: "/imgs/plusNavIcon.svg",
      navigation: () => {
        router.push(`/${dataShop.slug}/sumate`);
        setOpenDrawer(false);
      },
    },
    {
      id: 10,
      isShow: doTutorial && showProductFruits,
      label: "Tutorial",
      /* icon: "", */
      navigation: () => {
        startTutorial();
        setOpenDrawer(false);
      },
      subsection: false,
      divider: true,
    },
    {
      id: 3,
      isShow: true && showPaymentMethodsCountry,
      label: intl.formatMessage(messages.labelInquiries),
      icon: "/imgs/icon_whatsapp.svg",
      navigation: () => {
        window.open(`https://wa.me/${communityLeader?.phone}`, "_blank");
        setOpenDrawer(false);
      },
      subsection: false,
      divider: true,
    },
    {
      id: 5,
      isShow: showBanner && showPaymentMethodsCountry,
      icon: "/imgs/ilustrationDrawer.svg",
      subsection: false,
      divider: false,
    },
  ];

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleCloseModal = (event) => {
    if (event.target === event.currentTarget) {
      setOpenDrawer(false);
    }
  };

  const handleClickCategory = (category_id) => {
    router.push(`/${sucursal}/${category_id}`);
    setOpenDrawer(false);
  };

  const inswitch_wallet_id = customer
    ? customer.customer.inswitch_wallet_id
    : null;

  const verifiedPurchase = dataShop?.verified_purchase;
  const notVerifiedPurchase = !dataShop?.verified_purchase;
  const hasWallet = inswitch_wallet_id;

  const userWithError = user && user.error;
  const userToRender = userWithError ? null : user;
  return (
    <>
      {openDrawer && showBanner !== null && (
        <ModalContainer>
          <AlertDeliveryDate confirmationPage={false} />
          <ContainerClose>
            <Image
              onClick={handleCloseDrawer}
              src="/imgs/closeIcon.svg"
              width={32}
              height={32}
            />
          </ContainerClose>
          <Modal isOpen={openDrawer} onClick={handleCloseModal}>
            <ModalContent>
              {notVerifiedPurchase && <HeaderDrawerNotVerified />}

              {isUserLoaded && verifiedPurchase && !user && (
                <HeaderDrawerVerifiedNotLogin />
              )}

              {verifiedPurchase && userToRender && !hasWallet && (
                <HeaderDrawerUserNotWallet user={user} customer={customer} />
              )}

              {userToRender === null && <HeaderDrawerVerifiedNotLogin />}

              {verifiedPurchase &&
                userToRender &&
                hasWallet &&
                walletTransactions?.length > 0 && (
                  <HeaderDrawerUserWallet
                    user={user}
                    customer={customer}
                    walletAmount={walletAmount}
                  />
                )}

              {verifiedPurchase &&
                userToRender &&
                hasWallet &&
                !walletTransactions?.length && (
                  <HeaderDrawerUserNotWallet user={user} customer={customer} />
                )}
              {menuOptions.map((option) => {
                return (
                  option.isShow && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "auto",
                        padding: "0px 16px",
                      }}
                      key={option.id}
                    >
                      {option.id === 5 ? (
                        <ReferredBanner
                          drawer
                          icon={option.icon}
                          alt={option.label}
                          sucursal={sucursal}
                          setOpenDrawer={setOpenDrawer}
                        />
                      ) : (
                        <ContainerItems
                          divider={option.divider}
                          onClick={option.navigation && option.navigation}
                        >
                          {option.icon && (
                            <Image
                              src={option.icon}
                              alt={option.label}
                              width={16}
                              height={16}
                            />
                          )}
                          <Item elipsis={option.id === 4}>{option.label}</Item>
                        </ContainerItems>
                      )}
                      {option.subsection && (
                        <>
                          {categories?.map((category, i) => {
                            return (
                              <div
                                onClick={() => handleClickCategory(category.id)}
                                style={{
                                  paddingLeft: "40px",
                                  minHeight: "58px",
                                  height: "auto",
                                  marginTop: i === 0 && "15px",
                                }}
                                key={i}
                              >
                                <CategoryName>
                                  {capitalizeFirstLetter(category.name)}
                                </CategoryName>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  )
                );
              })}
              {termsConditionsByCountry[pais].length > 1 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 16px",
                  }}
                >
                  <TextCard onClick={() => setModal(true)} noBold>
                    Términos y condiciones
                  </TextCard>
                </div>
              )}
            </ModalContent>
          </Modal>
        </ModalContainer>
      )}
      {modal && (
        <ModalTermsAndConditions
          modal={modal}
          setModal={setModal}
          country={pais}
        />
      )}
      {modalWarningCart && (
        <ModalProductsInCart
          setModalWarningCart={setModalWarningCart}
          country={pais}
          setOpenDrawer={setOpenDrawer}
        />
      )}
    </>
  );
}

export default Drawer;
