import { Container, ContainerClose, TermTitleReferred, HowDoesItWork, TextBlock1, TextBlock2, Ul, TermTitle, ContainerButton, Button, ButtonText, ListItem } from './styled';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';
import { termsConditionsByCountry } from './helpers';

function ModalTermsAndConditions({setModal, modal, country}) {
    const terms = termsConditionsByCountry[country];
  return (
    modal && (
    <Container>
        <ContainerClose>
            <Image src='/imgs/closemodal.svg' width={24} height={24} alt='close' onClick={()=> setModal(!modal) } />
        </ContainerClose>

        <TextBlock2>
        {terms}
        </TextBlock2>
        <ContainerButton  onClick={()=> setModal(!modal) } >
            <Button>
                <ButtonText>
                <FormattedMessage
                id='termaAndCondition20'
                defaultMessage="Close"
                />
                </ButtonText>
            </Button>
        </ContainerButton>
    </Container>
    )
  );
}

export default ModalTermsAndConditions;