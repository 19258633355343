import Image from "next/image"
import { Container2, TextContainer, Text2, StampImage } from "./styled"
import { FormattedMessage } from "react-intl"

const CashBackGuarantee = ({ setModalGuarantee, country }) => {
    const textByCountry = country === 1 ? 'Te devolvemos tu dinero si un producto no es de tu satisfacción. Así de rápido y seguro.' : 'Te regresamos tu dinero si un producto no es de tu satisfacción. Así de rápido y seguro.';
    const guarantee = {
        id: 1,
        image: '/imgs/devolucion.png',
        description: textByCountry
    }
    return (
        <Container2>
            <StampImage>
                <Image src={guarantee.image} width={50} height={50} alt='image' />
            </StampImage>
            <TextContainer>
                <Text2>
                <FormattedMessage
                    id={guarantee.id}
                    defaultMessage={guarantee.description}
                />
                {' '}
                <span onClick={() => setModalGuarantee(true)}>
                    <u>
                    <FormattedMessage
                        id="guaranteeStamp.moreInfo"
                        defaultMessage="More info"
                    />
                    </u>
                </span>
                </Text2>
            </TextContainer>
        </Container2>
    )
}

export default CashBackGuarantee;
