import { track } from 'services/gtm';

export function trackShopClicked(shopId, communityLeaderId, type) {
  track('shop_clicked', {
    ecommerce: {
      view: 'shop_clicked',
      shop_id: shopId,
      community_leader_id: communityLeaderId,
      type,
    }
  });
}

export function notFoundNearestShop(name, lastName, phone, latitude, longitude) {
  track('not_found_nearest_shop', {
    ecommerce: {
      view: 'not_found_nearest_shop',
      name: name ?? '',
      last_name: lastName ?? '',
      phone: phone ?? '',
      latitude,
      longitude
    }
  });
}
