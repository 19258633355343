import styled, { css }  from 'styled-components'

export const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
min-height: 100vh;
align-items: center;
height: 100%;
`

export const Header = styled.div`
padding: 0px 16px;
width: 100%;
display: flex;
background-color: ${props => props.theme.secondary};
height: 64px;
align-items: center;
z-index: 2;
`
export const TitlePageSection = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
padding: 24px 0px;
`

export const ContainerSection2 = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 24px 0px;
`

export const ContainerContent = styled.div`
display: flex;
flex-direction: column;
padding: ${props => props.deliveryPointSelector ? '170px 24px 0px 24px': '32px 24px 0px 24px'};
align-items: center;
`

export const Title = styled.h1`
color: ${props => props.theme.textDefault};
font-size: 20px;
font-family: sans-serif;
font-weight: 600;
text-align: center;
`

export const Subtitle = styled.p`
color: ${props => props.theme.textDefault};
font-size: 14px;
font-family: sans-serif;
font-weight: 400;
text-align: center;
margin: 16px 0px;
line-height: 20px;
padding: 0px 16px;
`

export const FontBold = styled.h1`
color: ${props => props.theme.textDefault};
font-size: 16px;
font-family: sans-serif;
font-weight: 700;
text-align: center;
margin: 16px 0px 0px 0px;
padding: 0px 16px;
`

export const ContainerButton = styled.div`
width: 100%;
padding: 24px 0px;
display: flex;
justify-content: center;
align-items: center;
`

export const Button = styled.div`
width: 100%;
background-color: ${props => props.theme.primary};
padding: 10px 0px;
border-radius: 24px;
display: flex;
justify-content: center;
align-items: center;
&:active {
  opacity: 0.7;
}
cursor: pointer;
`

export const ButtonText = styled.p`
color: white;
font-size: 20px;
font-family: sans-serif;
font-weight: 600;
text-align: center;
`

export const Elipse = styled.div`
background-color: ${props => props.theme.information};
border-radius: 100%;
width: 32px;
height: 32px;
display: flex;
justify-content: center;
align-items: center;
`

export const ElipseText = styled.p`
color: white;
font-size: 16px;
font-family: sans-serif;
font-weight: 600;
`

export const ContainerElipse = styled.div`
padding: 16px 8px 24px 8px;
display: flex;
justify-content: flex-start;
width: 100%;
align-items: center;
`

export const Footer = styled.div`
background-color: ${props => props.theme.secondary};
width: 100%;
padding: 32px 24px 24px 24px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
`


export const FontBold2 = styled.h1`
color: ${props => props.theme.textDefault};
font-size: 16px;
font-family: sans-serif;
font-weight: 700;
text-align: start;
width: 152px;
line-height: 20px;
margin: 0px 16px;
`

export const TextFooter = styled.p`
color: white;
font-size: 16px;
font-family: sans-serif;
font-weight: 600;
margin-bottom: 8px;
&:active {
  opacity: 0.7;
}
cursor: pointer;
`

export const ContainerOptions = styled.div`
height: 80px;
display: flex;
flex-direction: column;
justify-content: center;
`

export const Spacer = styled.div`
height: 5px;
width: 100%;
`