import { useContext } from 'react';
import { Context } from 'context/Context';
import { ContainerMessage, TextMessage, Strong } from './styled'
import { FormattedMessage } from 'react-intl';
import { dateSelector } from 'services/featureflags';

function AlertDeliveryDate({confirmationPage}) {
  let { fechaEntrega, isCatalogSG, dataShop } = useContext(Context);
  const dateSelectorFeature = dateSelector(dataShop?.country_id);
  if(!dateSelectorFeature) return (
    fechaEntrega !== null && !isCatalogSG && (
     <> 
      <ContainerMessage>
        <TextMessage>
          {!confirmationPage && 
            <FormattedMessage
              id='textAlertDeliveryDate'
              defaultMessage="Order today,"
            />
          }{' '}{
            <FormattedMessage
              id='textAlertPickUpDeliveryDate'
              defaultMessage="pick up"
            />
          }{' '}
          <Strong>{
              <FormattedMessage
                id={`date${fechaEntrega.date.charAt(0).toUpperCase() + fechaEntrega?.date.slice(1)}`}
                defaultMessage={fechaEntrega.date.toUpperCase()}
              />
            }{' '}{fechaEntrega.day}</Strong>
        </TextMessage>
      </ContainerMessage>
      </>
    )
  );
  return <></>
}

export default AlertDeliveryDate;
