import styled, { keyframes }  from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
position: relative;
padding-bottom: 90px;
`;

export const ContainerImage = styled.div`
width: 100%;
`;

export const ShareButtonComponent = styled.button`
display: flex;
align-items: center;
gap: 4px;
justify-content: flex-end;
border: 0;
background-color: transparent;
color: #00904B;
font-family: sans-serif;
font-size: 14px;
font-weight: 800;
padding-right: 16px;
margin-top: 16px;
`;

export const Text = styled.p`
color: #344A3E;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; 
`

export const ContainerInfo = styled.p`
padding: 0px 16px;
`

export const Title = styled.p`
color: ${props => props.theme.textDefault};
text-align: center;
font-family: sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 26px;
margin-top: 12px;
`

export const Disclaimer = styled.p`
color: #A6A9AD;
text-align: center;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 18px;
margin-top: 4px;
`

export const Description = styled.p`
color: #646970;
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px;
margin-top: 12px;
`

export const ListContainer = styled.ul`
  columns: 1; 
  list-style-position: inside;
  padding: 0;
  margin: 8px 0px 0px;
`;

export const ListItem = styled.li`
  margin-bottom: 8px;
  font-size: 14px;
  white-space: normal;
  list-style-type: disc;
  list-style-position: inside;
  color: #011D0E;
  font-family: sans-serif;
`;

export const ContainerButton = styled.div`
position: fixed;
bottom: 0;
display: flex;
width: 100%;
max-width: 490px;
padding: 24px 16px 32px 16px;
justify-content: center;
align-items: center;
gap: 16px;
border-top: 1px solid #F2F3F3;
background: #FFF;
box-shadow: 0px 4px 10px 0px rgba(14, 0, 55, 0.03);
`

export const Button = styled.div`
display: flex;
height: 56px;
width: 100%;
padding: 14px 8px;
justify-content: center;
align-items: center;
gap: 16px;
flex: 1 0 0;
border-radius: 6px;
background: ${props => props.theme.primary};
&:active {
  opacity: 0.7;
}
${props => props.disabled && "opacity: 0.7"};
`

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const ContainerModal = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color:rgb(48, 54, 64, 0.7);
height: 100vh;
width: 100vw;
position: fixed;
top: 0;
z-index: 11;
max-width: 490px;
`

export const DetailBlock = styled.div`
height: auto;
width: 90vw;
background: #FFF;
position: relative;
animation: ${slideIn} 0.5s ease-in-out forwards;
padding: 12px 24px 24px;
max-width: 490px;
border-radius: 8px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`

export const Close = styled.div`
display: flex;
justify-content: flex-end;
position: ${props => props.modalReport && 'fixed'};
width: 100%;
z-index: ${props => props.modalReport && 9};
background: ${props => props.modalReport && 'white'};
`

export const Input = styled.input`
height: 56px;
border-radius: 8px;
border: 0.5px solid #303640;
font-size: 28px;
font-weight: 800;
width: 158px;
display: flex;
justify-content: center;
text-align: center;
margin-top: 16px;
margin-bottom: 16px;
${props => props.disabled && "opacity: 0.7"};
&:focus {
  border: 1px solid #303640;
  outline: none; 
}
`;

export const Subtitle = styled.p`
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 18px;
text-align: left;
margin-top: 16px;
`

export const ContainerButtons = styled.div`
display: flex;
justify-content: center;
width: 100%;
padding: 16px;
background-color: ${props => props.outlined ? 'white': props.theme.primary};
border: ${props => props.outlined && '1px solid #303640'};
border-radius: 6px;
margin-top: 24px;
`

export const ButtonText = styled.div`
color: ${props => props.outlined ? '#303640':'white'};
text-align: center;
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 800;
line-height: 24px;
`

export const Location = styled.p`
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 13px;
`