import { useContext } from "react";
import { Context } from "context/Context";
import {
  HeaderGreenNotUser,
  Button,
  ContainerButton,
  TextButton,
} from "./styled";
import Image from "next/image";
import { capitalizeFirstLetter } from "services/helpers";
import {
  NameOfShop,
  ContainerName,
  ContainerIconAndLogo,
  ContainerLogos,
  ContainerImgLogo,
} from "componentes/DrawerNavigation/styled";
import { FormattedMessage } from "react-intl";
import { trackLogIn } from "./tracking";
import {
  getLogoByCountryId,
  getBrandNameByCountryId,
} from "services/getLogosByCountryId";
import { get } from "lodash";

function HeaderDrawerVerifiedNotLogin() {
  const { setOpenDrawer, dataShop, router, sucursal, pais } = useContext(Context);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <HeaderGreenNotUser>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ContainerIconAndLogo>
          <ContainerLogos
            isCheckout
            onClick={() => router.push(`/${sucursal}`)}
          >
            {getLogoByCountryId(pais)}
            <ContainerName>
              {getBrandNameByCountryId(pais)}
              <NameOfShop>{`${capitalizeFirstLetter(
                dataShop.name
              )}`}</NameOfShop>
            </ContainerName>
          </ContainerLogos>
        </ContainerIconAndLogo>
        <div style={{ height: "40px", display: "flex", cursor: "pointer" }}>
          <Image
            onClick={handleCloseDrawer}
            src="/imgs/menu.svg"
            alt="logo"
            width={24}
            height={24}
          />
        </div>
      </div>
      <ContainerButton>
        <Button
          onClick={() => {
            trackLogIn();
            router.push(`/${dataShop.slug}/registro?login=true?edit=true`);
          }}
        >
          <TextButton>
            <FormattedMessage id="textGetInto" defaultMessage="Log in" />
          </TextButton>
        </Button>
      </ContainerButton>
    </HeaderGreenNotUser>
  );
}

export default HeaderDrawerVerifiedNotLogin;
