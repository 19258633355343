import styled, { keyframes } from 'styled-components';

export const ContainerBanner = styled.div`
margin-top: ${props => props.drawer && '0px'};
margin-top: ${props => props.confirmPage && '16px'};
display: flex;
justify-content: center;
margin-bottom: ${props => props.drawer ? '8px':'26px'};
width: 100%;
height: 125px;
position: relative;
border-radius: 8px;
overflow: hidden;
background: ${props => props.theme.primaryLighter};
cursor: pointer;
&:active{
  opacity: 0.8;
};
`

export const ContainerIlustration = styled.div`
position: absolute;
right: -6px;
`

export const ContainerText = styled.div`
top: 16px;
left: 16px;
position: absolute;
`

export const TextButton = styled.p`
color: #FFF;
text-align: center;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 800;
line-height: 14px;
letter-spacing: 0.14px;
`

export const TextBanner = styled.p`
color: #000;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px;
width: ${props => props.confirmPage ? '150px' : '136px'} 
`

export const Button = styled.div`
position: absolute;
left: 16px;
bottom: 16px;
width: 125px;
padding: 0px 24px;
display: flex;
justify-content: center;
border-radius: 24px;
background: ${props => props.theme.primary};
height: 36px;
align-items: center;
width: ${props => props.confirmPage ? '150px' : '136px'} 
`