import CountryNavBar from "componentes/CountryNavBar";
import Map from "componentes/Map";
import MapForm from "componentes/MapForm";
import { useEffect, useState, useContext } from "react";
import { countryCode, locationByCountryCode } from "services/helpers";
import { ContainerCountry } from "./styled";
import { Header } from "componentes/LandingCountry/styled";
import Image from "next/image";
import {
    useJsApiLoader
  } from "@react-google-maps/api";
import getShopsByBoundaries from "services/getshopsbyboundaries";
import { Context } from 'context/Context';
import ModalActivateLocation from "componentes/ModalActivateLocation";
import ListnearestShops from "componentes/ListNearestShops";
import getPickupPoint from "services/getpickuppoint";

const libraries = ['places'];

const Country = ({ country, type, setSelectedDeliveryPoint, setModalDeliveryPointSelector, nextModal, isList, setIsList }) => {
    const { actualLocation, sharedLocation, nearestShops, selectedDeliveryPoint, setActualLocation, setNearestShops, pais } = useContext(Context);
    const location = locationByCountryCode[country]; 
    const countryName = Object.keys(countryCode).find(key => countryCode[key] === country);
    const [thisMap, setThisMap] = useState(null);
    const [newActualLocation, setNewActualLocation] = useState(actualLocation);
    const [newNearestShops, setNewNearestShops] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [visibleArea, setVisibleArea] = useState(null);
    const [boundaries, setBoundaries] = useState(null);
    const [shopsInBound, setShopsInBound] = useState(null);
    const [buttonActived, setButtonActived] = useState(false);
    const [actualLocationActivated, setActualLocationActivated] = useState(false);
    const [center, setCenter] = useState({ lat: location.lat, lng: location.lng });
    const [autocompleteAddress, setAutocompleteAddress] = useState(null);
    const [modal, setModal] = useState(false);
    const [zoom, setZoom] = useState(15);
    const [refreshMap, setRefreshMap] = useState(false);
    const [showTextError, setShowTextError] = useState(false);
    const extraDistance = 0.12;
    

    const bounds = thisMap?.getBounds() || null;

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
        libraries
      });

    const [autocomplete, setAutocomplete] = useState(null);

    const onLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    };

    const onLocationChanged = () => {
      if (autocomplete !== null) {
          const place = autocomplete.getPlace();
          if (place && place.geometry) {
              setActualLocationActivated(true);
              setNewActualLocation({
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
                  zoom: 15,
              });
              setSearchValue('');
          } else {
            setShowTextError(true);
          }
      } else {
          console.log("Autocomplete is not loaded yet!");
      }
  };
  

    useEffect(()=> {
      if(newActualLocation && pais){
        getPickupPoint(pais, newActualLocation.lat, newActualLocation.lng, 10000).then((res)=> {
          setNewNearestShops(res)
        })
      }
    },[newActualLocation, pais]);

    useEffect(() => {
      if(autocomplete?.getPlace()?.formatted_address) {
        setAutocompleteAddress(autocomplete.getPlace().formatted_address)
      }
    }, [autocomplete?.getPlace()?.formatted_address])

    useEffect(() => {
        if(newActualLocation || refreshMap) {
          setCenter({ lat: newActualLocation.lat, lng: newActualLocation.lng })
          setZoom(newActualLocation.zoom)
          setVisibleArea({
            north: newActualLocation.lat + 0.04,
            south: newActualLocation.lat - 0.04,
            east: newActualLocation.lng + 0.03,
            west: newActualLocation.lng - 0.03
          })
          setBoundaries({
            boundaries: {
              west_north: { latitude: newActualLocation.lat + 0.04, longitude: newActualLocation.lng - 0.03 },
              east_north: { latitude: newActualLocation.lat + 0.04, longitude: newActualLocation.lng + 0.03 },
              west_south: { latitude: newActualLocation.lat - 0.04, longitude: newActualLocation.lng - 0.03 },
              east_south: { latitude: newActualLocation.lat - 0.04, longitude: newActualLocation.lng + 0.03 }
            },
            user_location: { latitude: newActualLocation.lat, longitude: newActualLocation.lng },
          })
          setRefreshMap(false)
        }
      }, [newActualLocation, refreshMap])

    useEffect(() => {
        if(boundaries) {
          getShopsByBoundaries(countryName, boundaries)
          .then(res => {
            !res.error ?
            setShopsInBound(res) :
            setShopsInBound([])
          })
        }
    }, [boundaries])

    useEffect(() => {
        if(visibleArea && !actualLocationActivated) {
          setBoundaries({
            boundaries: {
              west_north: { latitude: visibleArea.north, longitude: visibleArea.west },
              east_north: { latitude: visibleArea.north, longitude: visibleArea.east },
              west_south: { latitude: visibleArea.south, longitude: visibleArea.west },
              east_south: { latitude: visibleArea.south, longitude: visibleArea.east }
            },
            user_location: { latitude: newActualLocation?.lat, longitude: newActualLocation?.lng },
          })
        }
        setActualLocationActivated(false)
    }, [visibleArea])

    const changeVisibleArea = () => {
        if(bounds) {
          const ne = bounds.getNorthEast();
          const sw = bounds.getSouthWest();
          setVisibleArea({
            north: ne.lat() + extraDistance,
            south: sw.lat() - extraDistance,
            east: ne.lng() + extraDistance,
            west: sw.lng() - extraDistance,
          })
        }
    }

   const sortedData = newNearestShops?.length ? newNearestShops?.sort((a, b) => a.distance_to_user - b.distance_to_user) : newNearestShops?.sort((a, b) => a.distance_to_user - b.distance_to_user);

    if(isLoaded) return(
        <ContainerCountry type={type}>
          {
            type !== 'checkout' &&
            <Header>
                 <Image src='/imgs/logoComplete.svg' width={148} height={48} alt='logo' />
            </Header>
          }
            <MapForm setModal={setModal} onLoad={onLoad} onLocationChanged={onLocationChanged} setNewActualLocation={setNewActualLocation} searchValue={searchValue} setSearchValue={setSearchValue} setActualLocationActivated={setActualLocationActivated} autocompleteAddress={autocompleteAddress} setAutocompleteAddress={setAutocompleteAddress} type={type} nextModal={nextModal} sharedLocation={sharedLocation} isList={isList} nearestShops={sortedData} showTextError={showTextError} setShowTextError={setShowTextError}/>
            {
              isList ?
              <ListnearestShops country={country} center={center} newActualLocation={newActualLocation} nearestShops={sortedData} selectedDeliveryPoint={selectedDeliveryPoint} setSelectedDeliveryPoint={setSelectedDeliveryPoint} setModalDeliveryPointSelector={setModalDeliveryPointSelector} nextModal={nextModal} setIsList={setIsList} actualLocation={newActualLocation} setShopsInBound={setShopsInBound} setCenter={setCenter} setRefreshMap={setRefreshMap} setNewActualLocation={setNewActualLocation} /> :
              <Map location={location} actualLocation={newActualLocation} visibleArea={visibleArea} setVisibleArea={setVisibleArea} boundaries={boundaries} shopsInBound={shopsInBound} setButtonActived={setButtonActived} extraDistance={extraDistance} changeVisibleArea={changeVisibleArea} setThisMap={setThisMap} thisMap={thisMap} center={center} setCenter={setCenter} zoom={zoom} setZoom={setZoom} actualLocationActivated={actualLocationActivated} setActualLocationActivated={setActualLocationActivated} type={type} setSelectedDeliveryPoint={setSelectedDeliveryPoint} setModalDeliveryPointSelector={setModalDeliveryPointSelector} nextModal={nextModal} setIsList={setIsList} selectedDeliveryPoint={selectedDeliveryPoint} />
            }

            {modal && <ModalActivateLocation openModal={modal} setOpenModal={setModal}/>}
        </ContainerCountry>
    )
    return <></>
}
export default Country;