import Image from "next/image";
import { ContainerImgLogo } from "componentes/DrawerNavigation/styled";
import { useContext } from "react";
import { Context } from "context/Context";
import { imagesByCountryId, imageSizeByCountryId, DEFAULT_THEME_IMAGE_DATA, DEFAULT_THEME_SIZE_DATA } from 'services/helpers';

export const getLogoByCountryId = (countryId) => {
  const { sucursal, router } = useContext(Context);
  const countryData = imagesByCountryId[countryId] || DEFAULT_THEME_IMAGE_DATA;

  return (
    <ContainerImgLogo onClick={() => router.push(`/${sucursal}`)}>
      <Image
        alt={countryData.alt}
        src={countryData.logo}
        width={40}
        height={40}
        style={{
          cursor: "pointer",
          minWidth: "40px",
          minHeight: "40px",
        }}
      />
    </ContainerImgLogo>
  );
};

export const getBrandNameByCountryId = (countryId) => {
  const countryData = imagesByCountryId[countryId] || DEFAULT_THEME_IMAGE_DATA;
  const sizeData = imageSizeByCountryId[countryId] || DEFAULT_THEME_SIZE_DATA;

  return (
    <Image
      alt={countryData.alt}
      width={sizeData.width}
      height={sizeData.height}
      src={countryData.logoText}
    />
  );
};
