import { track } from 'services/gtm';

export function trackShopClicked(shopId, communityLeaderId, type) {
  track('shop_clicked', {
    ecommerce: {
      view: 'shop_clicked',
      shop_id: shopId,
      community_leader_id: communityLeaderId,
      type,
    }
  });
}
