import Image from "next/image"
import { Container, DetailBlock, Close, DateSelector, Title } from "./styled"
import Country from "componentes/Country";
import { useEffect, useState } from "react";
import { trackMapOpen } from './tracking';
import useDetectOS from "componentes/useDetectOS";
import { countryCode } from "services/helpers";

const ModalDeliveryPointSelector = ({ setModalDeliveryPointSelector, setSelectedDeliveryPoint, nextModal, country }) => {
    const [isList, setIsList] = useState(true);

    const handleCloseModal = (event) => {
        if (event.target === event.currentTarget) {
            setModalDeliveryPointSelector(null);
        }
    };

    useEffect(()=> {
        trackMapOpen();
    },[]);
    
    return (
        <Container onClick={handleCloseModal}>
            <DetailBlock>
                <div>
                    <Close>
                        <Image src='/imgs/closemodal.svg' width={24} height={24} alt='close' onClick={() => setModalDeliveryPointSelector(null)} />
                    </Close>
                    <DateSelector>
                        <Country country={countryCode[country]} type={'checkout'} nextModal={nextModal} setSelectedDeliveryPoint={setSelectedDeliveryPoint} setModalDeliveryPointSelector={setModalDeliveryPointSelector} isList={isList} setIsList={setIsList} />
                    </DateSelector>
                </div>
            </DetailBlock>
        </Container>
    )
    return <></>
}

export default ModalDeliveryPointSelector;
