import { Container, ContainerButton, TextSearch, ContainerButtonSg, TextButtonSg } from './styled'
import { useContext } from 'react';
import { Context } from 'context/Context';
import { useRouter } from "next/router";
import Image from 'next/image';
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage } from 'react-intl';

function SearchButton({SG, setIsActiveSearch}) {
  let { sucursal } = useContext(Context);
  const router = useRouter();

  return (
    <>
    {SG ? (
    <Container>
      <ContainerButtonSg onClick={()=> { router.push(`/${sucursal}/search`) }}>
      <SearchIcon sx={{ color: "green", fontSize: "24px" }} size="small" />
        <TextButtonSg>
        <FormattedMessage
          id='textbysearch'
          defaultMessage="Search for groceries"
        />
        </TextButtonSg>
      </ContainerButtonSg>
    </Container>
    ) : (
        <Image onClick={()=> {
        setIsActiveSearch(true);
        }} src="/imgs/search-white.svg" alt='searchIcon' width={24} height={24} />
    )}

  </>
  )
}

export default SearchButton;