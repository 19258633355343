import { useContext } from 'react';
import { Context } from 'context/Context';
import { ContainerButton, TextButton, Button } from './styled'
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';

function ButtonSolid({text, action, fullWidth, defaultMessage, id, disabled}) {
  return (
     <ContainerButton> 
         <Button fullWidth={fullWidth ?? false} disabled={disabled} onClick={action}> 
                <TextButton>
                    {
                        id && defaultMessage ?
                        <FormattedMessage
                            id={id}
                            defaultMessage={defaultMessage}
                        />
                        :
                        text
                    }
                </TextButton>
         </Button>
     </ContainerButton>
 )
 }

export default ButtonSolid;
    