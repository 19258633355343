import styled, { keyframes, css } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`;

export const Container = styled.div`
max-height: 100%;
overflow-y: auto;
position: fixed;
background: white;
top: 0px;
width: 100%;
max-width: 490px;
z-index: 11;
animation: ${fadeIn} 0.5s ease;
${props => props.flashDeals && css`
    background: ${props.theme.warning};
`};
`

export const ElementFix = styled.div`
position: fixed;
top: 0px;
z-index: 11;
width: 100%;
padding-bottom: 30px;
max-width: 490px;
${props => props.flashDeals && css`
    background: ${props.theme.warning};
    background-image: url('/imgs/bg_pattern_flash.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 150px;
`};
`


export const ContainerClose = styled.div`
width: 100%;
padding: 20px 0px 20px 16px;
&:active{
  opacity: 0.6;
};
cursor: pointer;
`

export const ContainerTitleAndImage = styled.div`
padding: 0px 78px 0px 24px;
display: flex;
justify-content: flex-start;
`

export const TermTitleReferred = styled.p`
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px;
margin-left: 3px;
`