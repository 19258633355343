import styled from 'styled-components';

export const Container = styled.div`
width: 100%;
height: ${({type}) => type === 'checkout' ? '70vh' : '100%'};
`

export const SearchArea = styled.div`
display: flex;
justify-content: center;
width: 100%;
`
export const SearchButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 250px;
height: 42px;
border-radius: 8px;
background: ${props => props.theme.information};
box-shadow: 0px 1px 14px 0px rgba(52, 74, 62, 0.30);
color: ${props => props.theme.white};
text-align: center;
font-size: 16px;
font-family: Roboto;
font-style: normal;
font-weight: 800;
line-height: 20px;
position: absolute;
top: 235px;
z-index: 7;
`
export const ViewList = styled.div`
display: flex;
padding: 8px 10px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 4px;
border: 1px solid #303640;
background: #F6F6F7;
position: absolute;
top: 235px;
left: 10px;
z-index: 7;
color: var(--Fill-Dark-contrast, #303640);
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 14px;
`

export const EmptyCard = styled.ul`
overflow-x: scroll;
overflow-y: hidden;
transform-origin: top left;
white-space: nowrap;
::-webkit-scrollbar {
  display: none;
}
width: 100%;
max-width: 490px;
position: fixed;
bottom: 0;
scroll-snap-type: x mandatory;
padding: 16px;
display: flex;
gap: 8px;
`

export const Card = styled.div`
border-radius: 8px;
background: white;
box-shadow: 0px 1px 14px 0px rgba(52, 74, 62, 0.30);
display: flex;
padding: 16px;
justify-content: space-between;
min-width: 100%;
align-items: center;
`;

export const TitleCard = styled.p`
color: ${props => props.theme.textDefault};
font-size: 14px;
font-family: sans-serif;
font-style: normal;
font-weight: 600;
line-height: 24px;
`;

export const SubtitleCard = styled.p`
color: ${props => props.theme.textDefault};
font-size: 14px;
font-family: sans-serif;
font-style: normal;
font-weight: 400;
line-height: 24px;
`;

export const ContainerText = styled.div`
display: flex;
width: 80%;
flex-direction: column;
`;