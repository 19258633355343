import styled, { css }  from 'styled-components'
import Image from 'next/image';

export const Container = styled.div`
position: relative;
padding-bottom: 150px;
background: #fff;
`
export const ContainerVerification = styled.div`
position: relative;
width: 100%;
margin: 8px 0;
`

export const ContainerHeader = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
padding: ${props => props.showDateSelector && !props.deliveryPointSelector ? '122px 0 10px 0': '160px 0 10px 0'};
`

export const ContainerImageGallery = styled.div`
display: flex;
align-items: flex-start;
justify-content: center;
padding: 20px 16px 0px 16px;
`

export const ContainerDiscount = styled.div`
display: flex;
width: ${props => props.modalReport ? '40px': '56'};
height: ${props => props.modalReport ? '40px': '56'};
position: absolute;
right: ${props => props.modalReport ? '0px': '16px'};
top: 0px;
`

export const ContainerTextDiscount = styled.p`
position: absolute;
top: 48%;
left: 50%;
transform: translate(-50%, -50%);
display: flex;
`

export const TextDiscount = styled.p`
font-weight: 800;
font-size: ${props => props.modalReport ? '16px' : '22px'};
line-height: 16px;
font-family: sans-serif;
color: #011D0E;
`

export const PercentajeDiscount = styled.p`
font-weight: 600;
font-size: ${props => props.modalReport ? '14px' : '17px'};
line-height: 16px;
font-family: sans-serif;
color: #011D0E;
`

export const StyledImage = styled(Image)`
&:active {
    opacity: 0.3;
}
`;

export const StyledImageArrow = styled(Image)`
margin-top: 20px;
`;

export const ContainerItems = styled.div`
display: flex;
justify-content: space-between;
padding: 0px 16px;
align-items: flex-end;
`;

export const ContainerPrice = styled.div`
display: flex;
align-items: center;
`;

export const Price = styled.div`
fcolor: #011D0E;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: ${props => props.modalReport ? '20px' : '28px'};
font-style: normal;
font-weight: 800;
line-height: 16px;
color: #011D0E;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-style: normal;
font-weight: 800;
line-height: normal;
margin-left: 2px;
`;

export const UnitMeasure = styled.p`
font-weight: 600;
font-size: 18px;
font-family: sans-serif;
color: #011D0E;
margin-left: 3px;
max-width: 120px;
line-height: 22px;
white-space: nowrap; 
overflow: hidden; 
text-overflow: ellipsis;
`;

export const FullUnitMeasure = styled.p`
font-weight: 400;
font-size: ${props => props.modalReport ? '11px' : '14px'};
color: ${props => props.modalReport ? '#344A3E' : '#344A3E'};
line-height: 20px;
color: #344A3E;
font-family: Roboto;
font-style: normal;
line-height: normal;
padding-left: ${props => props.showDiscount ? '0px' : '16px' };
`;

export const OldPrice = styled.p`
text-decoration-line: line-through;
display: flex;
width: 56px;
height:${props => !props.modalReport && '22px'};
flex-direction: column;
justify-content: flex-end;
flex-shrink: 0;
color: #344A3E;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: ${props => props.modalReport ? '12px' : '16px'};
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: strikethrough;
margin-left: 2px;
`;

export const ShareButton = styled.button`
display: flex;
position: absolute;
top: 38px;
left: 12px;
align-items: center;
gap: 4px;
justify-content: flex-start;
border: 0;
background-color: transparent;
color: #00904B;
font-family: sans-serif;
font-size: 13px;
font-weight: 800;
`;

export const ContainerSaving = styled.div`
display: flex;
padding: 0 0px 0px 16px;
align-items: center;
color: #FF3025;
text-align: center;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 800;
line-height: 16px;
gap: 2px;
`;

export const TextSavings = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: ${props => props.isDefaultTier ? '800' : '500'};
font-size: 14px;
line-height: 16px;
color: ${props => props.isDefaultTier ? '#FFF' : '#011D0E'};
background: ${props => props.isDefaultTier ? props.theme.primary : props.theme.warning};
padding: ${props => props.isDefaultTier ? '0px 3px' : '0px 3px'};
`;

export const TextSavingsAmount = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 16px;
color: #011D0E;
background-color:${props => props.theme.warning};
padding-left: 4px;
padding-right: 3px;
`;

export const ProductName = styled.h1`
color: #011D0E;
padding: 0px 16px;
font-family: Roboto;
font-style: normal;
font-weight: 500;
line-height: 24px;
font-size: ${props => props.modalReport ? '14px' : '20px'};
`;

export const Description = styled.p`
padding: 16px;
font-family: sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #011D0E;
`;

export const Limit = styled.p`
font-family: sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #011D0E;
text-align: center;
margin-top: 20px;
margin-bottom: 10px;
`

export const Spacing = styled.div`
margin-top: ${props => props.margin ? '0px' : '20px'};
`

export const QuantitySelection = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
margin-top: 30px;
padding: 0px 16px;
`

export const ContainerAdvice = styled.div`
position: absolute;
bottom: 0px;
`;

export const ContainerAdvice2 = styled.div`
padding: 8px 16px;
`;

export const BlockAdvice = styled.div`
border-radius: 8px;
background: #F6F6F7;
padding: 4px;
display: flex;
justify-content: flex-start;
align-items: center;
${props => props.soldOut && css`
background: ${props.theme.warningLighter};
`}
`

export const TextBlock = styled.div`
color: ${props => props.theme.textDefault};
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 22px;
margin-left: 16px;
width: 90%;
${props => props.soldOut && css`
width: 100%;
margin: 0
`}
`

export const TextAdvice = styled.p`
color: ${props => props.theme.textDefault};
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 22px;
${props => props.soldOut && css`
color: ${props.theme.textDefault};
`}
`

export const SubTextAdvice = styled.p`
color: ${props => props.theme.textDefault};
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px;
word-wrap: wrap;
${props => props.soldOut && css`
color: ${props.theme.textDefault};
`}
`

export const SpanAdvice = styled.span`
color: var(--dark-text-primary, #001109);;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 800;
line-height: 16px;
text-decoration-line: underline;
margin-left: 3px;
${props => props.soldOut && css`
color:${props.theme.textDefault};
`}
`

export const BlockStockAdvice = styled.div`
border-radius: 8px;
padding: 4px;
background: #F6F6F7;
display: flex;
justify-content: flex-start;
align-items: center;
`

export const BlockVerificationPrice = styled.div`
border: 1px solid var(--dark-stroke, #F2F3F2);
background: var(--dark-background-light, #F7F8F7);
padding: 16px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
`

export const TitleBlock = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
`
export const TitleVerification = styled.div`
color: ${props => props.theme.textSecondary};
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px;
`

export const SubTitleVerification = styled.div`
color: ${props => props.theme.textSecondary};
text-align: center;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 800;
line-height: 16px;
display: flex;
gap: 4px;
`

export const MarketLines = styled.div`
display: flex;
justify-content: space-between;
gap: 8px;
margin-top: 10px;
width: 100%;
`
export const MarketLineContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
border-radius: 8px;
border: 1px solid #F2F3F2;
background: #FFF;
box-shadow: 0px 1px 3px 0px rgba(103, 98, 98, 0.05);
padding: 4px 0 8px 0;
width: ${props => props.type === 'mercadito' ? props.length === 3 ? '26.8%' : '36.6%'  : props.length === 3 ? '22%' : '29.3%'};
`

export const MarketName = styled.div`
color: ${props => props.type === 'mercadito' ? '#001109' : props.theme.textSecondary};
font-family: Roboto;
font-size: 11px;
font-style: normal;
font-weight: 600;
line-height: 16px;
margin: 2px 0 8px 0;
`

export const MarketSeparatorLine = styled.div`
width: 72%;
height: 1px;
background: ${props => props.type === 'mercadito' ? props.theme.primary : '#E5E7E6'};
margin-bottom: 8px;
`


export const TextPriceVerification = styled.p`
color: ${props => props.type === 'mercadito' ? props.theme.textDefault : props.theme.textSecondary};
font-family: Roboto;
font-size: ${props => props.type === 'mercadito' ? '16px' : '12px'};
font-style: normal;
font-weight: 800;
line-height: 16px; /* 100% */
letter-spacing: -0.08px;
`
export const TextStockAdvice = styled.p`
color: ${props => props.theme.textDefault};
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 22px;
margin-left: 16px;
`

export const ContainerGuarantee = styled.div`
padding: 0px 16px;
margin-top: 2px;
display: flex;
align-items: center;
`

export const GuaranteeText = styled.p`
color: #011D0E;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 18px; 
margin-right: 4px;
margin-left: 6px;
`

export const BrandTitle = styled.p`
color:#011D0E;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
`

export const BrandName = styled.p`
color: ${props => props.grouped ? '#00904B' : '#011D0E'} ;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight:${props => props.grouped ? '800' : '400'} ;
line-height: 20px;
margin-left: 3px;
`

export const ContainerBrand = styled.div`
display: flex;
padding: 0px 16px;
margin-top: 4px;
`
export const ContainerHasBrand = styled.div`
`

export const ContainerReportPrice = styled.div`
display: flex;
width: auto;
align-items: center;
cursor: pointer;
position: absolute;
left: 16px;
top: 0px;
&:active{
    opacity: 0.7;
};
`

export const TextReport = styled.p`
color: #ED1F44;
text-align: center;
font-family: sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 800;
line-height: 16px;
margin-left: 4px;
`

export const ContainerBox = styled.div`
display: flex;
align-items: center;
width: 100%;
justify-content: space-between;
margin: 4px 0px;
` 

export const ContainerDiscounts = styled.div`
display: flex;
gap: 4px;
align-items: center;
`

export const TitleModalReport = styled.p`
color: #000;
font-family: sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 26px;
margin-top: 12px;
margin-bottom: 16px;
padding: 48px 16px 0px 16px;
`

export const SubtitleModalReport = styled.p`
color: #000;
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px; 
margin-top: 26px;
padding: 0px 16px;
`

export const ContainerButton = styled.div`
border-top: 1px solid #F2F3F3;
background: #FFF;
width: 100%;
display: flex;
justify-content: center;
padding: 24px 16px 32px;
position: fixed;
bottom: 0;
opacity: ${props => props.disabled && '0.5'};
&:active{
    opacity: 0.7
};
z-index: 2;
`

export const Button = styled.div`
width: 100%;
background: ${props => props.theme.primary};
display: flex;
justify-content: center;
padding: 14px 0px;
border-radius: 6px;
`

export const ButtonText = styled.div`
color: #FFF;
text-align: center;
font-family: sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 800;
line-height: 24px; 
`

export const ContainerOptions = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
border-radius: 8px;
border: 1px solid #F2F3F2;
margin: 8px 16px 0px;
height: auto;
`

export const Option = styled.div`
display: flex;
width: 100%;
border-bottom: 1px solid #F2F3F2;
height: 50px;
`

export const ContainerCardProduct = styled.div`
border-radius: 8px;
border: 1px solid #F2F3F3;
background: #FFF;
max-width: 490px;
height: auto;
margin: 0px 16px;
display: flex;
align-items: flex-start;
`

export const ContainerImage = styled.div`
height: auto;
width: 30%;
display: flex;
justify-content: center;
position: relative;
`

export const ContainerInfoProduct = styled.div`
width: 70%;
display: flex;
flex-direction: column;
padding: 16px 8px 16px 16px;
`

export const TextLabel = styled.p`
color: #011D0E;
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px; 
margin-bottom: 8px;
`
export const TextPriceByUnit = styled.p`
color: #646970;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
padding: 4px 16px 0px 16px;
`
export const ContainerExpirationDate = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${props => props.justifyContent};
  padding-right: ${props => props.paddingRight ? props.paddingRight : "16px"};
  margin-block: ${props => props.marginBlock ? props.marginBlock : "0px"};
  position: ${props => props.position && props.position};
  right: 8px;
  top: 60px;
`;

export const BadgeExpirationDate = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: ${props => props.padding};
  border-radius: 8px;
  background: var(--Bordeaux-Default, #ED1F69);
  color: var(--Fill-Stroke-Lowest-contrast, #FFF);
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Roboto;
  font-size: ${props => props.fontSize};
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
`;

export const DateTextExpirationDate = styled.p`
  color: var(--Fill-Stroke-Lowest-contrast, #FFF);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Roboto;
  font-size: ${props => props.fontSize};
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;