import { useTheme } from "styled-components";
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  MarkerClustererF,
} from "@react-google-maps/api";
import { useState, useContext, useEffect } from "react";
import { Circle } from "@react-google-maps/api";
import {
  Container,
  SearchArea,
  SearchButton,
  EmptyCard,
  Card,
  TitleCard,
  SubtitleCard,
  ContainerText,
  ViewList,
} from "./styled";
import ShopsSlider from "componentes/ShopsSlider";
import Image from "next/image";
import { FormattedMessage } from "react-intl";
import { trackShopClicked } from "./tracking";
import { Context } from "context/Context";
import { notFoundNearestShop } from "componentes/ListNearestShops/tracking";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const optionsClusterer = {
  imagePath: "/imgs/clusterer",
};

const Map = ({
  center,
  zoom,
  setCenter,
  setZoom,
  actualLocation,
  visibleArea,
  setVisibleArea,
  shopsInBound,
  setButtonActived,
  extraDistance,
  changeVisibleArea,
  setThisMap,
  thisMap,
  actualLocationActivated,
  setActualLocationActivated,
  type,
  setSelectedDeliveryPoint,
  setModalDeliveryPointSelector,
  nextModal,
  setIsList,
  selectedDeliveryPoint,
}) => {
  const theme = useTheme();
  const options = {
    strokeColor: theme.primary,
    strokeOpacity: 0.17,
    strokeWeight: 2,
    fillColor: theme.primary,
    fillOpacity: 0.17,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 300,
    zIndex: 2,
  };

  const {
    customer,
  } = useContext(Context);

  const [showNewShops, setShowNewShops] = useState(false);
  const [shopIndex, setShopIndex] = useState(null);
  const [isOpen, setIsOpen] = useState([]);
  const onLoad = (map) => {
    setThisMap(map);
  };

  const mapOptions = {
    mapTypeControlOptions: {
      mapTypeIds: [],
    },
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_TOP,
    },
    restrictions: {
      types: ["restaurant", "cafe"],
    },
    gestureHandling: "greedy",
  };

  const handleFetchShops = (e) => {
    if (e.cancelable) {
      e.preventDefault();
    }
    setShopIndex(null);
    changeVisibleArea();
    setButtonActived(true);
    setShowNewShops(false);
  };

  const handleMapMoved = () => {
    const bounds = thisMap.getBounds();
    if (bounds) {
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      if (visibleArea) {
        if (
          ne.lat() > visibleArea.north ||
          sw.lat() < visibleArea.south ||
          ne.lng() > visibleArea.east ||
          sw.lng() < visibleArea.west
        ) {
          setShowNewShops(true);
        }
      } else {
        setShowNewShops(true);
      }
    }
  };
  const handleMapZoom = () => {
    setZoom(thisMap.getZoom());
    const bounds = thisMap.getBounds();
    if (bounds) {
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      if (
        ne.lat() > visibleArea.north ||
        sw.lat() < visibleArea.south ||
        ne.lng() > visibleArea.east ||
        sw.lng() < visibleArea.west
      ) {
        !actualLocationActivated
          ? setShowNewShops(true)
          : setActualLocationActivated(false);
      } else {
        setShowNewShops(false);
      }
    }
  };

  const handleSetBound = () => {
    const bounds = thisMap.getBounds();
    if (bounds) {
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      setVisibleArea({
        north: ne.lat() + extraDistance,
        south: sw.lat() - extraDistance,
        east: ne.lng() + extraDistance,
        west: sw.lng() - extraDistance,
      });
    }
  };

  useEffect(() => {
    if (shopsInBound?.length === 0)
      notFoundNearestShop(
        customer?.customer.name,
        customer?.customer.last_name,
        customer?.customer.phone,
        actualLocation?.lat,
        actualLocation?.lng
      );
  }, [shopsInBound]);

  return (
    <Container type={type}>
      {showNewShops ? (
        <SearchArea>
          <SearchButton onClick={handleFetchShops}>
            Buscar en esta área
          </SearchButton>
        </SearchArea>
      ) : (
        <SearchArea>
          <ViewList
            onClick={() => {
              if (actualLocation) {
                setCenter({ lat: actualLocation?.lat, lng: actualLocation?.lng });
                setIsList(true);
              }
              setIsList(true);
            }}
          >
            <Image src="/imgs/list.svg" alt="list" width={16} height={16} />
            Ver lista
          </ViewList>
        </SearchArea>
      )}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        options={mapOptions}
        zoom={zoom}
        onZoomChanged={thisMap && handleMapZoom}
        onDragEnd={thisMap && handleMapMoved}
        onBoundsChanged={thisMap && !visibleArea && handleSetBound}
        onLoad={onLoad}
      >
        {actualLocation && (
          <MarkerF
            icon={{
              url: "/imgs/geolocal.svg",
              anchor: new google.maps.Point(16, 25),
            }}
            position={{ lat: actualLocation.lat, lng: actualLocation.lng }}
          />
        )}
        <MarkerClustererF
          options={optionsClusterer}
          minimumClusterSize={2}
          maxZoom={14}
        >
          {(clusterer) =>
            clusterer &&
            shopsInBound?.map((shop, index) => {
              return (
                <div key={index}>
                  <MarkerF
                    clusterer={clusterer}
                    icon={{
                      url: "/imgs/logo_mercadito.png",
                      anchor: new google.maps.Point(48, 48),
                    }}
                    position={{
                      lat: shop.shop_location.Latitude,
                      lng: shop.shop_location.Longitude,
                    }}
                    onClick={() => {
                      trackShopClicked(
                        shop.id,
                        shop.community_leader_id,
                        "map"
                      );
                      setShopIndex(index);
                      setCenter({
                        lat: shop.shop_location.Latitude,
                        lng: shop.shop_location.Longitude,
                      });
                      setZoom(15);
                      if (isOpen.includes(index)) {
                        setIsOpen(isOpen.filter((i) => i !== index));
                      } else {
                        setIsOpen([index]);
                      }
                    }}
                  />
                  {isOpen?.includes(index) && (
                    <InfoWindowF
                      position={{
                        lat: shop.shop_location.Latitude,
                        lng: shop.shop_location.Longitude,
                      }}
                      onCloseClick={() =>
                        setIsOpen(isOpen.filter((i) => i !== index))
                      }
                      zIndex={1}
                      options={{
                        pixelOffset: {
                          width: 0,
                          height: -40,
                        },
                      }}
                    >
                      <div>
                        <h3>
                          Recibirás la ubicación exacta después de realizar el
                          pedido.
                        </h3>
                      </div>
                    </InfoWindowF>
                  )}
                  {zoom > 14 && (
                    <Circle
                      center={{
                        lat: shop.shop_location.Latitude,
                        lng: shop.shop_location.Longitude,
                      }}
                      options={options}
                    />
                  )}
                </div>
              );
            })
          }
        </MarkerClustererF>
      </GoogleMap>
      {shopsInBound?.length > 0 && zoom > 10 ? (
        <ShopsSlider
          shops={shopsInBound}
          shopIndex={shopIndex}
          setShopIndex={setShopIndex}
          setCenter={setCenter}
          setZoom={setZoom}
          type={type}
          setSelectedDeliveryPoint={setSelectedDeliveryPoint}
          setModalDeliveryPointSelector={setModalDeliveryPointSelector}
          nextModal={nextModal}
          actualLocation={actualLocation}
          center={center}
          selectedDeliveryPoint={selectedDeliveryPoint}
        />
      ) : (
        shopsInBound?.length === 0 && (
          <EmptyCard>
            <Card>
              <ContainerText>
                <TitleCard>
                  <FormattedMessage
                    id="noResultsAreaTitle"
                    defaultMessage="No results found in this area"
                  />
                </TitleCard>
                <SubtitleCard>
                  <FormattedMessage
                    id="noResultsAreaSubtitle"
                    defaultMessage="Try expanding your search."
                  />
                </SubtitleCard>
              </ContainerText>
              <Image
                src="/imgs/iconSearch.svg"
                alt="icon"
                width={16}
                height={16}
              />
            </Card>
          </EmptyCard>
        )
      )}
    </Container>
  );
};

export default Map;
