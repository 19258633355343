import Image from "next/image"
import { useEffect, useState } from "react"
import { guaranteeTypes } from "services/guarantee"
import { Container, TextContainer, Text, StampImage } from "./styled"
import { FormattedMessage } from "react-intl"

const GuaranteeStamp = ({ category, setModalStamp }) => {
    const [guarantee, setGuarantee] = useState(false)
    useEffect(() => {
        let foundSubObject = null;
        Object.values(guaranteeTypes).forEach((subObject) => {
        if (subObject?.category_ids?.includes(category)) {
            foundSubObject = subObject;
        }
        });
        if (foundSubObject) {
        setGuarantee(foundSubObject);
        } 
    }, [category])
    if(!guarantee) return null
    return (
        <Container>
            <StampImage>
                <Image src={guarantee.image} width={58} height={72} alt='image' />
            </StampImage>
            <TextContainer>
                <Text>
                <FormattedMessage
                    id={guarantee.id}
                    defaultMessage={guarantee.description}
                />
                {' '}
                <span onClick={() => setModalStamp(guarantee)}>
                    <u>
                    <FormattedMessage
                        id="guaranteeStamp.moreInfo"
                        defaultMessage="More info"
                    />
                    </u>
                </span>
                </Text>
            </TextContainer>
        </Container>
    )
}

export default GuaranteeStamp;
