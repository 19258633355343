import { ModalContainer, Modal, ModalContent, ContainerClose, ContainerButton, Button, ButtonText, DeleteFiltersText, CloseTab, TitleBrand } from './styled';
import Image from 'next/image';
import Divider from '@mui/material/Divider';
import RadioButtonsFilter from './RadioButtonsFilter';
import SwitchFilter from './SwitchFilter';
import SliderFilter from './SliderFilter'
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { getPriceRange } from 'services/filter';
import BrandsFilter from './BrandsFilter';

function ModalFilters({ openFilters, setOpenFilters, handleFilters, filteredProducts, products, filterObject, countFilters, setFilterObject, selectedBrands, setSelectedBrands, pais }) {
    const priceRange = getPriceRange(products)

    const [selectedValue, setSelectedValue] = useState(filterObject.order);
    const [checked, setChecked] = useState(filterObject.discount);
    const [valueRange, setValueRange]= useState([filterObject?.range?.minPrice || priceRange.minPrice, filterObject?.range?.maxPrice || priceRange.maxPrice]);

    const result = filteredProducts.length;

    const handleCloseFilters = () => {
        setOpenFilters(false);
    };

    useEffect(() => {
      handleFilters('order_filter', selectedValue);
    },[selectedValue]);

    const deleteFilters = () => {
      setFilterObject({order:'recommended_sort', range: getPriceRange(products), discount: false});
      setSelectedValue('recommended_sort');
      setChecked(false);
      setValueRange([priceRange.minPrice, priceRange.maxPrice]);
      setSelectedBrands([]);
    }

    return (
      <>
        {openFilters && (
          <ModalContainer>
            <Modal isOpen={openFilters}>
              <CloseTab onClick={handleCloseFilters} />
              <ModalContent>
                <ContainerClose>
                    <Image onClick={handleCloseFilters} src="/imgs/closeTabBlack.svg" width={28} height={28} />
                    {countFilters > 0 && (
                    <DeleteFiltersText onClick={deleteFilters}>
                      <FormattedMessage 
                        id='removeFiltersText'
                        defaultMessage='Remove filters'
                      />
                    </DeleteFiltersText>
                    )}
                    </ContainerClose>
                    {
                      products.length > 1 &&
                      <RadioButtonsFilter setSelectedValue={setSelectedValue} selectedValue={selectedValue} />
                    }
                    <Divider variant="inset" sx={{backgroundColor:'#E6E9E7', borderColor:'#E6E9E7', mt:'16px', mb:'24px', ml:'0px'}} />
                    <SwitchFilter checked={checked} setChecked={setChecked} handleFilters={handleFilters}/>
                    {
                      priceRange.minPrice < priceRange.maxPrice &&
                      <SliderFilter handleFilters={handleFilters} priceRange={priceRange} value={valueRange} setValue={setValueRange} />
                    }
                      <BrandsFilter setSelectedBrands={setSelectedBrands} selectedBrands={selectedBrands} products={products} handleFilters={handleFilters} pais={pais}/>
              </ModalContent>
              <ContainerButton>
                  <Button onClick={() => handleCloseFilters()}>
                    <ButtonText>
                      {
                        filteredProducts.length === 0 ? (
                          'No encontramos resultados'
                        ) : (
                          <> 
                            <FormattedMessage
                              id='showId'
                              defaultMessage='Show'
                            />
                              {' '}
                              {result}
                              {' '}
                              {filteredProducts.length === 1 ? (
                              <FormattedMessage
                                id='resultId'
                                defaultMessage='result'
                              />
                                  ) : (
                              <FormattedMessage
                              id='resultsId'
                              defaultMessage='results'
                              />
                                  )}
                              </>
                        )
                      }
                    </ButtonText>
                  </Button>
                </ContainerButton>
            </Modal>
          </ModalContainer>
        )}
      </>
    );
  }
  
  export default ModalFilters;